.details {
    &__sub-content {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        &--image {
            width: 100%;
        }

        @media screen and (max-width: 747px) {
            &--image {
                width: 150px;
            }
        }
    }
}
