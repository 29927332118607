.always-visible {
    .form-header {
        &__note {
            &--hidden {
                visibility: visible;
            }
        }
    }
}
.form-header {
    position: relative;
    display: grid;
    grid-template-columns: auto 30px;
    grid-template-rows: auto auto;
    align-items: center;

    &.scrollbox {
        box-shadow: 0 1px 8px 3px rgba(0, 0, 0, 0.1);
        z-index: 1;
    }

    &__note {
        background: $successLightColor;
        position: absolute;
        width: 100%;
        min-height: 35px;
        height: auto !important;
        padding: 5px 0;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        padding-left: 3.75rem;
        padding-right: 3.75rem;
        font-size: 14px;
        font-weight: bold;
        z-index: 1;

        .mat-icon {
            display: inline-flex;
            margin-right: 5px;
        }

        span {
            line-height: 1;
        }
        &--alert {
            background: $alertLightColor;
        }
        &--warn {
            background: $highlightColor;
        }
        &--hidden {
            visibility: hidden;
        }
        &--pointer {
            cursor: pointer;
        }
    }

    &__wrapper {
        display: contents;
    }

    h1 {
        position: relative;
        margin: 0;
        grid-row: 1 / 1;
        grid-column: 1 / 1;

        mat-icon {
            margin-right: 0.5rem;
            height: 28px;
            svg {
                height: 32px;
                width: auto;
            }
        }
    }

    h2 {
        position: relative;
        margin: 4px 0;
        grid-row: 2 / 2;
        grid-column: 1 / 1;
        font-size: 0.9rem;
        line-height: 1.4;
        max-width: 600px;
    }

    .form-header_content-header-info {
        grid-row: 1 / span 2;
        grid-column: 2 / 2;

        mat-icon {
            width: 28px;
            height: 28px;
            font-size: 28px;
            line-height: 1;
        }
    }

    .form-header_close {
        grid-row: 1 / span 1;
        grid-column: 2 / span 1;

        position: relative;
        top: -2rem;
        right: -2rem;

        .mat-icon-button {
            border: none !important;
            &:hover {
                border: none !important;
                svg {
                    fill: $primaryColor;
                    color: $primaryColor;
                }
            }
        }

        .mat-icon {
            font-size: 2.5rem;
            height: 2.5rem;
            width: 2.5rem;
            line-height: 2.5rem;
            display: inline-flex;
        }
    }
    .clickable {
        cursor: pointer;
    }
}

@media only screen and (max-width: #{$layout-breakpoint-500 - 1}) {
    .form-header {
        .form-header_close {
            top: 0;
            right: 0;
        }
    }
}

@media only screen and (min-width: $layout-breakpoint-xs) {
    .form-header {
        .sign-up-layout_content-header-info {
            mat-icon {
                width: 28px;
                height: 28px;
                font-size: 28px;
                line-height: 1;
            }
        }
    }
}
