.blog {
    $padding: 3.125rem;

    .form-header {
        padding: 0;

        &_close {
            position: absolute;
            z-index: 2;
            top: 0.3rem;
            right: 1rem;
            display: block;
        }
    }

    .modal-form-layout_content {
        padding: 0;
    }

    .mat-sidenav-content {
        background-color: $blogArticlesBackgroundColor;
    }

    .tp-blog-modal__viewport {
        min-height: min(1000px, calc(100vh - 2 * #{$padding}));
    }

    .blog-article {
        &__navigation-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 1.25rem 1.25rem;

            &:first-child {
                grid-row-start: row1-start;
                grid-column-start: column1-start;
            }
        }

        &__navigation-panel {
            @include tp-box-shadow-all;
            border-radius: 0.625rem;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            min-height: 14.375rem;
            max-height: 14.375rem;
            height: 14.375rem;
            min-width: 20.625rem;
            width: 20.625rem;
            background-size: cover;
            object-fit: cover;
            cursor: pointer;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 70px;
                bottom: 0;
                left: 0;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
                border-bottom-left-radius: 0.625rem;
                border-bottom-right-radius: 0.625rem;
            }
        }

        &__navigation-content {
            color: #fff;
            font-weight: bold;
            text-align: center;
            padding-bottom: 1rem;
            z-index: 2;
        }
    }

    grid-template-rows: $padding minmax(min(1000px, calc(100vh - 2 * #{$padding})), max-content) minmax($padding, auto);

    @media only screen and (min-width: $layout-breakpoint-sm) {
        grid-template-columns: minmax(40px, auto) minmax(940px, 1400px) minmax(40px, auto);
    }

    @media only screen and (max-width: $layout-breakpoint-sm - 1) {
        .blog-article {
            &__navigation-wrapper {
                grid-template-columns: auto;
            }
        }
    }
    @media only screen and (max-width: $layout-breakpoint-xs) {
        .blog-article {
            &__navigation-panel {
                min-height: 8.375rem;
                max-height: 8.375rem;
                height: 8.375rem;
                min-width: 14.625rem;
                width: 14.625rem;
            }
        }
    }
    @media only screen and (max-width: $layout-breakpoint-xxs) {
        .blog-article {
            &__navigation-panel {
                min-height: 6.375rem;
                max-height: 6.375rem;
                height: 6.375rem;
                min-width: 11.625rem;
                width: 11.625rem;
            }
        }
    }
}
