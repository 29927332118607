.skills-list-table {
    &__skill {
        padding-left: 5px;
        padding-right: 5px;
    }

    &__skill--highlight {
        background-color: $highlightColor;
        font-weight: bold;
    }
}
