@use 'sass:math';

$placeholderWidth: 140px;
$avatarSize: 8.125rem;

@mixin sizeImagePicker($avatarSize) {
    .image-picker {
        .image-picker_image-wrapper {
            width: $avatarSize;
            height: $avatarSize;
            border-radius: 50%;

            div {
                width: $avatarSize;
                height: $avatarSize;
                background-size: cover;
                object-fit: cover;
                border-radius: 50%;
                background-position: center;
            }
        }
    }

    .image-picker:not(.disabled) {
        .image-picker_image-wrapper {
            cursor: pointer;

            &:hover,
            &:focus {
                &:after {
                    color: #fcc55e;
                }
            }

            .image-picker__camera-button {
                position: absolute;
                bottom: 1.5rem;
                left: math.div($avatarSize - 1.5rem, 2);
                font-size: 1.5rem;
                width: 1.5rem;
                height: 1.5rem;
                text-align: center;
                color: #fff;
                z-index: 2;
            }

            &:after {
                position: absolute;
                content: '';
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, #000000 25%, rgba(0, 0, 0, 0) 50%);
                opacity: 0.6;
                z-index: 1;
                border-radius: inherit;
            }
        }
    }
}

/*
    apply default size for avatar
    reaplying this mixin with increased specificity
    will override dimensions
*/
@include sizeImagePicker(8.125rem);

.image-picker {
    display: block;
    position: relative;

    .image-picker_image-wrapper {
        position: relative;
        display: inline-block;

        div {
            width: $avatarSize;
            height: $avatarSize;
            background-size: cover;
            object-fit: cover;
            border-radius: 50%;
            background-position: center;
        }
    }
}

.image-picker {
    .image-picker_image-wrapper--square {
        background-color: $lightGrey;
        border-radius: 10px;

        div {
            border-radius: 10px;
        }

        .image-picker__camera-button {
            color: $greyColor;
        }

        &:after {
            visibility: hidden;
        }

        &.image-picker_image-wrapper--has-own-image {
            .image-picker__camera-button {
                color: #fff;
            }

            &:after {
                visibility: visible;
            }
        }
    }
}

.image-picker:not(.disabled) {
    .image-picker_image-wrapper {
        cursor: pointer;
    }
}

.image-picker-dialog {
    position: relative;
    display: block;

    input[type='file'] {
        visibility: hidden;
        display: none;
    }

    .mat-dialog-title {
        margin: 0;
    }

    .image-picker-dialog_own-picture-btn {
        display: inline-block;
        margin: 16px 0;
        padding: 0.5rem 2rem;
        width: 100%;
        height: 4rem;
        appearance: none;
        border: none;
        background-color: whitesmoke;
        cursor: pointer;
        &:hover {
            background-color: lighten($color: whitesmoke, $amount: 0.8);
        }

        mat-icon {
            display: inline-block;
            width: 3rem;
            height: 3rem;
            vertical-align: middle;
            svg {
                width: 3rem;
                height: auto;
            }
        }

        .image-picker-dialog_own-picture {
            display: inline-block;
            height: 2.375rem;
            line-height: 3rem;
            vertical-align: middle;
            color: $greyColor;
        }

        span {
            font-size: 1rem;
            vertical-align: middle;
            margin: 0 1rem;
        }
    }

    .image-picker-dialog_placeholders {
        $imagePadding: 4px;

        position: relative;
        padding: 4px 0;
        display: grid;
        grid-template-columns: $placeholderWidth $placeholderWidth;
        grid-auto-rows: $placeholderWidth;
        justify-items: center;
        align-items: center;
        overflow-x: hidden;

        a {
            display: block;
            position: relative;
            width: $placeholderWidth - 2 * $imagePadding;
            height: $placeholderWidth - 2 * $imagePadding;

            img {
                display: inline-block;
                width: 100%;
                height: 100%;
            }

            &:not(.selected) {
                cursor: pointer;
            }

            &.selected::after {
                display: inline-block;
                position: absolute;
                text-align: center;
                font-size: 12rem;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(255, 255, 255, 0.2);
                background-size: 50px;
                background-repeat: no-repeat;
                background-position: 50%;
                background-image: url('/assets/icons/icon_selected.png');
                content: '';
            }

            &.current {
                border: 2px dashed rgba(0, 0, 0, 0.3);
            }

            .btn-delete {
                display: none;
                position: absolute;
                top: 0;
                right: 0;
                background-color: rgba(255, 255, 255, 0.7);
            }

            &:not(.selected):not(.current):hover {
                .btn-delete {
                    display: block;
                }
            }
        }
    }

    &_save {
        margin: auto !important;
    }

    &_button-group {
        width: 100%;
        text-align: center;
        padding-bottom: 1.5rem;
    }

    &_cropper {
        padding-bottom: 1.5rem;
        width: 100%;
        text-align: center;
    }

    &_button-crop {
        margin-left: 0.5rem !important;
    }

    .form-footer {
        margin-bottom: 0 !important;
    }
}

@media only screen and (min-width: $placeholderWidth * 5 + 8px * 5) {
    .image-picker-dialog {
        .image-picker-dialog_placeholders {
            grid-template-columns: $placeholderWidth $placeholderWidth $placeholderWidth;
        }
    }
}

@media only screen and (min-width: $placeholderWidth * 7 + 8px * 7) {
    .image-picker-dialog {
        .image-picker-dialog_placeholders {
            grid-template-columns: $placeholderWidth $placeholderWidth $placeholderWidth $placeholderWidth;
        }
    }
}
