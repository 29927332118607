.settings-page {
    .collapsable__trigger {
        display: block;
        width: 100%;

        padding: 20px 25px 15px;
        background-color: $lightGrey;
    }

    .collapsable__content {
        display: block;
        padding: 0 25px;

        border-bottom: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
    }

    .collapsable ~ .collapsable {
        margin-top: 20px;
    }

    .collapsable {
        position: relative;
        display: block;

        &::after {
            content: '\f054';
            font-family: 'FontAwesome';
            display: block;
            position: absolute;
            right: 25px;
            top: 20px;
            cursor: pointer;
            pointer-events: none;

            transform: rotate(0deg);
            transition: transform 0.3s linear;
        }

        &--opened {
            .collapsable__content {
                padding: 0 25px;
                border-bottom: 2px solid $lightGrey;
                border-left: 2px solid $lightGrey;
                border-right: 2px solid $lightGrey;
            }

            &::after {
                transform: rotate(90deg);
                transition: transform 0.3s linear;
            }
        }
    }
}
