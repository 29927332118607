.details {
    .card__tags-header {
        font-size: 18px;
        text-transform: capitalize;
    }

    &__layout-canvas {
        .details__title {
            border-bottom: 0;
            padding-bottom: 0;
            padding-top: 1rem;

            h2 {
                line-height: 1;
                margin-bottom: 20px;
            }
        }
        .details__facts {
            border-bottom: 1px solid #484e5f;
            padding-bottom: 1rem;
        }
        .details__organizer-image {
            cursor: pointer;
        }

        tp-details-content {
            padding-top: 2.5rem;

            .expanding-button-wrapper {
                margin-top: 1rem;
                text-align: center;
            }

            .card__tags {
                margin-top: 0;
                margin-bottom: 0;
                padding: 0;

                .card__tags-header {
                    display: block;
                }
            }

            p.description,
            p > p {
                margin-bottom: 0;
            }

            p.description > p {
                overflow: hidden;
            }

            p.description {
                &:not(.expanded) {
                    max-height: 80px;
                    overflow: hidden;
                }
            }
        }

        h3 {
            margin-bottom: 0.4rem;
            max-width: 95%;
        }

        @media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
            tp-details-content {
                display: block;
                &.details__content--button {
                    padding-top: 0.5rem;
                }
            }
            .details__content-ready-status {
                .mat-icon {
                    left: 0.75rem;
                    top: 0.55rem;
                }
            }
        }
    }

    &__topics {
        .details__content-label {
            padding-top: 2.5rem;
        }
    }

    &__content-label {
        display: flex;
        justify-content: space-between;

        &--required {
            text-align: left;
            h3 {
                display: flex;
            }
            tp-info-toggle {
                font-size: 0.875rem;
                //margin-top: -1.5rem;
                width: 20.5rem;
                float: right;

                .mat-icon {
                    &:hover {
                        cursor: default;
                        transform: scale(1);
                    }
                    margin-right: 0.5rem;
                    svg {
                        fill: $alertColor;
                    }
                }
            }
        }

        @media screen and (max-width: $layout-breakpoint-sm - 1px) {
            justify-content: normal;
            flex-direction: column-reverse;
            &--required {
                tp-info-toggle {
                    width: auto;
                    float: unset;
                }
            }
        }
    }

    &__content-target-functions {
        display: flex;
        justify-content: flex-start;
        tp-checkbox-item {
            padding-top: 0.75rem;
            padding-right: 1rem;
        }
        tp-select {
            width: 100%;
        }
    }

    &__content-ready-status {
        position: relative;
        border-radius: 12px;
        font-size: 14px;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        padding: 0 2.5rem;

        &.not-ready {
            background-color: #f7d9ce;
        }
        &.almost-ready {
            background-color: #ffefb6;
        }
        &.ready {
            background-color: #dcedc4;
        }

        .mat-icon {
            position: absolute;
            left: 2.6rem;
        }
        p {
            text-align: center;
            margin: 0;
        }

        @media screen and (max-width: $layout-breakpoint-sm - 1px) {
            .mat-icon {
                left: 1rem;
                margin-left: -7px;
            }
        }
    }

    &__content-nice-to-have-skills {
        &--toggle {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;

            h3 {
                max-width: none;
            }
            .card__tags {
                margin: 0 1.25rem;
            }
        }
        &--title {
            margin: 0 !important;
        }
        &__short-list {
            padding-left: 1.5rem;
        }
        &__short-list--item {
            margin-right: 1rem;
            position: relative;
            font-size: 14px;
            &:not(:first-of-type) {
                &:before {
                    position: absolute;
                    left: -0.75rem;
                    content: '•';
                }
            }
        }

        @media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
            &__short-list {
                display: none;
            }
        }
    }

    @media screen and (max-width: #{$layout-breakpoint-sm - 1}) {
        &__layout-canvas {
            tp-details-content {
                padding-top: 3.125rem;
            }
        }
        &__topics {
            .details__content-label {
                padding-top: 3.125rem;
            }
        }
    }

    &__layout-combined {
        @media screen and (max-width: 747px) {
            /**
            ----------------------------
            |..........title...........|
            ----------------------------
            |.......content-1..........|
            ----------------------------
            |......sub-content-1.......|
            ----------------------------
            |.......content-2..........|
            ----------------------------
            |......sub-content-2.......|
             */
            grid-template-columns: 1fr;
            grid-row-gap: 1.875rem;
            grid-template-rows: min-content;

            .details__title {
                grid-row: 1;
            }

            .details__content-1 {
                grid-row: 2;
            }

            .details__sub-content-1 {
                grid-row: 3;
            }

            .details__content-2 {
                grid-row: 4;
            }

            .details__sub-content-2 {
                grid-row: 5;
            }
        }

        @media screen and (min-width: 748px) and (max-width: #{$layout-breakpoint-sm - 1}) {
            /**
            ----------------------------
            |..........title...........|
            ----------------------------
            | sub-content-1 | content-1|
            |...............| content-1|
            ----------------------------
            |.......content-2..........|
            ----------------------------
            |......sub-content-1.......|
            ----------------------------
             */
            grid-template-columns: 150px minmax(0, 1fr);
            grid-column-gap: 0.875rem;
            grid-row-gap: 1.875rem;

            .details__title {
                grid-column: 1 / span 2;
                grid-row: 1;
            }

            .details__sub-content-1 {
                grid-column: 1;
                grid-row: 2;
            }

            .details__content-1 {
                grid-column: 2;
                grid-row: 2 / span 2;
            }

            .details__content-2 {
                grid-column: 1 / span 2;
                grid-row: 4;
            }

            .details__sub-content-2 {
                grid-column: 1 / span 2;
                grid-row: 5;
            }

            .details__sub-content-1 {
                grid-column: 1;
                grid-row: 2;
            }
        }

        @media screen and (min-width: $layout-breakpoint-sm) {
            /**
            ----------------------------
            |..........title...........|
            ----------------------------
            | sub-content-1 | content-1|
            | sub-content-2 | content-2|
            ----------------------------
             */
            grid-template-columns: 210px minmax(0, 1fr);
            grid-column-gap: 1.75rem;
            grid-row-gap: 2.5rem;

            .details__title {
                grid-column: 1 / span 2;
            }

            .details__column {
                display: grid;
                grid-template-rows: min-content;
                grid-row-gap: 2.5rem;
            }
        }
    }

    &__layout-single {
        /**
        ------------
        | content-1|
        ------------
         */
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    &.readonly {
        .mat-checkbox-inner-container {
            opacity: 0.5;
        }
    }
}
