.get-to-know {
    &__dialog {
        .form-footer nav {
            justify-content: center;
        }
    }

    &__user-image {
        width: 8.125rem;
        height: 8.125rem;
        background-size: cover;
        object-fit: cover;
        border-radius: 50%;
        background-position: center;
        margin-bottom: 2rem;
    }
}
