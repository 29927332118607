.sta-entity-applications-table-view {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 2.5rem;

    .mat-cell {
        font-size: 0.875rem;
    }

    .complete-staffing-btn {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .skills-list {
        width: calc(100% - 1.5rem);
    }

    mat-icon.checked {
        display: inline-block;
        float: left;
        height: 1.125rem;
        width: 1.125rem;
        padding: 0.25rem;
        margin-right: 0.25rem;
        border-radius: 50%;
        background-color: #64a71a;
        visibility: hidden;
        margin-top: 1rem;

        &.visible {
            visibility: visible;
        }
    }

    .mat-column {
        padding: 1rem 0;

        &-identity {
            flex-basis: 140px;
            flex-grow: 1;
            flex-shrink: 0;

            display: flex;
            justify-content: flex-start;
            align-items: unset;
            white-space: pre-wrap;

            &.mat-cell {
                flex-direction: column;
            }

            .avatar-block {
                display: flex;
                flex-direction: row;
                margin-bottom: 0.5rem;
            }

            .identity-avatar {
                width: 50px;
                min-width: 50px;
                height: 50px;
                border-radius: 50%;
                background-position: center;
                margin-right: 1rem;
                object-fit: cover;
                background-size: cover;
            }

            .name-and-location {
                width: calc(100% - 3rem);
                margin-left: 1.4rem;

                .name,
                .location {
                    display: block;
                    line-height: 1.1;
                    white-space: normal;
                }
                .name {
                    font-weight: bold;
                    white-space: normal;
                    padding-right: 0.75rem;
                }
            }
        }

        &-skills {
            flex-basis: 190px;
            flex-grow: 0;

            .column-mode {
                max-width: 190px;
            }

            &.table__header-cell {
                padding-left: 0px;
            }

            .no-matched-skills {
                font-weight: 500;
                color: $mediumGreyColor;
            }
        }

        &-interestInSkills {
            flex-basis: 190px;
            flex-grow: 0;

            .column-mode {
                max-width: 190px;
            }
        }

        &-actions {
            flex-basis: 110px;
            flex-grow: 0;
            align-items: unset;

            tp-icon-button + tp-sta-entity-applications-reject-button,
            tp-icon-button + tp-sta-entity-applications-cancel-button,
            tp-sta-entity-applications-reject-button + tp-sta-entity-applications-cancel-button,
            tp-icon-button + .button-wrapper {
                margin-left: 0.625rem;
            }

            .button-wrapper {
                line-height: 0;
                display: inline-block;
            }
        }

        &-status {
            flex-basis: 150px;
            flex-shrink: 0;
            flex-grow: 0;
            justify-content: flex-end;
            align-items: flex-start;

            .mat-icon.approved {
                color: $successColor;
            }

            .mat-button[disabled='true'] {
                color: inherit;
            }
        }
    }
}

.sta-entity-applications-list-view {
    .complete-staffing-btn {
        display: block;
        margin: 1.5rem 0 1rem auto;
        text-align: right;
    }

    tp-skills-list {
        &.hide-empty-skills {
            .without-skills {
                display: none;
            }
        }
    }

    .skills-separator {
        width: 100%;
        height: 1px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid $rowDivider;
    }

    mat-icon.checked {
        display: inline-block;
        float: left;
        height: 1.125rem;
        width: 1.125rem;
        padding: 0.25rem;
        margin-right: 0.25rem;
        border-radius: 50%;
        background-color: #64a71a;
        visibility: hidden;

        &.visible {
            visibility: visible;
        }
    }

    .avatar-block {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.5rem;
    }

    .name-and-location {
        margin-left: 1.4rem;
        flex-direction: column;

        .name,
        .location {
            display: block;
            line-height: 1.1;
            white-space: normal;
        }
        .name {
            font-weight: bold;
            white-space: normal;
            padding-right: 0.75rem;
        }
    }

    .application-table {
        margin-top: 1rem;
        padding-bottom: 1rem;

        &:not(:last-child) {
            border-bottom: 1px solid $mediumGreyColor;
        }
        &:first-child {
            margin-top: 2.5rem;
        }
        &:last-child {
            margin-bottom: 1.25rem;
        }

        &__row {
            display: grid;
            grid-template-columns: 3fr 2fr;
            column-gap: 0;

            padding-top: 5px;
            padding-bottom: 5px;

            align-items: center;

            font-size: 0.875rem;
            word-break: break-word;

            &.not-actual {
                .identity-avatar {
                    opacity: 0.5;
                }
                * {
                    color: $mediumGreyColor;
                }
            }

            &--skills {
                display: flex;
                padding: 0 1.25rem;
                margin-bottom: 1rem;
                align-items: unset;

                .mat-column-skills {
                    max-width: 50%;
                }
            }

            &--name {
                grid-template-columns: 1fr;
            }

            .mat-column-interestInSkills {
                margin-left: 1rem;
            }
        }

        &__label-cell {
            text-transform: uppercase;
            font-size: 0.625rem;
            line-height: 0.625rem;
            font-weight: 500;
            letter-spacing: 0.4px;
            text-align: right;
        }

        &__identity-img-cell {
            .identity-avatar {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-position: center;
                object-fit: cover;
                background-size: cover;
            }
        }

        &__identity-name-and-location-cell {
            .name,
            .location {
                display: block;
                line-height: 1.1;
            }
            .name {
                font-weight: bold;
            }
        }

        &__actions {
            display: flex;
            flex-direction: row;

            & > *:not(:first-child) {
                margin-left: 0.5rem;
            }

            button + button,
            button + .button-wrapper {
                margin-top: 5px;
            }
        }

        &__skills-cell {
            display: flex;

            p {
                margin-bottom: 0;
            }
        }

        &__status-cell {
            text-align: right;
        }

        &__status-cell--disabled {
            padding: 0;
            color: $accentColor;

            .mat-icon {
                color: $primaryColor;
            }
        }

        hr {
            margin-top: 1.875rem;
            margin-bottom: 1.875rem;
            color: $lightGrey;
        }
    }

    .show-more-skills-dropdown_title {
        white-space: nowrap;
    }

    @media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
        .complete-staffing-btn {
            width: 100%;
            margin-bottom: 2rem;
        }
    }
}

.skills-wrap {
    padding-left: 1.25rem;

    &:first-of-type {
        margin-bottom: 1rem;
    }

    .mat-icon {
        margin-right: 0.5rem;
    }
}
