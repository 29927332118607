.sign-up-menu__trigger-button {
    text-transform: uppercase;
    text-align: start;
}

.sign-up-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    .mat-icon {
        width: 11px;
        height: 11px;
        margin-left: 16px;
        svg {
            height: 11px;
        }
    }
}

@media only screen and (min-width: 600px) {
    .sign-up-menu .sign-up-menu_close {
        display: none;
        visibility: hidden;
    }
}
