.mat-snack-bar-container {
    background-color: $greyColor;

    &.container--wide {
        max-width: 100%;

        .snack-action button {
            border: none;
            text-transform: uppercase;
            font-weight: bold;
            color: $primaryColor !important;
        }
    }
}
.snack {
    position: relative;
    display: flex;

    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    color: #fff;

    &-close,
    &-action {
        cursor: pointer;
        margin-left: 16px;
    }

    &-wrapper {
        flex: 0 0;
        flex-basis: auto;
    }

    &-icon {
        height: 3.5em;
        width: 3.5em;
        margin-right: 10px;
        svg {
            height: inherit;
        }
    }
}
