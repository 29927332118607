.blog-article-list {
    display: block;
    overflow-y: auto;

    padding: 60px 70px;

    &__header {
        width: 100%;
    }
    &__category-label {
        color: $greyColor;
        opacity: 0.5;
        padding-bottom: 8px;
    }

    &__category-title {
        padding-bottom: 44px;
    }
}
