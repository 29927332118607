.mat-dialog-title {
    padding-bottom: 30px;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    position: relative;

    button {
        position: absolute;
        right: -2.5rem;
        top: -1.875rem;
    }
}

.mat-dialog-container {
    max-width: 56.25rem;
    padding: 3.75rem 4.375rem !important;

    .mat-dialog-actions {
        margin: 1.8125rem 0 0;
        padding-bottom: 0;
    }
}

.mat-dialog-content {
    padding-bottom: 1px !important; // Prevent scrollbar from showing up immediately
}

.dialog-content {
    display: flex !important;
    flex-direction: row;
    &__column {
        &--left {
            padding-right: 27px;
            mat-icon {
                fill: $mediumGreyColor;
                height: 124px;
                width: 124px;
                margin-top: -12px;
                svg {
                    height: inherit;
                    width: inherit;
                }
            }
        }
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {
    .mat-dialog-container {
        margin: 0 2.25rem;
    }
}

@media screen and (max-width: $layout-breakpoint-xs) {
    .cdk-overlay-pane {
        max-width: 100vw !important;
    }

    .mat-dialog-container {
        padding: 1rem !important;
        margin: 0 0.5rem;
    }
    .dialog-content {
        &__column {
            &--left {
                display: none;
            }
        }
    }
}
