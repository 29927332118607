$layout-breakpoint-xxxs: 280px !default;
$layout-breakpoint-xxs: 360px !default;
$layout-breakpoint-500: 500px !default;
$layout-breakpoint-xs: 600px !default;
$layout-breakpoint-700: 700px !default;
$layout-breakpoint-sm: 960px !default;
$layout-breakpoint-md: 1280px !default;
$layout-breakpoint-mdlg: 1400px !default;
$layout-breakpoint-lg: 1920px !default;

// Less than mixins
@mixin lt-xxs-screen {
    @media only screen and (max-width: #{$layout-breakpoint-xxs - 1}) {
        @content;
    }
}

@mixin lt-xs-screen {
    @media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
        @content;
    }
}
@mixin lt-md-screen {
    @media only screen and (max-width: #{$layout-breakpoint-md - 1}) {
        @content;
    }
}

// Greater than mixins
@mixin gt-xs-screen {
    @media only screen and (min-width: $layout-breakpoint-xs) {
        @content;
    }
}

@mixin gt-sm-screen {
    @media only screen and (min-width: $layout-breakpoint-sm) {
        @content;
    }
}

@mixin gt-md-screen {
    @media only screen and (min-width: $layout-breakpoint-md) {
        @content;
    }
}

@mixin gt-mdlg-screen {
    @media only screen and (min-width: $layout-breakpoint-mdlg) {
        @content;
    }
}

@mixin gt-lg-screen {
    @media only screen and (min-width: $layout-breakpoint-lg) {
        @content;
    }
}
