@mixin sidenav-theme($topColor, $bottomColor, $contrastColor) {
    $signOutIconSize: 24px;
    $navItemIconSize: 18px;
    $navigation-focus-color: #94d1ff;

    .sidenav_topics {
        position: relative;
    }

    .sidenav {
        $header: max-content;
        $links: auto;
        $footer: max-content;

        display: grid;
        grid-template-rows: $header $links $footer;

        position: relative;
        flex-direction: column;
        height: 100%;
        width: 100%;
        background: $topColor linear-gradient(0deg, $bottomColor 0%, $topColor 100%);
        color: $contrastColor;

        overflow-y: auto;
        overflow-x: hidden;
    }

    .sidenav_header {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;

        align-items: center;
        justify-content: flex-start;

        margin-top: 20px;
        margin-bottom: 20px;
    }

    .sidenav__logo-link {
        position: relative;
        display: inline-block;
        height: auto;
        line-height: 1;
    }

    .sidenav__profile-link {
        &.cdk-keyboard-focused {
            outline: $navigation-focus-color solid 2px !important;
        }
    }

    .sidenav__logo {
        width: 100%;
    }

    .sidenav__avatar {
        height: 5rem;
        width: 5rem;
        object-fit: cover;
        background-size: cover;
        border-radius: 50%;
        background-position: center;
    }

    .sidenav_powered-by {
        position: relative;
        display: inline;
    }

    .sidenav_language-switcher {
        position: relative;
        display: block;
        font-size: 80%;
        min-height: 34px;
    }

    .sidenav_links {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .sidenav_divider {
            width: 80%;

            @media (min-height: 1281px) {
                margin: 3rem auto;
            }

            @media (max-height: 1280px) {
                margin: 2rem auto;
            }

            @media (max-height: 900px) {
                margin: 0.75rem auto;
            }

            border-top-width: 1px;
            border-color: rgba(0, 0, 0, 0.5);
        }

        .sidenav_link {
            position: relative;
            display: block;
            &.cdk-keyboard-focused {
                outline: $navigation-focus-color solid 2px !important;
            }
        }

        .sidenav_link-edit {
            display: none;
        }

        .active .sidenav_link-edit {
            display: inline-block;
            position: absolute;
            right: 13px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            cursor: pointer;
            color: $topColor;
            svg {
                display: inline-block;
                vertical-align: middle;
                height: 14px;
            }
            &:hover {
                color: white;
            }
        }
    }

    h6.sidenav_top-links-header,
    h6.sidenav_topic-links-header,
    h6.sidenav_bottom-links-header {
        width: 100%;
        position: relative;
        margin: 0 auto;
        padding: 4px calc(#{$navItemIconSize} + 18px);
        opacity: 0.4;
        font-weight: 500;
    }

    .sidenav_select-topics {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        right: 18px;
        top: 5px;
        height: 20px;
        width: 20px;
        text-align: center;
        cursor: pointer;
        color: white;
        border-radius: 50%;
        background-color: rgb(0, 0, 0);

        mat-icon {
            height: 14px;
            width: 14px;

            svg {
                height: 14px;
            }
        }
    }

    .sidenav_link-icon.mat-icon {
        vertical-align: middle;
        margin-right: 5px;
        height: $navItemIconSize;
        width: $navItemIconSize;
        svg {
            height: inherit;
            max-width: $navItemIconSize;
        }
    }

    .sidenav_link-icon-badge {
        .mat-badge-content {
            width: 20px;
            height: 20px;
            text-align: center;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            left: -10px !important;
            top: -16px !important;
            color: #fff;
            transform: translate(5px, 100%);
        }
        &.mat-badge-hidden {
            .mat-badge-content {
                display: none;
            }
        }
    }

    .indicator-applicants {
        position: absolute;
        left: 0.35rem;
        top: 50%;
        display: block;
        border-radius: 50%;
        width: 0.6rem;
        height: 0.6rem;
        margin-top: -0.3rem;
        background-color: $primaryColor;
    }

    .sidenav_top-links,
    .sidenav_topic-links,
    .sidenav_bottom-links {
        width: 100%;
        margin: 0;
        padding: 0;

        li {
            position: relative;
            list-style-type: none;
            line-height: 30px;
            border-radius: 20px;
            margin: 0px auto;
            height: 30px;
            width: 230px;
            white-space: nowrap;
            font-size: 0.875rem;
            font-weight: 500;

            a {
                position: relative;
                display: inline-block;
                padding: 0 18px;
                height: 100%;
                width: 100%;
                vertical-align: middle;
            }
        }

        li.active {
            background-color: rgba(255, 255, 255, 0.25);
        }

        li:hover:not(.active) {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    .sidenav_bottom-links {
        margin-bottom: 20px;
    }

    .sidenav_footer {
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: 20px;
    }

    .sidenav_sign-out-toggle {
        position: relative;
        display: inline-block;
        min-width: $signOutIconSize;
        height: 24px;
        font-size: 12px;
        cursor: pointer;
        mat-icon {
            vertical-align: middle;
        }
        &:hover {
            color: $primaryColor;
        }
    }
}
