$buttonSize: 56px;

.rank-and-select-topics .drop-list-group {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;

    .drop-list-container {
        flex-basis: 0;
        flex-grow: 1;
        max-width: calc(50% - 85px);
        width: 100%;

        .drop-list-container--empty-item {
            border-radius: 3px;
            margin-bottom: 5px;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: bold;
            padding: 6px 15px;
            min-height: $buttonSize;
            display: flex;
            align-items: center;
            border: 1px dashed #8e93a4;
            position: absolute;
            width: 100%;
            &[id='0'] {
                top: 61px;
            }
            &[id='1'] {
                top: 122px;
            }
            &[id='2'] {
                top: 183px;
            }
            &[id='3'] {
                top: 244px;
            }
            &[id='4'] {
                top: 305px;
            }
            &[id='5'] {
                top: 366px;
            }
            &[id='6'] {
                top: 427px;
            }
            &[id='7'] {
                top: 488px;
            }
        }
    }

    .drag-space {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 110px;
        width: 110px;
        position: relative;
    }

    .drag-arrow {
        width: 90px;
        position: absolute;
        top: -25px;
        left: 8px;
    }
}

.rank-and-select-topics .cdk-drop-list {
    display: block;
    list-style: none;
    margin: 0;
    min-height: 100px;
    padding: 0 0 $buttonSize;
    position: relative;

    &.right {
        list-style: none;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        width: 100%;

        li:first-of-type {
            margin-top: 1px;
        }
    }

    &.warning-border {
        padding-bottom: 0;
        border-radius: 3px;
        box-shadow: 0 0 10px #ff9300;

        li:last-child {
            margin-bottom: 0;
        }
    }
}

.rank-and-select-topics .cdk-drop-list-dragging {
    .cdk-drag {
        tp-info-toggle {
            display: none;
        }
    }
}

.module-selection-drop-list-container-item.cdk-drag {
    background: #f2f2f2;
    color: $greyColor;
    border-radius: 3px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    padding: 6px 15px;
    min-height: $buttonSize;
    position: relative;
    display: flex;
    align-items: center;

    &.empty {
        min-height: $buttonSize;
        background: none;
        box-shadow: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        cursor: default;
    }

    &.cdk-drag-preview {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;

        background: $highlightColor;
        cursor: pointer;

        @include tp-box-shadow-all;

        tp-info-toggle {
            display: none;
        }

        .drag-lines {
            &:before,
            &:after {
                background: #212121;
            }
        }

        tp-info-toggle {
            display: none;
        }

        .placeholder {
            display: none;
        }
    }

    &.cdk-drag-placeholder {
        opacity: 1 !important;
        background: none !important;
        padding: 6px 15px;
        border: 1px dashed #4d4d4d;

        tp-info-toggle {
            display: none;
        }

        .placeholder {
            text-transform: initial;
            font-style: italic;
            font-weight: normal;
            display: inline-block;
            opacity: 1;
        }

        .text-content {
            display: none;
        }

        .drag-lines {
            display: none;
        }

        mat-icon {
            display: none;
        }
    }

    .placeholder {
        display: none;
    }

    mat-icon {
        padding-top: 0;

        svg {
            height: 24px;
            width: 24px;
        }
    }

    .text-content {
        width: 100%;
        padding-left: 16px;
        padding-right: 34px;
        margin: 0;
        position: relative;

        .category {
            font-size: 12px;
            font-weight: 400;
            line-height: 1;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            padding-top: 5px;
            padding-bottom: 2px;
            position: absolute;
            top: -19px;

            mat-icon {
                font-size: 0.6rem;
                padding-right: 3px;
                line-height: 1;
                min-width: auto;
                width: auto;
                height: auto;
            }
        }

        .title {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 1.1;
        }
    }

    .title {
        mat-icon {
            padding-right: 8px;
        }
    }

    .drag-lines {
        display: block;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -3px;

        &:before {
            display: block;
            content: '';
            width: 18px;
            height: 1px;
            margin-bottom: 3px;
            background: #9e9e9e;
        }

        &:after {
            display: block;
            content: '';
            width: 18px;
            height: 1px;
            background: #9e9e9e;
        }
    }

    &:hover {
        cursor: pointer;

        tp-info-toggle {
            display: block;
        }
    }

    tp-info-toggle {
        display: none;
        position: absolute;
        left: -23px;
        top: 50%;
        margin-top: -15px;
        opacity: 0.5;
    }
}

.rank-and-select-topics .num-list-container {
    display: flex;
    margin-right: 1rem;

    .num-list {
        list-style: none;
        counter-reset: topics-counter;
        margin: 0;
        padding: 0;
        width: calc(#{$buttonSize} + 4px);

        li {
            counter-increment: topics-counter;
            position: relative;
            width: $buttonSize;
            height: $buttonSize;
            margin-bottom: 5px;

            &:before {
                display: flex;
                position: absolute;
                left: 0;
                top: 0;
                content: counter(topics-counter);
                text-transform: uppercase;
                font-size: 16px;
                font-weight: bold;
                padding: 0;
                justify-content: center;
                align-items: center;
                border-radius: 3px;
                width: $buttonSize;
                height: $buttonSize;
                text-align: center;
            }

            &:not(.active-num-box) {
                &:before {
                    border: 1px solid #707070;
                    color: #707070;
                }
            }

            &.last-num-box:before {
                opacity: 0.5;
            }
        }
    }
}
