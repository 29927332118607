.card {
    &__actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: $foregroundColor;

        button + button,
        tp-icon-button + tp-icon-button {
            margin-left: 20px;
        }

        tp-icon-button {
            button {
                background-color: #fff;
            }
        }

        @media screen and (max-width: 499px) {
            @include paddingMobile();
            margin: auto auto 1.375rem;
        }

        @media screen and (min-width: 500px) and (max-width: 747px) {
            @include paddingDesktop();
            margin: auto auto 1.375rem;
        }
    }
}
