.my_page_placeholder {
    &--wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        background-image: url('/assets/images/placeholder/my_page_placeholder.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        max-width: 1024px;
        height: 218px;
        padding: 1.5rem 2.5rem 1.5rem 0;
    }
    &--text {
        margin-bottom: 2em;
    }
    &--cta {
        font-weight: bold;
        margin-bottom: 0.5em;
    }
}
