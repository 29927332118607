.add-profile {
    &__container {
        width: 100%;
        min-height: 900px;
        background-repeat: no-repeat;
        background-position: 0 60px;
        background-size: contain;
    }

    &--tab-bar-placeholder {
        .add-profile__container {
            margin-top: 24px;
            border-top: 1px solid $foregroundColor;
        }
        .add-profile__text {
            padding-top: 60px;
        }
    }

    &--people {
        .add-profile__container {
            background-image: url('/assets/images/backgrounds/unlock_people_bg.png');
        }
    }
    &--offer {
        .add-profile__container {
            background-image: url('/assets/images/backgrounds/unlock_offers_bg.png');
        }
    }
    &__text {
        padding: 10px 0 60px;
        width: 50%;
    }
}
