.route-transition-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(0, 0, 0);
    opacity: 0.3;
    z-index: 99999;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &--active {
        visibility: visible;
    }

    &__loader {
        width: 70px;
        height: 80px;
        text-align: center;
        font-size: 20px;
        div {
            background-color: #fff;
            height: 100%;
            width: 6px;
            margin: 0 4px;
            display: block;
            float: left;
            -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
            animation: sk-stretchdelay 1.2s infinite ease-in-out;
        }

        div:nth-of-type(2) {
            -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
        }

        div:nth-of-type(3) {
            -webkit-animation-delay: -1s;
            animation-delay: -1s;
        }

        div:nth-of-type(4) {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        }

        div:nth-of-type(5) {
            -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
        }
    }
}

@-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
    }
}

@keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
    }
}
