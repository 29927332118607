.entity-not-found {
    .mat-dialog-content {
        margin: 0;
        padding: 0;

        a {
            margin-top: 4rem;
        }
    }
    &__img-cnt {
        width: 100%;
        text-align: center;
        img {
            max-width: 100%;
            max-height: 30em;
        }
    }
    .mat-dialog-actions {
        margin-bottom: 0;
    }
    .form-footer {
        nav {
            justify-content: center;
        }
    }

    @media only screen and (max-width: 960px) {
        .mat-dialog-content {
            a {
                margin-top: 2rem;
            }
        }

        .grid-layout {
            .grid-layout_span-12--md {
                grid-row: 1;
                grid-column: span 12;
            }
        }
        &__img-cnt {
            img {
                max-width: 15rem;
            }
        }
    }
}
