.card {
    &__cta {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: auto;

        @media screen and (max-width: 499px) {
            @include paddingMobile();
        }

        @media screen and (min-width: 500px) and (max-width: 747px) {
            @include paddingDesktop();
        }

        @media screen and (max-width: 747px) {
            tp-inverted-button,
            tp-primary-icon-button,
            tp-secondary-icon-button {
                margin: 0.5rem 0.5rem 0;
            }

            &--mobile-align-center {
                justify-content: center;
            }

            &--mobile-align-left {
                justify-content: flex-start;
            }
        }

        @media screen and (min-width: 748px) {
            tp-inverted-button,
            tp-primary-icon-button,
            tp-secondary-icon-button {
                margin-bottom: 0.5rem;
            }

            tp-inverted-button + tp-inverted-button,
            tp-inverted-button ~ tp-primary-icon-button,
            tp-inverted-button ~ tp-secondary-icon-button,
            tp-secondary-icon-button ~ tp-primary-icon-button,
            tp-secondary-button ~ tp-primary-icon-button {
                margin-left: 1rem;
            }

            &--desktop-align-center {
                justify-content: center;
            }

            &--desktop-align-left {
                justify-content: flex-start;
            }
        }
    }
}
