.edit-skill-list-table {
    $buttonSize: 30px;
    $buttonBorderRadius: 0.75rem;
    $buttonPadding: 0 15px;

    &__skill--highlight {
        display: inline-flex;
        align-items: center;
        height: $buttonSize;
        border-radius: $buttonBorderRadius;
        padding: $buttonPadding;
        background-color: $highlightColor;
        border: 1px solid $highlightColor;
    }

    .edit-skill-button {
        &__add-btn {
            height: $buttonSize;
            line-height: 100%;
            border-radius: $buttonBorderRadius;
            padding: $buttonPadding;
            border-color: $mediumGreyColor;

            .mat-icon {
                display: inline-flex;
                margin-left: 0.5rem;
                height: 11px;
                width: 11px;
                svg {
                    height: 11px;
                    margin-top: 2px;
                }
            }
        }
    }

    @media screen and (max-width: $layout-breakpoint-700 - 1px) {
        .mat-column-skill {
            flex-grow: 2;
        }

        mat-cell:first-of-type {
            padding-left: 0;
        }

        mat-cell:last-of-type {
            padding-right: 0;
        }
    }
}

.edit-skill-button-actions-menu-popover {
    margin-top: 12px;
    margin-bottom: 12px;
}

.edit-skill-button-actions-menu-popover .edit-skill-button-actions-menu {
    width: 220px;

    mat-icon {
        display: flex;
        align-items: center;
    }

    &__header {
        padding-bottom: 0;
        text-transform: uppercase;
        font-size: 0.625rem;
        font-weight: 500;
    }

    &__divider {
        border-top-color: white;
    }

    &__skill-level-btn {
        font-size: 0.875rem;
        font-weight: 500;
        background-color: $lightGrey;

        .label {
            margin-left: 10px;
            margin-right: auto;
        }

        mat-icon {
            width: inherit;
        }

        .check-mark-icon {
            color: $primaryColor;
        }
    }

    &__cancel-btn {
        font-size: 0.875rem;
        .mat-icon {
            height: 24px;
            width: 24px;
            margin-right: 5px;
        }
        .mat-list-item-content {
            justify-content: center;
        }
    }
}
