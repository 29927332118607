@import './common';
@import './content';
@import './facts';
@import './title';
@import './tags';
@import './sub-content';
@import './actions';
@import './organizer';
@import './cta';
@import './text';
@import './indicators';
@import './badge';

.card {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 1024px;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 1.25rem;

    @include tp-box-shadow-all;

    @media screen and (max-width: 747px) {
        flex-direction: column;
        justify-content: stretch;
    }

    @media screen and (min-width: 748px) {
        flex-direction: row;
        min-height: 256px;
    }
}
