.mat-menu-content--no-padding {
    .mat-menu-content:not(:empty) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.mat-menu__direct-messages {
    max-width: 400px !important;
}
