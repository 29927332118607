.logout-page {
    background-position: center;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url('/api/v2/tenant-settings/assets/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.logout-dialog {
    .mat-dialog-actions {
        margin-bottom: 0;
    }
}
