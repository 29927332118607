button mat-icon.fa::before {
    vertical-align: middle;
}

mat-icon {
    svg {
        height: 24px;
        width: auto;
        display: block;
        margin: auto;
    }

    &:not(.default-color) {
        svg {
            path {
                fill: currentColor;
            }
        }
    }
}

.mat-icon.small {
    height: 10px;
}

mat-icon.small svg {
    height: 100%;
}

.mat-icon.medium-grey svg {
    color: $mediumGreyColor;
}

.mat-icon.checked svg {
    color: #fff;
}

.mat-icon.bigger,
mat-icon.bigger svg {
    height: 35px;
}

p .mat-icon {
    height: 1.2em;
}
