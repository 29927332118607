.mentoring-profile-interest-form {
    .motivation-select {
        justify-content: left;
        align-items: flex-start;

        .motivation-select__option {
            padding: 1rem;
            font-size: 0.9rem;
        }
    }
    @media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
        .motivation-select__label-wrapper {
            width: calc(100% - 2rem);
        }
    }
}

.mentoring-profile-motivation-form,
.mentoring-profile-interest-form {
    .motivation-select {
        padding-left: 0;

        .motivation-error,
        .motivation-select__errors {
            display: none;
        }

        &.ng-touched.ng-invalid {
            .motivation-select__errors {
                display: block;
            }
            .motivation-error {
                display: block;
                font-size: 1rem;
                height: 1.5rem;
                line-height: 1.5;
                text-align: left;
            }
        }
    }
}
