@import '~@angular/material/theming';

@mixin tenant-ui-material-typography() {
    @media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
        $custom-typography: mat-typography-config(
            $font-family: 'Roboto, sans-serif',
            $headline: mat-typography-level(26px, 34px, 500),
            $title: mat-typography-level(20px, 28px, 500),
            $subheading-2: mat-typography-level(17px, 24px, 500),
            $body-2: mat-typography-level(14px, 20px, 500),
            $body-1: mat-typography-level(14px, 20px, 400),
            $input: mat-typography-level(14px, 1.5, 400),
        );

        @include angular-material-typography($custom-typography);
        @include mat-core($custom-typography);

        $mat-chips-typography-config: mat-typography-config(
            $body-2:
                mat-typography-level(
                    $font-size: 14px,
                    $font-weight: 500,
                ),
        );
        @include mat-chips-typography($mat-chips-typography-config);
    }

    @media only screen and (min-width: $layout-breakpoint-xs) {
        $custom-typography: mat-typography-config(
            $font-family: 'Roboto, sans-serif',
            $display-3: mat-typography-level(65px, 65px, 400),
            $display-1: mat-typography-level(36px, 40px, 400),
            $headline: mat-typography-level(30px, 40px, 500),
            $title: mat-typography-level(24px, 32px, 500),
            $subheading-2: mat-typography-level(18px, 25px, 500),
            $body-2: mat-typography-level(16px, 23px, 500),
            $body-1: mat-typography-level(16px, 23px, 400),
            $input: mat-typography-level(14px, 1.5, 400),
        );

        // todo investigate why settings of button is ignored
        // $button: mat-typography-level(14px, 40px, 500, $letter-spacing: 0.5px),

        @include angular-material-typography($custom-typography);
        @include mat-core($custom-typography);

        $mat-chips-typography-config: mat-typography-config(
            $body-2:
                mat-typography-level(
                    $font-size: 14px,
                    $font-weight: 500,
                ),
        );
        @include mat-chips-typography($mat-chips-typography-config);
    }
}
