$mainLayoutContainerPadding: 3.125rem;

.main-layout {
    display: block;
    position: relative;
    width: 100vw;
    height: 100vh;

    header:first-of-type,
    tp-header {
        // the tp-header is a quick-fix until workshops has been reworked
        h1 {
            display: flex;
            margin-top: 16px;
        }
        tp-icon-button {
            margin-right: 0px;
            .mat-icon-button,
            .mat-icon-button:hover {
                border: none !important;
            }
            svg path {
                fill: #484e5f;
            }
            .mat-icon-button:hover {
                svg {
                    fill: $primaryColor;
                }
            }
        }
    }
}

.main-layout_container {
    position: relative;
    display: block;
    width: 100vw;
    height: 100vh;
}

.main-layout_sidenav-container {
    position: relative;
    display: block;
    width: 270px;
    height: 100vh;

    .main-layout_sidenav-toggle {
        position: absolute;
        color: $navContrastColor;
        top: 0;
        right: 0;
    }

    @include sizeImagePicker(7rem);
}

.main-layout_main-container {
    position: relative;
    display: grid;
    height: 100vh;
    padding: $mainLayoutContainerPadding;

    &::-webkit-scrollbar-button {
        display: none;
        width: 0px;
        height: 0px;
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track {
        background-color: #fff;
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        width: 5px;
        height: 60px;
        -webkit-border-radius: 7px;
        border-radius: 7px;
        &:hover {
            background-color: $mediumGreyColor;
        }
    }
    &.scrollable {
        &::-webkit-scrollbar-thumb {
            background-color: $mediumGreyColor;
        }
    }

    .main-layout_header {
        margin-top: -$mainLayoutContainerPadding;
        margin-right: -$mainLayoutContainerPadding;
        margin-left: -$mainLayoutContainerPadding;

        &.profile-view-header {
            margin-top: 0;
            & .profile-view-header__action-bar {
                padding-right: 1.5rem;

                .action-button ~ .action-button {
                    margin-left: 0.625rem;
                }
            }
        }
    }

    .main-layout_footer {
        margin-right: -$mainLayoutContainerPadding;
        margin-left: -$mainLayoutContainerPadding;
        padding: 0;
    }

    .main-layout_sidenav-toggle {
        position: fixed;
        background-color: #333;
        color: $navContrastColor;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        width: 2rem;
        height: 2.2rem;
        line-height: 2.2rem;
        font-size: 2rem;
        top: 15px;
        left: 0;
        z-index: 2;
    }

    section:not(:last-child) {
        margin-bottom: 3.125rem;
    }

    .slide-list-layout {
        section {
            margin-bottom: 0;
        }
    }

    .main-layout_margin-bottom {
        margin-bottom: 3.125rem;
    }

    .tab-container-gray {
        margin: -3.125rem;
        padding: 3.125rem;
        display: block;
        background-color: $lightGrey;
        min-height: calc(100vh - 172px);

        .card,
        .mat-form-field-outline-start,
        .mat-form-field-outline-end {
            background-color: #fff;
        }
        .custom-tp-simple-select-icon {
            z-index: 1 !important;
            pointer-events: none;
        }
    }
}

.main-layout_sidenav-toggle--shown {
    display: block;
    visibility: visible;
}

.main-layout_sidenav-toggle--hidden {
    display: none;
    visibility: hidden;
}

.main-layout_sub-navigation {
    margin-bottom: 3.125rem;
}
