$chipHeight: 27px;
$rowHeight: $chipHeight + 8px;

tp-tagger,
tp-generation-exchange-tagger,
tp-campaigns-tagger {
    position: relative;
    display: block;

    &.simple-view {
        .mat-basic-chip {
            display: inline-block;
            font-size: 16px;
            font-weight: 700;
            padding: 2px 13px;
            margin: 5px 3px 0;
            line-height: 24px;
            color: #646464;
            background-color: #f0f0f0;
            vertical-align: middle;
            outline: 0;

            &.mat-chip-selected {
                font-weight: bold;
            }
        }
    }

    &:not(.simple-view) {
        height: $rowHeight * 3;

        .mat-chip-list-wrapper {
            margin: 0;
            justify-content: center;
        }

        mat-basic-chip {
            display: inline-block;
            padding: 0 3px;
            white-space: nowrap;
            color: inherit;
            user-select: none;
            font-size: 0.85rem;
            outline: 0;
            position: relative;
            margin-right: 7px;
            line-height: 1.5em;
            font-weight: normal;
        }
        mat-basic-chip:after {
            content: '\00b7';
            font-weight: bold;
            position: absolute;
            right: -5px;
        }
        mat-basic-chip:last-child:after {
            content: none;
        }

        .mat-chip-selected {
            background-color: $highlightColor;
            font-weight: bold;
            border-radius: 5px;
            padding: 0px 6px;
        }
    }

    &.selectable .mat-basic-chip {
        cursor: pointer;
        transition: background-color 0.1s ease-out;
    }

    &.tagger--thin {
        .mat-basic-chip:not(.mat-chip-selected) {
            font-weight: 300;
        }
    }
}
