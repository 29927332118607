.mentoring {
    nav,
    main {
        max-width: 1024px;
    }

    .filters-container {
        margin-bottom: -1.5rem;

        tp-mentoring-context-select {
            display: flex;
            align-items: center;
            padding-top: 15px;
        }
    }
}
