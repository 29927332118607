.info-toggle {
    display: inline-block;
    font-size: 1.2rem;

    &__link {
        display: flex;
        align-items: center;
    }

    &__link {
        &:not(.disabled) {
            .info-toggle__icon {
                cursor: pointer;
                &:hover {
                    transform: scale(1.2);
                }
            }
        }
    }

    &__icon {
        transition: all 0.2s ease-in-out;

        &.mat-icon {
            height: 100%;
            max-height: 24px;
        }

        svg {
            path {
                color: #5c6277;
            }
        }

        &::before {
            vertical-align: middle;
        }
    }
}
