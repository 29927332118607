.details {
    .details__facts {
        display: block;
        width: 100%;
        line-height: 1.25rem;
        font-size: 0.8rem;
        white-space: pre-wrap;
        font-weight: 600;

        &-segment {
            display: inline-flex;
            align-items: center;
            vertical-align: middle;
            line-height: 100%;
            white-space: nowrap;
            margin-bottom: 0.625rem;

            &:not(:last-child) {
                margin-right: 1.625rem;
            }

            &-icon {
                font-size: 1.5em;
                height: auto;
                width: auto;
                margin-right: 0.5rem;
            }

            &-button {
                position: relative;
                margin-left: 5px;
                line-height: 20px;
                width: 20px;
                height: 20px;
                font-size: 9px;
                box-shadow: none;
                background-color: $mediumGreyColor;

                .mat-button-wrapper {
                    padding: 3px 0;
                    line-height: 14px;
                }
            }

            &--uppercase {
                text-transform: uppercase;
            }

            &-company {
                max-width: 7.8em;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        @media screen and (max-width: 747px) {
        }

        @media screen and (min-width: 748px) {
        }
    }
}
