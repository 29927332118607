@import '../../../node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import '~@angular/material/theming';
.time-picker {
    display: inline-flex;
    vertical-align: middle;

    input {
        margin-left: 2.5rem;
        line-height: 100%;
    }

    &__button-container {
        display: inline-block;

        .mat-icon-button {
            position: absolute;
            top: 0.25rem;
        }
    }

    &.w-100 {
        width: 100%;
    }
}

.mat-datepicker-content .mat-icon-button.mat-calendar-previous-button,
.mat-datepicker-content .mat-icon-button.mat-calendar-next-button {
    border: none;
    &:hover {
        border: none;
    }
}
