.blog-article-list-item {
    display: block;
    position: relative;

    width: 100%;
    padding-bottom: 40px;
    cursor: pointer;

    &__img-container {
        position: relative;
        display: inline-block;

        width: 80px;
        height: 80px;
        border-radius: 80px;
        box-sizing: border-box;
        overflow: hidden;

        transform: scale(1);
        transition: transform 0.3s ease-out;

        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat no-repeat;
    }

    &:hover {
        &__img-container {
            transform: scale(1.1);
            transition: transform 0.3s ease-out;
        }
    }

    &__content-wrapper {
        position: relative;
        display: inline-block;
        padding-left: 20px;

        width: calc(100% - 85px);
        box-sizing: border-box;

        vertical-align: top;
    }

    &__date {
        position: relative;
        color: $greyColor;
        padding-bottom: 20px;
    }

    &__title {
        display: block;
    }

    @media only screen and (min-width: 600px) {
        &__img-container {
            width: 130px;
            height: 130px;
            border-radius: 130px;

            img {
                width: 130px;
                height: 130px;
            }
        }

        &__content-wrapper {
            width: calc(100% - 136px);
        }
    }
}
