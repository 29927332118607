.blog__category-menu {
    position: relative;
    display: flex;

    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    width: 100%;
    height: 100%;
    background-color: $blogArticlesBackgroundColor;
    color: $greyContrastColor;

    padding: 60px 40px;

    &--close {
        align-self: flex-end;
    }

    &__title {
        position: relative;
        display: block;

        font-weight: 500;
        padding-bottom: 30px;
        opacity: 0.5;
    }

    &__category-list {
        margin: 0;
        padding: 15px 0;
        border-top: 1px solid $greyContrastColor;
        font-weight: 500;

        overflow: auto;
    }

    &__category {
        padding: 15px 0;
        list-style: none;
        cursor: pointer;

        &--active {
            color: $highlightColor;
            font-weight: 700;
        }
    }

    // may not be displayed
    &__header {
        padding-bottom: 84px;
        .mat-icon {
            height: auto;
            width: auto;
        }
    }
}
