[contenteditable='true'] {
    &[placeholder]:empty {
        &:hover {
            cursor: text;
        }

        &:before {
            content: attr(placeholder);
            color: #555;
        }

        &:focus {
            &:before {
                content: '';
            }
        }
    }
}
