@mixin resizeAngularCheckbox($size: 16px) {
    .mat-checkbox-inner-container {
        height: $size;
        width: $size;
    }
}

.checkbox {
    .mat-checkbox-background,
    .mat-checkbox-frame {
        border-radius: 4px;
    }

    &.checkbox--big {
        @include resizeAngularCheckbox(20px);
    }

    &.checkbox--super-big {
        @include resizeAngularCheckbox(24px);
    }

    &.checkbox--column {
        display: block;
    }
}
