.workshop-entity-form {
    .image-picker {
        display: flex;
        justify-content: center;
    }

    @media only screen and (min-width: $layout-breakpoint-700) and (max-width: #{$layout-breakpoint-sm - 1}) {
        .workshop-entity-form__title {
            grid-column: span 6;
        }
    }
}
