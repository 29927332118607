$headerTopBorder: $mediumGreyColor;
$headerBackground: whitesmoke;
$rowDivider: whitesmoke;

.table {
    width: 100%;

    &__header-row {
        border-top: 1px solid $headerTopBorder;
        min-height: auto;
        background-color: $headerBackground;
        border-bottom-color: $rowDivider;
    }

    &__header-cell {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.625rem;
        padding-left: 12px;
        &.align-center {
            justify-content: center;
        }
        .mat-sort-header-button {
            text-transform: uppercase;
        }
    }

    &__row {
        border-color: $rowDivider;
        padding: 0.625rem 0;
        min-height: auto;

        &.big-padding {
            padding: 1.25rem 0;
        }

        &.applicants-structure {
            align-items: unset;
        }
    }

    &__cell {
        font-size: 0.875rem;
        padding-left: 12px;

        &.mat-column-skills {
            padding-left: 1.3rem;
        }
        &.mat-column-interestInSkills {
            padding-left: 0;
            tp-skills-list,
            tp-show-more-skills-dropdown {
                margin-left: 12px;
            }

            tp-skills-list {
                &.hide-empty-skills {
                    .without-skills {
                        display: none;
                    }
                }
            }
        }

        &.column-direction {
            flex-direction: column;
            align-items: start;
        }
        .mat-chip.mat-standard-chip {
            display: block;
        }
        &.align-center {
            justify-content: center;
        }
        &.align-right {
            justify-content: right;
            padding-right: 0;
        }
        &.align-left {
            justify-content: left;
            padding-right: 0;
        }
        &.bold {
            font-weight: bold;
        }
        &.not-actual {
            .identity-avatar {
                opacity: 0.5;
            }
            * {
                color: $mediumGreyColor;
            }
        }

        .skills-separator {
            width: 100%;
            height: 1px;
            margin-bottom: 0.5rem;
            border-bottom: 1px solid $rowDivider;
        }
    }

    &--no-first-last-cell-padding {
        mat-cell:first-of-type {
            padding-left: 0;
        }

        mat-cell:last-of-type {
            padding-right: 0;
        }
    }
    &--inline {
        th,
        td {
            border: 1px solid;
            padding-left: 5px;
            padding-right: 5px;
        }
        border-collapse: collapse;
    }
}
