.questionnaire {
    .questionnaire__body-text {
        font-weight: 500;
    }
    .questionnaire__body-btn-cnt {
        text-align: center;
        padding-top: 2rem;
    }
    .questionnaire--first-step {
        background-image: url('/global_assets/questionnaire/start.png');
        background-size: 80%;
        background-position: center bottom;
        background-repeat: no-repeat;
        min-height: 90vh;
    }
    .questionnaire__img-cnt {
        width: 100%;
        text-align: center;
        img {
            max-width: 100%;
            max-height: 15em;
        }
    }
}
