.no-match-placeholder {
    &__container {
        display: flex;
    }
    &__content {
        &-left,
        &-right {
            width: 50%;
        }
        &-left {
            display: flex;
            padding-right: 50px;
            border-right: solid 1px $mediumGreyColor;
            align-items: flex-end;
            text-align: end;
            tp-secondary-button {
                button {
                    margin-left: 5px;
                    margin-top: 20px;
                }
            }
        }
        &-right {
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-self: start;
            .no-match-placeholder__text {
                padding-left: 35%;
            }
        }
    }
    &__link {
        &-left {
            flex: 0 0 50%;
            margin-left: 10px;
        }
    }
    &__image {
        &-left {
            width: 100%;
            align-self: center;
        }
        &-right {
            width: 100%;
            margin-top: -60px;
        }
    }
    &__text {
        font-size: 22px;
        color: $greyColor;
        line-height: 30px;
        font-weight: 500;
    }
}

@include lt-xs-screen {
    .no-match-placeholder {
        &__container {
            flex-direction: column-reverse;
        }
        &__content {
            &-left,
            &-right {
                width: 100%;
            }
            &-left {
                padding: 50px 0;
                border-right: none;
            }
            &-right {
                padding: 0 0 50px 0;
                border-bottom: solid 1px $mediumGreyColor;
            }
        }
    }
}
