.job-x-tandem-check-dialog {
    .mat-dialog-container {
        min-width: 100%;
        position: relative;
    }

    .job-x__tandem-check-close {
        position: absolute;
        top: 1rem;
        right: 1rem;

        .mat-icon {
            &,
            svg {
                color: $mediumGreyColor;
                fill: currentColor;
            }
        }
    }
}
