// IE10+ detection
@mixin ie-only() {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

$default-focus-color: #0088f0;
$navigation-focus-color: #94d1ff;

// disable outline for mouse input
*[role='button']:focus:not(.focus-visible),
a:focus:not(.focus-visible),
button:focus:not(.focus-visible) {
    outline: 0;
}

.cdk-drag,
.mat-form-field-type-text-editor div[contenteditable] {
    outline: 0;
    &.cdk-keyboard-focused,
    &.cdk-program-focused {
        outline: $default-focus-color solid 2px;

        &::-moz-focus-inner {
            border: 0;
        }
    }
}

// outline for all focusable elements except side navigation
.focus-visible {
    outline: $default-focus-color solid 2px !important;

    // hide dotted outline for Firefox
    &::-moz-focus-inner {
        border: 0;
    }
}

.mat-card.cdk-mouse-focused {
    outline: none;
}

// outline for side navigation
mat-sidenav .focus-visible {
    outline: $navigation-focus-color solid 2px !important;
}

//outline on expansion panel toggle button inside
.saas .mat-expansion-panel.expansion-panel-transparent {
    .mat-expansion-panel-header.cdk-keyboard-focused {
        background: none;
        outline: none !important;

        .mat-content {
            outline: $navigation-focus-color solid 2px;
        }
    }
}
//outline expansion panel header
.saas .mat-expansion-panel {
    & .mat-expansion-panel-header.expansion-panel-header-profile {
        &.cdk-keyboard-focused,
        &.cdk-program-focused {
            &:not([aria-disabled='true']) {
                position: relative;
                outline: none !important;
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 70px;
                    border: $default-focus-color solid 2px;
                }
                &.mat-expanded mat-panel-description:after {
                    background: linear-gradient(to right, rgba(245, 245, 245, 0) 90%, rgba(245, 245, 245, 1) 100%);
                }
            }
        }
    }
}
//panel header: show topic buttons on header focus or if it has focused children
.layout-medium mat-expansion-panel {
    & mat-expansion-panel-header.expansion-panel-header-profile {
        .mat-content {
            overflow: visible;
        }
        &.cdk-keyboard-focused,
        &.cdk-program-focused,
        &:focus-within {
            tp-update-topic:not(.explanation),
            tp-delete-topic:not(.explanation) {
                opacity: 1;
            }
        }
        &:focus, /* implementation for browsers not supporting :focus-within, related polyfill used */
        &.focus-within {
            tp-update-topic:not(.explanation),
            tp-delete-topic:not(.explanation) {
                opacity: 1;
            }
        }
    }
}

tp-subnav .mat-tab-link.focus-visible {
    outline-offset: -4px;

    @include ie-only() {
        outline: 0 !important;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 2px;
            display: block;
            width: calc(100% - 4px);
            height: 46px;
            border: $default-focus-color solid 2px;
        }
    }
}

/*
todo needs to be reviewed
tp-matching-view mat-card tp-tagger mat-basic-chip,
.mat-chip-list-wrapper .mat-standard-chip,
tp-tags-suggestion .mat-basic-chip {
    &.focus-visible {
        outline: 0 !important;
        box-shadow: inset 0 0 0 2px $default-focus-color;
        border: none !important;
    }
}
*/

// Fix of "Focusable element may be missing focus styling" FF Accessibility tool issue.
// It's kinda a hack.
// These styles will be overridden by styles in .focus-visible.
tp-sidenav-footer button.menu-launcher:focus,
tp-sidenav-navigation button.open-topic-add-modal-btn:focus {
    outline-color: transparent;
}

mat-form-field {
    &.cdk-keyboard-focused,
    &.cdk-program-focused {
        &:focus-within,
        &.focus-within {
            outline: $default-focus-color solid 2px !important;
        }
    }
    mat-select,
    input,
    textarea {
        &.focus-visible {
            outline: 0 !important;
        }
    }
}

.mat-button,
.mat-flat-button,
.mat-icon-button,
.mat-stroked-button {
    &.cdk-keyboard-focused,
    &.cdk-program-focused {
        .mat-button-focus-overlay {
            opacity: 0 !important;
        }
    }
}
