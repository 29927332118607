@use 'sass:math';

$loader-size: 60px;
$loader-line-height: $loader-size - 5px;
$loader-speed: 2.75s;
$loader-size-repeat-count: 3;
$loader-container-size: $loader-size * ($loader-size-repeat-count + 2);

.gooey-container {
    display: inline-block;
    width: 100%;
    height: 100%;
    min-height: 180px;
    position: relative;

    .gooey {
        position: absolute;
        top: calc(50% - 30px);
        left: calc(50% - #{math.div($loader-container-size, 2)});
        width: $loader-container-size;
        filter: url(#goo);
        height: $loader-size;
        display: inline-block;

        span {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            text-align: center;
            line-height: $loader-line-height;
            font-weight: bold;
            animation: loadingText $loader-speed ease infinite;
            font-size: 1.4rem;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            display: block;
            border-radius: 50%;
            width: $loader-size;
            height: $loader-size;
            z-index: 2;
            left: calc(50% - #{$loader-size});
        }

        &::before {
            animation: leftGoo $loader-speed ease infinite;
            background: $primaryColor;
        }

        &::after {
            margin-left: $loader-size;
            animation: rightGoo $loader-speed ease infinite;
            background: $highlightColor;
        }
    }

    > svg {
        height: 0;
    }

    @keyframes loadingText {
        0% {
            opacity: 0;
        }
        15% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        85% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes leftGoo {
        50% {
            transform: translateX($loader-size * $loader-size-repeat-count);
        }
    }

    @keyframes rightGoo {
        50% {
            transform: translateX($loader-size * $loader-size-repeat-count * -1);
        }
    }
}
