@import './details.layouts';
@import './content';
@import './facts';
@import './sub-content';
@import './organizer';
@import './title';
@import './actions';
@import './header-image';

.details {
    display: grid;
}

@media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
    .details {
        display: block;
    }
}
