.form-footer {
    padding: 3.75rem 0 0;

    &--ignore-sibling-padding {
        padding: 1.875rem 0 0;
    }

    &--actions-padding {
        padding: 3.125rem 0 0;
    }

    .nav__buttons--right {
        justify-content: space-between;
        display: flex;
    }
}

@media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
    .form-footer {
        &--buttons-center {
            nav {
                justify-content: center;
                display: flex;
                flex-direction: column;

                tp-primary-button,
                tp-primary-icon-button,
                tp-secondary-button,
                tp-secondary-icon-button {
                    width: 100%;
                    button,
                    a {
                        width: 100%;
                    }
                }

                .nav__buttons--right {
                    tp-primary-button,
                    tp-primary-icon-button,
                    tp-secondary-button,
                    tp-secondary-icon-button {
                        width: 45%;
                        button,
                        a {
                            width: 100%;
                        }
                    }
                }
            }
        }
        nav {
            width: 100%;
            row-gap: 0.625rem;
        }
    }
}

@media only screen and (min-width: $layout-breakpoint-xs) {
    .form-footer {
        nav {
            display: flex;
            justify-content: center;
            width: 100%;

            tp-tertiary-button ~ tp-primary-button,
            tp-secondary-button ~ tp-primary-button,
            tp-secondary-button ~ tp-primary-icon-button,
            tp-secondary-icon-button ~ tp-primary-button,
            tp-secondary-icon-button ~ tp-sta-entity-participate-button,
            tp-secondary-icon-button ~ tp-projects-entity-participate-button {
                margin-left: 1.25rem;
            }
        }

        &--buttons-center {
            nav {
                justify-content: center;

                tp-link ~ tp-link,
                a ~ a {
                    margin-left: 1.25rem;
                }
            }
        }

        &--buttons-space-between {
            nav {
                justify-content: space-between;
            }
        }
    }
}

@media only screen and (min-width: $layout-breakpoint-sm) {
    .form-footer {
        padding: 3.75rem 0 0;

        &--ignore-sibling-padding {
            padding: 3.75rem 0 0;
        }
    }
}
