.tp-chart {
    display: block;
    position: relative;
}

.chart-toolbar {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;

    button {
        &:not(:last-of-type) {
            margin-bottom: 6px;
        }
    }
}

.analytics-controls {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .mat-form-field-container {
        flex: 0 calc(50% - 5px);
    }

    > :not(:last-of-type) {
        margin-right: 5px;
    }
}
