.generation-exchange-profile-form {
    mat-checkbox {
        padding-bottom: 1rem;

        .mat-checkbox-layout {
            white-space: normal;
        }
    }

    .motivation-select {
        justify-content: left;
        align-items: flex-start;
        padding-left: 0;
        padding-bottom: 0;
        max-height: none;

        .motivation-select__option {
            padding: 1.25rem;
            font-size: 0.9rem;
            max-width: 700px;
            width: auto;
        }
        .motivation-select__label-wrapper {
            min-width: auto;
            width: auto;
        }

        .motivation-error,
        .motivation-select__errors {
            display: none;
        }

        &.ng-touched.ng-invalid {
            .motivation-select__errors {
                display: block;
            }
            .motivation-error {
                display: block;
                font-size: 1rem;
                height: 1.5rem;
                line-height: 1.5;
                text-align: left;
            }
        }
    }
}
