.sign-up-layout {
    position: relative;
    display: grid;

    grid-template-rows: 3.75rem minmax(auto, max-content) minmax(3.75rem, auto);
    grid-template-columns: 100%;

    width: 100vw;
    height: 100vh;

    background-image: url('/api/v2/tenant-settings/assets/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
}

.sign-up-layout_header {
    position: relative;
    display: grid;
    grid-template-columns: 160px auto 160px;
    grid-template-rows: 100%;
    align-items: center;

    grid-row: 1 / 1;

    .sign-up-layout_menu {
        justify-self: right;
        color: white;
        grid-column: 3;
        margin-right: 1rem;
    }
}

.sign-up-layout_main {
    position: relative;
    display: grid;
    grid-template-rows: max-content auto;
    grid-template-columns: 100%;
    overflow: auto;
    max-width: $overlayMaxWidth;
    border-radius: $overlayRadius;
    background-color: $backgroundColor;
}

.sign-up-layout_content-header {
    position: relative;
    display: grid;
    grid-template-rows: auto auto;
    align-items: center;

    h1 {
        position: relative;
        margin: 0;
        grid-row: 1 / 1;
        grid-column: 1 / 1;

        .mat-icon {
            margin-right: 0.5em;
            height: 28px;
        }
    }

    h2 {
        position: relative;
        margin: 0;
        grid-row: 2 / 2;
        grid-column: 1 / 1;
        font-size: 1rem;
    }
}

.sign-up-layout_content {
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: -10px 0 0 $lightGrey inset;
}

@mixin setSignUpLayoutMobileNavigation() {
    .sign-up-layout_footer nav {
        display: grid;
        width: 100%;
        row-gap: 0.625rem;
        justify-content: center;
    }
}

@mixin setSignUpLayoutDesktopNavigation() {
    .sign-up-layout_footer nav {
        display: flex;
        justify-content: center;
        width: 100%;

        tp-secondary-icon-button ~ tp-primary-button {
            margin-left: 1.25rem;
        }
    }
}

@mixin setSignUpLayoutSpaces($mainMarginLeftRight, $mainPaddingLeftRight, $mainPaddingTopDown, $secondaryPadding) {
    .sign-up-layout_main {
        margin: 0 $mainMarginLeftRight;
    }

    .sign-up-layout_content {
        padding: $secondaryPadding $mainPaddingLeftRight;
    }

    .sign-up-layout_content-header {
        padding: $mainPaddingTopDown - $secondaryPadding 0 $secondaryPadding + $secondaryPadding;
    }

    .sign-up-layout_footer {
        padding: $mainPaddingTopDown 0 $mainPaddingTopDown;
    }
}

@media only screen and (max-width: #{$layout-breakpoint-xxs - 1}) {
    .sign-up-layout_main {
        border-radius: 0;
    }

    @include setSignUpLayoutSpaces(0, 1rem, 1rem, 1rem);
    @include setSignUpLayoutMobileNavigation();
}

@media only screen and (min-width: $layout-breakpoint-xxs) and (max-width: #{$layout-breakpoint-500 - 1}) {
    @include setSignUpLayoutSpaces(1.25rem, 2rem, 3.75rem, 1rem);
    @include setSignUpLayoutMobileNavigation();
}

@media only screen and (min-width: $layout-breakpoint-500) and (max-width: #{$layout-breakpoint-xs - 1}) {
    @include setSignUpLayoutSpaces(4.25rem, 3.75rem, 3.75rem, 1rem);
    @include setSignUpLayoutMobileNavigation();
}

@media only screen and (min-width: $layout-breakpoint-xs) and (max-width: #{$layout-breakpoint-sm - 1}) {
    @include setSignUpLayoutSpaces(4.25rem, 3.75rem, 3.75rem, 1rem);
    @include setSignUpLayoutDesktopNavigation();
}

@media only screen and (min-width: $layout-breakpoint-sm) {
    .sign-up-layout {
        grid-template-columns: auto $overlayMaxWidth auto;
    }

    .sign-up-layout_header {
        grid-column: 1 / span 3;
    }

    .sign-up-layout_main {
        grid-column: 2 / 2;
    }

    @include setSignUpLayoutSpaces(0, 3.75rem, 3.75rem, 1rem);
    @include setSignUpLayoutDesktopNavigation();
}
