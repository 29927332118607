.select-topics {
    .mat-button-toggle-group {
        width: 100%;
        border: none;

        .mat-button-toggle,
        .mat-button-toggle-label-content {
            width: 100%;
        }
    }

    div.toggle-buttons-container {
        &.warning-border {
            margin: 0 7px;
            box-shadow: 0 0 10px #ff9300;
            border-radius: 4px;
            padding-bottom: 0;

            mat-button-toggle {
                margin: 0 0 7px;
            }
        }
    }

    mat-button-toggle {
        margin: 12px;
        display: block;
        border-radius: 0.3rem;
        background-color: $lightGrey;

        &.mat-button-toggle-checked {
            background-color: $highlightColor;
            box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
        }

        .content {
            padding: 8px;
            width: calc(100% - 40px);
            white-space: nowrap;
            display: flex;
            align-items: center;
            min-height: 80px;

            .text-content,
            mat-icon {
                display: inline-block;
                vertical-align: top;
            }

            mat-icon {
                font-size: 2rem;
                align-self: center;
                min-width: 42px;

                svg {
                    height: 1.6rem;
                }
            }

            .text-content {
                padding: 0 0 0 16px;
                width: calc(100% - 60px);
                text-align: left;
                border: none;
                position: relative;

                .category {
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    font-weight: bold;
                    font-size: 12px;
                    position: absolute;
                    top: -17px;

                    mat-icon {
                        margin-left: 0;
                        font-size: 1rem;
                        padding-right: 3px;
                        line-height: 1;
                        min-width: auto;
                        width: auto;
                        height: auto;
                    }
                }

                .title {
                    margin: 0;
                    line-height: 1.5;
                    font-size: 20px;
                    padding: 0;
                    text-transform: uppercase;
                    font-weight: bold;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            .checkbox {
                position: absolute;
                top: 50%;
                margin-top: -25px;
                right: 25px;
                pointer-events: none;

                .mat-checkbox-frame {
                    background-color: white;
                }
            }
        }
    }

    @media only screen and (max-width: $layout-breakpoint-xs) {
        mat-button-toggle {
            .content {
                mat-icon {
                    min-width: 1.2rem;
                    svg {
                        height: 1.2rem;
                    }
                }
                .text-content {
                    padding-left: 10px;
                    width: calc(100% - 45px);

                    .title {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
