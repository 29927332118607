.modal-form-layout {
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100vw;
    height: 100vh;

    grid-template-columns: auto 100% auto;
    grid-template-rows: 3.125rem minmax(auto, max-content) minmax(3.125rem, auto);

    color: $foregroundColor;
    background-color: rgba(0, 0, 0, 0.65);
}

.modal-form-layout_main {
    position: relative;
    display: flex;
    grid-column: 2 / 2;
    grid-row: 2 / 2;

    overflow: hidden;

    background-color: $backgroundColor;
    flex-direction: column;

    justify-content: flex-start;
    align-items: stretch;

    border-radius: 10px;
    box-shadow: 0 5px 20px 8px rgb(0 0 0 / 40%);

    .form-header {
        &--image-header-mode {
            .form-header_close {
                position: absolute;
                top: 0;
                right: -3.5rem;
                z-index: 1;
                &.white-color {
                    .mat-icon-button:not(:hover) {
                        .mat-icon {
                            svg {
                                fill: #fff;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    tp-details-image-header {
        .form-header_close {
            width: 1.25rem;
            height: 1.25rem;
            position: relative;
            display: block;
            color: white;
            z-index: 1;
            margin-top: 0.5rem;

            .mat-icon-button {
                width: 1.25rem;
                height: 1.25rem;
            }

            &.white-color {
                .mat-icon-button:not(:hover) {
                    .mat-icon {
                        svg {
                            fill: #fff;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.modal-form-layout_content {
    position: relative;
    display: block;
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;

    &.no-margin-top {
        padding-top: 0;
    }
    &.unset-position {
        position: unset;
    }

    &::-webkit-scrollbar-button {
        display: none;
        width: 0px;
        height: 0px;
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track {
        background-color: #fff;
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        width: 5px;
        height: 60px;
        -webkit-border-radius: 7px;
        border-radius: 7px;
        &:hover {
            background-color: $mediumGreyColor;
        }
    }
    &.scrollable {
        &::-webkit-scrollbar-thumb {
            background-color: $mediumGreyColor;
        }
    }

    .big-margin-top {
        margin-top: 1.625rem;
    }
}

@mixin setModalFormLayoutSpaces(
    $primaryMarginLeftRight,
    $primaryPaddingLeftRight,
    $primaryPaddingTopDown,
    $secondaryPadding
) {
    .modal-form-layout_main {
        margin: 0 $primaryMarginLeftRight;
    }

    .modal-form-layout_skills-area {
        padding: $secondaryPadding $primaryPaddingLeftRight;
    }

    .modal-form-layout_content {
        &:not(.modal-form-layout_padding-for-child) {
            padding: $secondaryPadding $primaryPaddingLeftRight $primaryPaddingTopDown;
        }
        &.modal-form-layout_padding-for-child {
            padding: 0;
            .form-footer--actions-padding {
                padding-right: $primaryPaddingLeftRight;
                padding-left: $primaryPaddingLeftRight;
                padding-bottom: $primaryPaddingTopDown;
            }
            .datails-padding {
                padding-right: $primaryPaddingLeftRight;
                padding-left: $primaryPaddingLeftRight;
            }
        }
    }

    .modal-form-layout_main .form-header {
        padding: $primaryPaddingTopDown $primaryPaddingLeftRight $secondaryPadding;
        &--image-header-mode {
            padding-top: 0rem;
            padding-bottom: 13.75rem;
            &.no-padding {
                padding: 0;
            }
            height: 0;
        }
    }
}

@media only screen and (max-width: #{$layout-breakpoint-xxs - 1}) {
    .modal-form-layout_main {
        border-radius: 0;
    }

    @include setModalFormLayoutSpaces(0, 1rem, 1rem, 1rem);
}

@media only screen and (max-width: #{$layout-breakpoint-500 - 1}) {
    @include setModalFormLayoutSpaces(1.25rem, 1.25rem, 1.25rem, 1rem);
}

@media only screen and (min-width: $layout-breakpoint-500) and (max-width: #{$layout-breakpoint-xs - 1}) {
    @include setModalFormLayoutSpaces(4.25rem, 3.75rem, 3.75rem, 1rem);
}

@media only screen and (min-width: $layout-breakpoint-xs) and (max-width: #{$layout-breakpoint-sm - 1}) {
    @include setModalFormLayoutSpaces(4.25rem, 3.75rem, 3.75rem, 1rem);
}

@media only screen and (max-width: #{$layout-breakpoint-sm - 1}) {
    .modal-form-layout_main .form-header {
        &--image-header-mode {
            padding-bottom: 13.75rem;
            .form-header_close {
                right: -1.5rem;
            }
            &.no-padding {
                padding: 0;
                .form-header_close {
                    right: 0.5rem;
                }
            }
        }
    }
}

@media only screen and (min-width: $layout-breakpoint-sm) {
    .modal-form-layout {
        grid-template-columns: auto $overlayMaxWidth auto;
    }

    .modal-form-layout_main .form-header {
        &--image-header-mode {
            padding-bottom: 13.75rem;
            &.no-padding {
                padding: 0;
            }
        }
    }

    .modal-form-layout_content {
        position: relative;
        display: block;
        flex-grow: 1;
    }

    .modal-form-layout_main {
        grid-template-columns: 40px auto 40px;
        grid-template-rows: 20px auto 40px;

        grid-column: 2 / 2;
        grid-row: 2 / 2;
        word-wrap: break-word;
    }

    @include setModalFormLayoutSpaces(0, 3.75rem, 3.75rem, 1rem);
}
