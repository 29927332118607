.nla-location-independent-popover {
    .popover {
        padding: 0.6rem;
        font-size: 0.8rem;
        font-weight: 400;
        max-width: 8em;
        color: $foregroundColor;
    }
    &.matched .popover {
        background-color: $highlightColor;
    }
}

.nla-locations-list {
    &.mat-list-base {
        padding: 0.8rem 0;
        .mat-list-item {
            height: 1.4rem;
        }
    }

    &__list-item {
        .mat-list-item-content {
            font-size: 0.8rem;
            font-weight: 400;
        }

        .matched {
            padding: 0 0.125rem;
            background-color: $highlightColor;
            font-weight: bold;
            line-height: 1.2rem;
        }
    }
}

.nla-profile-tile__locations-header {
    position: absolute;
    top: 0;
    left: 0.5rem;
    font-size: 0.7rem;
    line-height: 2.5rem;
    font-weight: 500;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    color: $foregroundColor;
    width: 100%;

    .mat-icon {
        color: inherit;
    }

    p.profile-title-locations {
        text-align: left;
    }
}
