.card {
    &__title {
        display: flex;
        position: relative;
        color: $greyColor;
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 0;
        line-break: anywhere;

        .card__status {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.5px;
            background-color: $mediumGreyColor;
            text-transform: uppercase;
            margin-right: 0.5rem;
            padding: 0 2px;
            position: relative;
            top: -5px;
        }

        .card__badge {
            display: inline-block;
            width: 14px;
            height: 14px;
            margin: 2px 0;
            border-radius: 50%;
            background-color: $primaryColor;
            left: 15px;
            top: 0.25rem;
        }

        @media screen and (max-width: 499px) {
            @include paddingMobile();
            .card__badge {
                left: 5px;
            }
        }

        @media screen and (min-width: 500px) and (max-width: 747px) {
            @include paddingDesktop();
        }

        @media screen and (min-width: 748px) {
            &-wrapper {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .card__badge {
                left: -25px;
            }
        }

        &--bold {
            font-weight: bold;
        }

        &--highlight {
            padding: 0 0.5rem;
            background-color: $highlightColor;
        }
    }

    &--dark {
        .card__title {
            color: white;
        }
    }

    &__sub-content &__title {
        color: white;
    }

    &--placeholder {
        .card__title {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
