.module-prioritization-mobile-drop-list-container-item.cdk-drag {
    background: $lightGrey;
    border-radius: 3px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 7px 15px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    position: relative;

    &.cdk-drag-preview {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: bold;
        min-height: 80px;
        display: flex;
        align-items: center;

        .placeholder {
            display: none;
        }
    }

    &.cdk-drag-placeholder {
        opacity: 1 !important;
        background: none !important;
        padding: 0;
        border: 1px dashed #4d4d4d;

        .placeholder {
            width: 100%;
            text-transform: initial;
            font-style: italic;
            font-weight: normal;
            color: #ccc;
            display: inline-block;
            opacity: 1;
            padding: 5px 15px;
        }

        .text-content {
            display: none;
        }

        .drag-lines {
            display: none;
        }

        mat-icon {
            display: none;
        }
    }

    .placeholder {
        display: none;
    }

    mat-icon {
        font-size: 2rem;
        align-self: center;
        min-width: 42px;

        svg {
            height: 1.6rem;
        }
    }

    .text-content {
        width: calc(100% - 60px);
        padding-left: 16px;
        position: relative;

        .category {
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 1;
            text-transform: none;
            position: absolute;
            top: -17px;

            mat-icon {
                font-size: 1rem;
                padding-right: 3px;
                line-height: 1;
                min-width: auto;
                width: auto;
                height: auto;
            }
        }

        .title {
            font-size: 20px;
            line-height: 1.5;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .drag-lines {
        display: block;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -5px;

        &:before {
            display: block;
            content: '';
            width: 18px;
            height: 2px;
            margin-bottom: 4px;
            background: $foregroundColor;
        }

        &:after {
            display: block;
            content: '';
            width: 18px;
            height: 2px;
            background: $foregroundColor;
        }
    }

    &:hover {
        cursor: pointer;
    }
}

.rank-topics {
    .drop-list-group {
        display: flex;
        flex-wrap: nowrap;
        margin: 0 7px 7px;

        .drop-list-container {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            width: 100%;
        }
    }

    .cdk-drop-list {
        display: block;
        list-style: none;
        margin: 0;
        min-width: 100px;
        padding: 0 0 0 44px;
        position: relative;

        .cdk-drag {
            min-height: 80px;
            display: flex;
            align-items: center;
        }
    }

    .cdk-drop-list-dragging {
        .cdk-drag {
            cursor: move;
        }
    }

    .num-list-container {
        display: block;

        .num-list {
            list-style: none;
            counter-reset: topics-counter;
            margin: 0;
            padding: 0;
            width: 44px;
            position: absolute;

            li {
                counter-increment: topics-counter;
                position: relative;
                width: 38px;
                height: 80px;
                margin-bottom: 5px;

                &:before {
                    display: flex;
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: counter(topics-counter);
                    text-transform: uppercase;
                    font-size: 20px;
                    font-weight: bold;
                    padding: 0;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3px;
                    width: 38px;
                    height: 80px;
                    text-align: center;
                }

                &:not(.active-num-box) {
                    &:before {
                        border: 1px solid #707070;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: $layout-breakpoint-xs) {
    .module-prioritization-mobile-drop-list-container-item.cdk-drag {
        mat-icon {
            min-width: 1.2rem;
            svg {
                height: 1.2rem;
            }
        }
        .text-content {
            padding-left: 10px;
            width: calc(100% - 45px);

            .title {
                font-size: 14px;
            }
        }
    }
}
