@mixin rotate($rotation) {
    transition: transform 0.3s linear;
    transform: rotate($rotation);
}

.collapsable {
    &__content {
        display: block;
        box-sizing: border-box;
        overflow-y: hidden;
    }

    &:not(.disabled) {
        .collapsable__trigger {
            cursor: pointer;
        }
    }

    &--closed {
        .collapsable__trigger--rotate-90 {
            @include rotate(0deg);
        }
        .collapsable__trigger--rotate-180 {
            @include rotate(0deg);
        }
    }

    &--opened {
        .collapsable__trigger--rotate-90 {
            @include rotate(90deg);
        }
        .collapsable__trigger--rotate-180 {
            @include rotate(180deg);
        }
    }
}
