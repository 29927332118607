$pagination-buttons-breakpoint: 1215px !default;

.generation-exchange-paginator {
    .bottom-buttons {
        padding: 2rem 0;
        display: flex;
        justify-content: space-between;
    }

    @media only screen and (min-width: $pagination-buttons-breakpoint) {
        .bottom-buttons {
            display: none;
        }
    }
    @media only screen and (max-width: 599px) {
        .bottom-buttons {
            display: grid;
            grid-template-columns: 100%;
            width: 100%;
            row-gap: 0.625rem;
            padding: 2rem;

            button {
                width: 100%;
            }
        }
    }
}
