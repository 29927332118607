.card {
    &__content {
        display: flex;
        overflow: hidden;
        white-space: pre-wrap;

        @media screen and (max-width: 747px) {
            padding: 2rem 0;

            &-aside-wrapper {
                position: relative;
                display: block;
                max-height: 100px;
                flex-grow: 0;
                flex-shrink: 0;
                overflow: hidden;
                margin-bottom: 1rem;
            }

            &-aside {
                position: relative;
                display: block;
                height: 100px;
            }

            &-main {
                width: 100%;
                min-width: 0;
            }
        }

        @media screen and (min-width: 748px) {
            padding: 1.5rem 2.5rem;

            flex-basis: 900px * 0.74;
            flex-shrink: 1;
            flex-grow: 1;

            &-main {
                flex-basis: 100%;
                flex-shrink: 1;
                flex-grow: 1;
                min-width: 0;
                display: flex;
                flex-direction: column;
            }

            &-aside-wrapper {
                position: relative;
                display: block;
                flex-basis: auto;
                flex-grow: 0;
                flex-shrink: 0;
                overflow: hidden;
            }

            &-aside {
                position: relative;
                display: block;
                width: 120px;

                box-sizing: content-box;
                @include paddingDesktop();
                padding-right: 0;
            }
        }
    }

    &--dark {
        background-color: $card-dark-color;
        color: white;
    }

    &--placeholder {
        @media screen and (max-width: 499px) {
            @include paddingMobile();
        }

        @media screen and (min-width: 500px) and (max-width: 747px) {
            @include paddingDesktop();
        }

        .card__content {
            flex-wrap: wrap;
            justify-content: center;
            padding-top: 3rem;

            @media screen and (min-width: 748px) {
                flex-basis: 595px;
            }
        }

        .card__content-main {
            flex-basis: 300px;
            flex-grow: 1;
        }

        .card__content-aside {
            padding-right: 1rem;
            padding-left: 1rem;
            padding-bottom: 1rem;
        }
    }
}
