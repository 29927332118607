.image-picker-dialog_content {
    position: relative;
}

.image-loader {
    width: 100%;
    height: 100%;
    margin: 0 -24px;
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 0;
    z-index: 99999;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &__loader {
        width: 70px;
        height: 80px;
        text-align: center;
        font-size: 20px;
        div {
            background-color: $mediumGreyColor;
            height: 100%;
            width: 6px;
            margin: 0 4px;
            display: block;
            float: left;
            -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
            animation: sk-stretchdelay 1.2s infinite ease-in-out;
        }

        div:nth-of-type(2) {
            -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
        }

        div:nth-of-type(3) {
            -webkit-animation-delay: -1s;
            animation-delay: -1s;
        }

        div:nth-of-type(4) {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        }

        div:nth-of-type(5) {
            -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
        }
    }
}

@-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
    }
}

@keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
    }
}
