.tile-layout {
    display: flex;
    flex-direction: column;
    height: auto;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
}

.tile-layout_title {
    width: 100%;
    margin: 0;

    h2 {
        display: inline;
    }
}

.tile-layout_content {
    display: grid;
    flex-grow: 1;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    border-radius: 0 0 4px 4px;
    justify-items: center;
    align-items: center;
    padding: 20px;
    max-height: 100%;
}

.tile-layout_content-icon {
    justify-self: start;
}

.tile-layout_title {
    padding: 15px 20px;
    display: flex;
    align-items: center;

    h2 {
        line-height: 1;
        margin: 0;
        vertical-align: middle;
    }

    .tile-layout_header-icon {
        width: 1.5rem !important;
        height: 1.5rem !important;
        margin-right: 0.5rem;
        display: inline-flex;
        svg {
            height: 1.5rem;
        }
    }
}

.tile-layout_content {
    .tile-layout_content-headline {
        font-size: 1.5rem;
        font-weight: normal;
        line-height: 1.4;
    }

    .tile-layout_content-icon {
        &.mat-icon {
            height: 7.5em;
            width: 7.5em;
            svg {
                height: 7.5em;
            }
        }

        img {
            height: 5em;
            width: 5em;
        }
    }
}

@media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
    .tile-layout_title {
        padding: 10px 10px;
    }

    .tile-layout_content {
        padding: 10px;
    }
}

@media only screen and (min-width: $layout-breakpoint-xs) {
    .tile-layout_translate-animation {
        transition: transform ease-in 0.3s;
    }

    .tile-layout_content {
        &:hover {
            .tile-layout_translate-animation {
                transform: translateY(-25%);
            }
        }
    }
}
