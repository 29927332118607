@function set-foreground-palette($theme) {
    $foreground: map-get($theme, foreground);
    $foreground: map_merge(
        $foreground,
        (
            text: $foregroundColor,
        )
    );
    $foreground: map_merge(
        $foreground,
        (
            divider: $foregroundColor,
        )
    );

    $result: map_merge(
        $theme,
        (
            foreground: $foreground,
        )
    );
    @return $result;
}

@function set-background-palette($theme) {
    $backgroundMap: map-get($theme, background);
    $backgroundMap: map_merge(
        $backgroundMap,
        (
            background: $backgroundColor,
            unselected-chip: $backgroundColor,
        )
    );

    $result: map_merge(
        $theme,
        (
            background: $backgroundMap,
        )
    );
    @return $result;
}

@mixin styleButtonIcon() {
    .mat-icon {
        height: 16px;
        width: 20px;
        vertical-align: text-top;
        margin: 0 4px 0 0;
        svg {
            height: inherit;
            max-width: 20px;
        }
    }
}

@media print {
    .modal-layout {
        display: block;

        tp-icon-button {
            display: none;
        }

        .modal-layout_main {
            display: block;
            overflow: visible !important;
        }

        .modal-layout_content {
            display: block;
            overflow: visible !important;
        }
    }
}

@mixin tenant-ui-material-theme($theme) {
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .tenant-ui-theme {
        color: mat-color($foreground, text);

        .mat-stroked-button {
            &:not(:disabled):hover .mat-button-focus-overlay,
            &:not(:disabled):focus .mat-button-focus-overlay {
                opacity: 0;
            }
        }

        .mat-subheading-2,
        .mat-typography h3 {
            margin: 0 0 1.25rem;
        }

        .mat-radio-label-content {
            font-size: 0.875rem;
        }

        .loader .spinner > svg {
            fill: map-get($primary, 500);
        }

        .align-center {
            display: flex !important;
            align-items: center;
        }

        tp-image-picker {
            svg path {
                color: map-get($primary, 500);
            }
        }

        .secondary-button {
            color: mat-color($foreground, text);
            border-radius: 30px;
            height: 2.5rem;
            line-height: 2.4rem;
            letter-spacing: 0.03125rem;
            font-weight: 500;
            font-size: 0.875rem;
            padding-left: 1.5625rem;
            padding-right: 1.5625rem;
            border: 1px solid map-get($primary, 500);
            &:hover {
                color: mat-color($foreground, text) !important;
                border-color: map-get($primary, 500) !important;
            }
        }

        tp-icon-button ~ tp-icon-button {
            margin-left: 1.25rem;
        }

        tp-icon-button {
            &:not(.no-border) {
                button {
                    &:hover {
                        svg {
                            fill: map-get($primary, 900);
                        }
                    }
                }
            }
            &.no-border {
                button {
                    border: none !important;
                    &:hover {
                        padding: 0.5px;
                        border: none !important;
                        svg {
                            fill: map-get($primary, 900);
                        }
                    }
                }
            }
            &.flip-vertically {
                > button {
                    transform: scaleY(-1);
                }
            }
            &.mat-primary {
                color: map-get($primary, 500);
            }
            &.big {
                .mat-icon {
                    height: 32px;
                }
            }
            &.add-skill-button {
                button {
                    margin-bottom: 0.9375rem;
                    margin-top: 0.425rem;
                }
            }
        }

        tp-primary-button,
        tp-primary-icon-button {
            button:not(:disabled):focus,
            button:not(:disabled):hover {
                background-color: map-get($primary, 900) !important;
            }
        }

        tp-link,
        tp-secondary-button,
        tp-secondary-icon-button {
            a.mat-primary,
            a.mat-primary:not(:disabled),
            button.mat-primary,
            button.mat-primary:not(:disabled) {
                border-color: $primaryColor !important;
                &:hover {
                    border-color: map-get($primary, 900) !important;
                }
            }

            &.disabled {
                a.mat-primary {
                    color: $mediumGreyColor !important;
                }
            }
        }

        tp-link {
            a,
            a:not(:disabled) {
                border-color: $primaryColor !important;
                &:hover {
                    border-color: map-get($primary, 900) !important;
                }
            }
        }

        tp-primary-button,
        tp-primary-icon-button,
        tp-secondary-button,
        tp-secondary-icon-button {
            &.text-color-white {
                button,
                button.mat-primary,
                a,
                a.mat-primary {
                    color: white !important; //TODO: move all styles to storybook
                }
            }

            &.background-color-white {
                button,
                button.mat-primary {
                    background-color: white !important;
                }
            }

            &.border-color-white {
                button,
                button.mat-primary,
                a,
                a.mat-primary {
                    border-color: white !important;
                    &:hover {
                        border-color: white !important;
                    }
                }
            }
        }

        tp-chip.no-margin {
            .mat-icon {
                margin-left: 0 !important;
            }
        }

        .mat-icon {
            &.primary {
                color: map-get($primary, 500);
                svg {
                    color: map-get($primary, 500);
                }
            }
        }

        .mat-icon-button {
            height: $buttonHeight;
            width: $buttonHeight;
            border: 1px solid $greyColor;
            padding: 0.5px;

            mat-icon {
                height: inherit;
                width: inherit;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                svg {
                    max-width: 24px;
                }
            }

            &.no-border {
                border: none;
                &:hover {
                    padding: 0.5px;
                    border: none;
                }
            }
        }

        .mat-stroked-button,
        .mat-flat-button,
        .mat-button {
            border-radius: $buttonBorderRadius;
            line-height: 34px;
            letter-spacing: 0.5px;
            font-weight: 500;
        }

        .mat-stroked-button,
        .mat-flat-button,
        .mat-icon-button,
        .mat-button {
            &.text-color-white {
                color: white;
            }

            &.background-color-white {
                background-color: white;
            }

            &.border-color-white {
                border-color: white;
            }
        }

        p.small {
            font-size: 0.875rem;
            line-height: 20px;
        }

        .mat-form-field-container {
            width: 100%;

            mat-label {
                display: block;
                font-size: 1rem;
                line-height: 1.25rem;
                font-weight: 600;
                color: $foregroundColor;

                &.required:after {
                    content: ' *';
                }
            }

            .motivation-select__label-wrapper {
                font-size: 0.875rem;
            }

            .mat-form-field {
                width: 100%;

                .mat-icon-button {
                    border: 0;

                    &:hover {
                        border: 0;
                    }
                }
            }

            mat-datepicker-toggle {
                color: $greyColor;
            }

            &.mat-form-field-container--custom-icon {
                .mat-form-field-appearance-outline {
                    .mat-form-field-suffix,
                    .mat-form-field-prefix {
                        top: 0;
                    }
                }
            }
        }

        mat-error {
            font-size: 0.75rem;
        }

        .mat-form-field-appearance-outline {
            .mat-form-field-wrapper,
            .mat-form-field-flex {
                margin: 0;
            }

            .mat-form-field-prefix {
                margin-right: 0.55em;
            }

            .mat-form-field-prefix,
            .mat-form-field-suffix {
                top: 0;
                -webkit-align-self: center;
                svg {
                    display: inline-block;
                }
            }

            .mat-form-field-wrapper {
                padding-bottom: 0;
            }

            .mat-form-field-infix {
                border-top: 0;
            }

            .mat-form-field-outline {
                top: 0;
                // the color of the field border - default state
                color: $mediumGreyColor;
            }

            .mat-select .mat-select-arrow-wrapper {
                transform: none;
            }

            .mat-form-field-outline-thick {
                // the color of the field border - hover/focus state
                color: $accentColor;
            }
        }

        .mat-chip.mat-standard-chip {
            border: 1px solid $mediumGreyColor;

            .mat-icon {
                color: map-get($primary, 500);
                opacity: 1;
                height: 11px;
                width: 11px;
                svg {
                    height: 14px;
                    margin-top: -1px;
                }
            }

            &:hover::after {
                opacity: 0.04;
            }

            &:focus::after {
                opacity: 0.04;
            }

            &.red-border {
                border-color: #f44336;
            }
        }

        .mat-option-text {
            color: $foregroundColor;
            font-size: 14px;
        }

        .mat-select-read-only {
            font-style: italic;
            pointer-events: none;

            .mat-form-field-outline {
                background: $lightGrey;
                border-radius: 5px;
            }

            .mat-select-disabled .mat-select-value {
                color: mat-color($foreground, text);
            }
        }

        tp-checkbox-item {
            .mat-checkbox-inner-container {
                width: 20px;
                height: 20px;
            }
            .mat-checkbox-frame,
            .mat-checkbox-background {
                border-radius: 6px;
            }
            &.selected {
                &.green-mode,
                &.green-mode.always-primary {
                    .mat-checkbox-background {
                        background-color: #64a71a !important;
                    }
                }
                &.always-primary {
                    .mat-checkbox-background {
                        background-color: map-get($primary, 500) !important;
                    }
                }
            }
        }

        .edit-skill-button-expand-level-mode {
            & > * {
                display: inline-block;
            }
            &--skill-name {
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
            }
            &--skill-level {
                cursor: pointer;
                .mat-chip.mat-standard-chip {
                    border-radius: 0;
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                }
            }
            .mat-standard-chip {
                display: inline-block;
                margin: 0;
            }
        }

        @media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
            .edit-skill-button-expand-level-mode {
                flex-direction: column;
                &--skill-name {
                    width: calc(100% - 2rem);
                    max-width: calc(100% - 2rem) !important;
                    border-radius: 0;
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                    border-bottom-left-radius: 0 !important;
                    text-align: center;
                }
                .mat-standard-chip {
                    width: 100%;
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                }
                &--skill-level {
                    width: calc(100% - 2rem);
                    .mat-chip.mat-standard-chip {
                        border-radius: 0;
                        border-bottom-left-radius: 15px;
                        border-bottom-right-radius: 15px;
                        text-align: center;
                    }
                }
            }
            .modal-form-layout {
                .form-header__note {
                    padding: 0.25rem 0.5rem;
                    height: auto;
                }
                .details__facts-segment {
                    white-space: break-spaces;
                    line-height: 1.25rem;
                }
            }
            nav {
                .mat-button {
                    border: 1px solid map-get($primary, 500);
                }
            }
        }

        tp-show-more-skills-dropdown {
            &.hidden {
                visibility: hidden;
            }
        }

        .show-more-skills-dropdown_wrapper {
            font-size: 12px;
            cursor: pointer;
            position: relative;
            margin: 0 0 0.25rem;

            .mat-icon {
                position: absolute;
                left: -1.25rem;
                height: 12px;
                margin-top: 4px;
                width: auto;

                &.revert {
                    transform: rotate(180deg);
                }

                svg {
                    height: 12px;
                    width: auto;
                }
            }

            & {
                align-items: center;
            }
        }

        .show-more-skills-dropdown_title {
            font-style: italic;
        }

        tp-details-target-groups {
            .mat-checkbox-inner-container {
                width: 20px;
                height: 20px;
            }
            .mat-checkbox-frame {
                border-radius: 6px;
            }
            .mat-checkbox-checked {
                .mat-checkbox-background {
                    border-radius: 6px;
                    background-color: #64a71a !important;
                }
            }
        }

        .edit-skill-button-expand-level-mode {
            &--skill-name {
                .mat-standard-chip {
                    padding: 3px 15px !important;
                }
            }
            &--skill-level {
                .mat-standard-chip {
                    padding: 3px 32px 3px 15px !important;
                    .mat-icon {
                        width: 24px !important;
                    }
                }
                .custom-level-arrow-icon {
                    color: $foregroundColor;
                    fill: $foregroundColor;
                }
            }
        }

        tp-checked-select {
            width: 100%;
        }

        tp-sta-profile-interest-form {
            tp-personal-skills-input {
                display: block;
                margin-bottom: 2.5rem;

                tp-icon-button.add-skill-button {
                    button {
                        margin-bottom: 0;
                    }
                }
            }
        }

        tp-projects-profile-interest-form {
            tp-personal-skills-input {
                display: block;
                margin-bottom: 1.25rem;

                tp-icon-button.add-skill-button {
                    button {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .form-spacious-containters {
            .radio-group-vertical {
                margin: 0.875rem 0 0 0;
            }
            .grid-layout_field {
                padding: 1.25rem 0;
            }
            .mat-form-field-container {
                mat-label {
                    font-size: 1rem;
                }
                mat-label:not(.mat-form-field mat-label) {
                    line-height: 1.25rem;
                    padding-bottom: 5px;
                }
                .small-label {
                    font-size: 0.985rem;
                }
            }
        }

        .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
            background-color: map-get($primary, 500);
        }

        .owl-dt-container-control-button {
            color: map-get($primary, 500);
        }

        .custom-tp-simple-select-icon {
            top: 0.9rem !important;
        }

        tp-cloud-selector {
            .custom-tp-simple-select-icon {
                top: 0 !important;
                right: 0 !important;
            }
        }

        tp-select {
            .mat-select-arrow {
                visibility: hidden;
            }
        }

        .custom-tp-select-icon,
        .custom-tp-simple-select-icon {
            background-color: #fff;
        }

        tp-modal-alert,
        tp-modal-feedback,
        tp-modal-warning {
            display: contents;
        }

        .readonly {
            mat-form-field,
            .custom-tp-select-icon,
            .custom-tp-simple-select-icon {
                background-color: $lightGrey !important;
            }
        }

        .overflow-hidden {
            overflow: hidden;
        }

        .skills-list.additional-margin-bottom {
            margin-bottom: 0.25rem;
        }

        .text-align-center {
            text-align: center;
        }

        tp-custom-paginator {
            .mat-paginator-container {
                .mat-form-field-flex {
                    display: block;
                }
                .mat-form-field-underline {
                    display: none;
                }
                .mat-paginator-page-size-select {
                    margin: 0;

                    .mat-form-field-infix {
                        width: 40px;
                        border-top: 0;
                    }
                    .mat-form-field-wrapper {
                        padding: 0;
                    }
                    .mat-select-arrow {
                        visibility: visible;
                    }
                    .mat-form-field-flex {
                        padding: 0 0.5rem;
                    }
                }
                .mat-form-field-subscript-wrapper {
                    display: none;
                }

                .mat-button-wrapper {
                    svg {
                        border: 1px solid $foregroundColor;
                        border-radius: 50%;
                    }
                }
            }
        }

        .mat-paginator {
            font-size: 0.85rem;

            .mat-paginator-page-size {
                .mat-form-field-flex {
                    padding-top: 0;
                }
            }

            .mat-paginator-range-actions {
                .mat-paginator-navigation-next,
                .mat-paginator-navigation-previous {
                    padding: 0;
                    border: none;
                    .mat-button-wrapper {
                        position: relative;
                        top: -2px;
                    }
                }
            }
        }

        tp-entity-details-page {
            overflow-y: scroll;
            overflow-x: hidden;
            .tp-details-image-header {
                .tp-details-actions {
                    .mat-icon-button {
                        background-color: white;
                        .mat-icon {
                            color: $greyColor;
                        }
                    }
                }
                .orginizer-info + tp-icon-button {
                    .mat-icon-button {
                        position: absolute;
                        z-index: 1;
                        right: 0;

                        .mat-icon {
                            color: #fff;
                        }
                    }
                }
            }
            tp-entity-details-info-panel {
                .tp-details-content {
                    p.description {
                        margin-bottom: 0;
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $layout-breakpoint-xs) {
            tp-primary-button,
            tp-primary-icon-button,
            tp-secondary-button,
            tp-tertiary-button,
            tp-tertiary-icon-button {
                button {
                    height: 40px !important;
                    line-height: 39px !important;
                }
            }

            tp-icon-button {
                button {
                    height: 40px !important;
                    width: 40px !important;
                }
            }

            tp-secondary-button,
            tp-secondary-icon-button {
                a {
                    height: 40px !important;
                    line-height: 39px !important;
                }
            }

            .success-factor-main-content {
                flex-direction: column;
                align-items: end !important;

                .success-factor-checkbox-area {
                    justify-content: flex-start !important;
                    width: 100%;
                }

                .logo {
                    margin-top: 0.625rem;
                }
            }
        }
    }
}
@mixin theme-emitter($theme) {
    tp-theme-emitter {
        .primary {
            color: $primaryColor;
        }
        .highlight {
            color: $highlightColor;
        }
    }
}
