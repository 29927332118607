mat-card.account-tile-mentoring {
    &.account-tile-mentoring {
        .profile-campaigns-tags,
        tp-campaigns-tagger {
            margin-bottom: 20px;
            height: 48px;
            overflow: hidden;

            .tagger-icon-campaigns {
                height: 24px;
                width: 24px;
                margin-right: 5px;
            }

            button {
                position: relative;
                line-height: 20px;
                background-color: $mediumGreyColor;
                width: 20px;
                height: 20px;
                margin-left: -2px;
                font-size: 9px;
                box-shadow: none;

                span {
                    padding: 3px 0;
                    line-height: 14px;
                }
            }
        }
    }
}
