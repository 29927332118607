.list-item {
    &__tags {
        &-tag {
            display: inline-block;
            margin: 0 0.125rem;
            padding: 0px 6px;

            &:first-of-type {
                margin-left: 0;
            }

            &--matched {
                border-radius: 5px;
                padding: 0px 6px;
                background-color: $highlightColor;
                font-weight: bold;
                line-height: 1.2rem;
            }
        }

        &-middot {
            display: inline-block;

            &:not(:last-of-type)::after {
                content: '\2022';
            }
        }
    }
}
