.workshop {
    tp-header {
        display: flex;
        justify-content: stretch;
        align-items: center;
        max-width: 1024px;

        h1 {
            flex: 1 1 auto;
        }

        .new-workshop-btn {
            flex: 0 1 auto;
        }
    }
    nav,
    main {
        max-width: 1024px;
    }
}
.workshop-dialog {
    .mat-dialog-actions {
        margin-bottom: 0;
        justify-content: center;
    }
}
