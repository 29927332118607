.projects-entity-applications-modal {
    &__header {
        margin-bottom: 1.25rem;
        display: grid;
        grid-template-columns: 100%;
    }
    &__header-sub-title {
        font-size: 14px;
    }

    &__header-skills-list {
        max-width: 720px;
    }
}
