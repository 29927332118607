.sitemap-page {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        line-height: 1.6;
    }

    span {
        font-weight: bold;
        margin-top: 1rem;
        display: block;
    }

    a {
        text-decoration: underline;
    }
}
