.workshop-profile-interest-edit {
    .workshop-profile-interest-skills-list {
        &__header {
            text-transform: uppercase;
            font-size: 0.7em;
            background-color: lightgrey;
            border-top: 1px solid gray;
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }

        &__add-skill-btn,
        &__delete-skill-btn {
            i {
                font-size: 1.5em;
            }
        }

        &__add-skill-btn {
            width: 125px;

            span {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                text-transform: uppercase;
                font-weight: 500;
            }
        }

        &__skill-item {
            align-items: center;
        }
    }
    .personal-skills-container {
        margin-top: 1.25rem;
    }
}
