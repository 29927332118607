@mixin tp-box-shadow {
    box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.08);
}

@mixin tp-box-shadow-hover {
    &:hover {
        box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.18);
    }
}

@mixin tp-box-shadow-all {
    @include tp-box-shadow;
    @include tp-box-shadow-hover;
}

@mixin increase-specificity {
    &#{&} {
        @content;
    }
}
