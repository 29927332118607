.release-notes {
    &__img-container {
        display: flex;
        position: relative;
        justify-content: center;
        margin: 1rem;

        img {
            max-width: 100%;
            height: auto;
            align-self: flex-start; // required for Safari
            border-radius: 4px;
        }
    }
}
@media only screen and (max-width: $layout-breakpoint-sm) {
    .release-notes {
        &__img-container {
            display: none;
        }
    }
}
