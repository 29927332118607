.about-model-view-container {
    display: block;
    position: relative;
    max-width: 1000px;

    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 10px 25px 0px #dddddd;
    background-color: white;
    margin-bottom: 60px;

    img {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    &--content {
        display: block;
        position: relative;
        background-color: white;
        h2 {
            position: relative;
            width: 100%;
            padding: 0 16px;
            text-align: center;

            margin: 8px 0;
        }
        p {
            width: 100%;
            padding: 0 16px 16px;
            margin: 0;
        }
    }

    .model-icon {
        display: inline-block;
        margin-left: 15px;
        height: 30px;
        width: 30px;
        vertical-align: middle;
        svg {
            height: 30px;
            width: 30px;
        }
    }
}

@media only screen and (min-width: $layout-breakpoint-sm) {
    .about-model-view-container {
        &--content {
            h2 {
                position: absolute;
                display: inline-block;
                width: auto;
                top: -52px;
                left: 30px;
                margin: 0;
                padding: 15px 20px;

                background-color: white;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            p {
                padding: 22px 40px 30px;
            }
        }
    }
}
