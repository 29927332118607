$image-max-width: 100%;

.blog-article {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;

    overflow-y: auto;
    overflow-x: hidden;

    padding: 60px 70px;

    &__category {
        display: block;
        position: relative;
        width: 100%;
        color: $greyColor;

        padding-bottom: 8px;
    }

    &__title,
    &__content h1 {
        display: block;
        position: relative;

        width: 100%;
        margin: 0;
        padding-bottom: 35px;
    }

    &__image,
    &__content img {
        display: block;
        width: $image-max-width;
        height: auto;
    }

    &__content {
        display: block;
        position: relative;

        width: 100%;
        padding-bottom: 80px;
        img.wp-smiley {
            display: none;
        }

        ul {
            list-style-type: disc;

            &.av-subnav-menu {
                display: none;
            }
        }

        u {
            text-decoration: none;
        }

        p {
            a {
                color: rgba(0, 0, 0, 0.87);
                span {
                    color: rgba(0, 0, 0, 0.87);
                    cursor: default;
                }
            }
        }
        .tp-blog-crosslink a {
            text-decoration: underline;
        }
    }

    &__before-navigation-separator {
        border-top-width: 2px;
        border-top-color: #333;
        width: 100%;

        margin: 30px 0;
    }

    &__navigation {
        display: block;
        position: relative;
        width: 100%;
        height: 44px /* arrows */ + 30px /* margin-bottom */ + 60px /* line-height * 2 */;

        margin: 0 0 20px 0;

        &__navigation-category {
            display: block;
            position: relative;
            width: 100%;
            text-align: center;
            margin: 0 0 40px 0;
        }

        &__navigation-wrapper {
            position: relative;
            display: block;
            width: 100%;

            white-space: nowrap;

            &__navigation-panel {
                position: relative;
                display: inline-block;
                width: calc(100% / 2);
                vertical-align: middle;
                text-align: center;

                &__navigation-arrow {
                    position: relative;
                    display: block;
                    text-align: center;

                    padding: 10px 0;
                    border-radius: 4px;

                    cursor: pointer;
                }

                &__navigation-content {
                    display: inline-block;
                    position: relative;

                    vertical-align: middle;
                    text-align: center;

                    color: #333;

                    width: 100%;
                    padding: 10px 10px 10px 10px;
                    cursor: pointer;

                    p {
                        display: block;
                        position: relative;
                        margin-top: 15px;

                        white-space: normal;
                    }

                    &__img-container {
                        position: relative;
                        display: inline-block;

                        width: 140px;
                        height: 140px;
                        border-radius: 140px;

                        background-size: cover;
                        background-position: center;
                        box-sizing: border-box;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 600px) {
        &__image,
        &__content img {
            width: $image-max-width;
            margin-bottom: 50px;
        }

        &__before-navigation-separator {
            margin: 60px 0;
        }

        &__navigation {
            height: auto;

            &__navigation-wrapper {
                position: relative;
                padding-bottom: 44px;

                &__navigation-panel {
                    position: static;
                    width: calc((100% - 3px) / 2);
                    vertical-align: top;

                    &__navigation-content {
                        text-align: center;
                        padding: 10px 5%;
                    }

                    &__navigation-arrow {
                        position: absolute;
                        bottom: 10px;

                        &.fa-long-arrow-right {
                            left: 50%;
                            right: 0;
                        }

                        &.fa-long-arrow-left {
                            left: 0;
                            right: 50%;
                        }
                    }
                }

                &__navigation-separator {
                    position: absolute;
                    left: calc(50% - 1px);
                    top: 0;
                    bottom: 0;

                    border-left: 1px solid #ccc;
                    box-sizing: border-box;
                    width: 1px;
                }
            }
        }
    }

    @media only screen and (min-width: 1400px) {
        &__navigation {
            &__navigation-wrapper {
                padding: 0 50px;
                text-align: center;

                &__navigation-panel {
                    width: calc(((100% - 2px) / 2) - 50px);
                    height: 100%;

                    &__navigation-arrow {
                        bottom: calc(50% - 22px);
                        &--right {
                            right: 0;
                            left: calc(100% - 24px - 50px);
                        }

                        &--left {
                            left: 0;
                            right: auto;
                        }
                    }
                }
            }
        }
    }
}
