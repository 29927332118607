.card {
    &__organizer {
        display: flex;
        position: relative;

        &-image {
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            object-fit: cover;
            border: 1px solid white;
        }

        &-wrapper {
            display: block;
            position: relative;
            font-size: 0.75rem;
            line-height: 1rem;
        }

        &-label {
            text-transform: uppercase;
            font-size: 10px;
            letter-spacing: 0;
            margin: 0;
        }

        &-name {
            font-weight: bold;
            font-size: 0.875rem;
            letter-spacing: 0.17px;
            word-break: break-all;
            max-height: 38px;
            overflow: hidden;
        }

        .btn-more {
            position: absolute;
            right: calc(30% - 20px);
            top: 48px;
            width: 40px;
            height: 40px;
            line-height: 0;
            padding: 0;
            background-color: transparent;
            box-shadow: none;

            .mat-button-wrapper {
                width: 24px;
                height: 24px;
                line-height: 24px;
                font-size: 12px;
                padding: 0;
                background-color: #fff;
                color: #484e5f;
                border-radius: 50%;
            }
        }

        @media screen and (max-width: 499px) {
            @include paddingMobile();
            margin: 1.375rem 0;
        }

        @media screen and (min-width: 500px) and (max-width: 747px) {
            @include paddingDesktop();
            margin: 1.375rem 0;
        }

        @media screen and (max-width: 747px) {
            flex-direction: row;
            justify-content: flex-start;
            flex-grow: 1;
            align-items: center;

            &-image {
                width: 40px;
                height: 40px;
                margin-right: 0.5rem;
            }

            &-wrapper {
                display: block;
                position: relative;
                margin-right: 0.5rem;
                font-size: 0.75rem;
                line-height: 1rem;
            }
        }

        @media screen and (min-width: 748px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &-image {
                width: 80px;
                height: 80px;
                margin-bottom: 0.5rem;
            }

            &-wrapper {
                display: block;
                flex-direction: column;
                align-items: center;
                position: relative;
                margin-bottom: 0.5rem;
                font-size: 1rem;
                line-height: 1.2rem;
                text-align: center;
            }

            &-label {
                letter-spacing: 0.12px;
            }
        }
    }
}
