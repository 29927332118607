.projects-entity-applications-search-results__no-results-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #eee;
    height: 70px;
}

.projects-entity-applications-search-results--table-view {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    .mat-cell {
        font-size: 0.875rem;
    }

    .mat-column {
        padding: 1rem 0;

        &-identity {
            flex-basis: 260px;
            flex-grow: 1;
            flex-shrink: 0;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            white-space: pre-wrap;

            .identity-avatar {
                width: 60px;
                min-width: 60px;
                height: 60px;
                border-radius: 50%;
                background-position: center;
                margin-right: 1rem;
                object-fit: cover;
                background-size: cover;
            }

            .name-and-location {
                .name,
                .location {
                    display: block;
                    line-height: 1.1;
                    white-space: nowrap;
                }

                .name {
                    font-weight: bold;
                }
            }
        }

        &-skills {
            flex-basis: 240px;
            flex-grow: 1;
            flex-shrink: 0;
        }

        &-actions {
            flex-basis: 130px;
            flex-grow: 0;

            button + button {
                margin-left: 0.625rem;
            }
        }

        &-status {
            flex-basis: 165px;
            flex-shrink: 0;
            flex-grow: 0;
            justify-content: flex-end;

            .mat-button[disabled='true'] {
                color: inherit;

                .mat-icon {
                    color: $primaryColor;
                }
            }
        }
    }

    .table {
        background-color: #eee;
    }

    .table--no-first-last-cell-padding {
        mat-cell:first-of-type {
            padding-left: 1.25rem;
        }

        mat-cell:last-of-type {
            padding-right: 0.75rem;
        }
    }
}

.projects-entity-applications-search-results--list-view {
    .application-table {
        background-color: #eee;
        border-bottom: 1px solid whitesmoke;
        padding: 20px;

        &__row {
            display: grid;
            grid-template-columns: 120px 1fr max-content;
            column-gap: 1.1875rem;

            padding-top: 5px;
            padding-bottom: 5px;

            align-items: center;

            font-size: 0.875rem;
            word-break: break-word;
        }

        &__label-cell {
            text-transform: uppercase;
            font-size: 0.625rem;
            line-height: 0.625rem;
            font-weight: 500;
            letter-spacing: 0.4px;
            text-align: right;
        }

        &__identity-img-cell {
            justify-self: flex-end;

            .identity-avatar {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-position: center;
                object-fit: cover;
                background-size: cover;
            }
        }

        &__identity-name-and-location-cell {
            .name,
            .location {
                display: block;
                line-height: 1.1;
            }
            .name {
                font-weight: bold;
            }
        }

        &__actions {
            display: flex;
            flex-direction: column;

            button + button {
                margin-top: 5px;
            }
        }

        &__skills-cell {
            display: flex;

            p {
                margin-bottom: 0;
            }
        }

        &__status-cell {
            grid-template-columns: auto !important;
            justify-items: center;
        }
    }

    @media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
        .application-table {
            padding: 20px 10px;

            &__row {
                grid-template-columns: 75px 1fr max-content;
                column-gap: 0.9375rem;
            }

            &__status-cell button {
                width: 100%;

                &:disabled .mat-icon {
                    color: $primaryColor;
                }
            }
        }
    }
}
