.faq-page {
    display: block;
    max-width: 1000px;

    div {
        h1,
        h2 {
            margin-top: 32px;
        }

        .collapsable__trigger {
            i {
                margin-right: 16px;
            }

            &:hover {
                color: #626262;
            }
        }

        .collapsable__content {
            background-color: whitesmoke;
            padding-left: 16px;
            padding-right: 16px;
            margin: 8px 0 8px 15px;
        }

        .collapsable__trigger ~ .collapsable__trigger {
            margin-top: 24px;
        }
    }

    .collapsable__content {
        .well {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }
}
