// Sizing using em instead of rem so that we can dimension it as needed in the context of use

@mixin inlineLanguageSwitcher {
    .language-switcher {
        display: inline-block;
        position: relative;

        width: auto;
        height: auto;
        margin: 0;

        &.language-switcher__invert-colors {
            .language-switcher_menuitem {
                &.selected {
                    .language-switcher_button-switch {
                        background-color: $accentColor;
                        color: white;
                    }
                }

                &:not(.selected) {
                    .language-switcher_button-switch {
                        color: $accentColor;
                    }
                }
            }
            .language-switcher_button-switch {
                border-color: $accentColor;
            }
        }
    }

    .language-switcher_menu {
        position: relative;
        display: flex;
        width: auto;
        margin: 0;
        padding: 0.375em;
        border-radius: 0.25em;
    }

    .language-switcher_menuitem {
        display: inline-block;
        transition-duration: 0.5s;

        vertical-align: middle;
        list-style-type: none;
        &:hover {
            cursor: pointer;
        }

        &.selected {
            .language-switcher_button-switch {
                background-color: white;
            }
        }

        &:not(.selected) {
            .language-switcher_button-switch {
                color: white;
            }
        }

        & ~ .language-switcher_menuitem {
            &:before {
                content: '';
                display: inline;
                vertical-align: middle;
                margin-left: 0.25em;
                margin-right: 0.25em;
            }
        }
    }

    .language-switcher_button-switch {
        display: inline;
        vertical-align: middle;
        font-size: 1em;
        font-weight: 500;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 3px;
        padding: 2px 0.25em;
        margin: 0;

        cursor: pointer;
    }
}

@mixin dropdownLanguageSwitcher {
    .language-switcher {
    }

    .language-switcher_menu {
    }

    .language-switcher_menuitem {
        display: block;
        transition-duration: 0.5s;
        &:hover {
            cursor: pointer;
        }
    }
}

@include inlineLanguageSwitcher();
// @media only screen and (max-width: $layout-breakpoint-xs) {
//     @include dropdownLanguageSwitcher();
// }

// @media only screen and (min-width: $layout-breakpoint-xs +1px) {
// }
