$input-field-border-radius: 1.5rem;

.search-layout {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    flex-basis: 260px;
    margin-bottom: 1.25rem;

    &_input {
        width: 100%;
        margin-right: 20px;
    }
    &_button {
        flex-shrink: 0;
        padding-right: 20px;
    }
}

.global-search {
    &__search-icon {
        &--animate {
            svg {
                animation-name: animate-search-loader;
                animation-duration: 700ms;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-fill-mode: none;
                animation-timing-function: ease-in-out;
            }
        }
    }

    &__autocomplete-attachment {
        position: absolute;
        width: 1px;
        height: 1px;
        bottom: 1rem;
        left: 1.25rem;
        z-index: 99999;
    }

    &__wrapper {
        max-width: 400px;
        min-width: 280px;
        transition: max-width 600ms ease-in-out;

        &:active,
        &:focus,
        &:focus-within {
            max-width: 100%;
        }

        .mat-form-field-container {
            position: relative;

            .mat-form-field-flex {
                border-radius: 24px;
                padding-left: 1.25rem;

                &:not(.mat-form-field-disabled) {
                    background: #ffffff;
                }

                .mat-form-field-infix {
                    padding: 0.625rem 0 0.625rem 0;
                }

                .mat-form-field-outline {
                    .mat-form-field-outline-start {
                        border-radius: $input-field-border-radius;
                        min-width: 0;
                        border-style: none;
                    }

                    .mat-form-field-outline-end {
                        border-radius: $input-field-border-radius;
                        border-style: none;
                    }
                }

                &:hover {
                    .mat-form-field-outline {
                        .mat-form-field-outline-end {
                            border: 1px solid $mediumGreyColor;
                        }
                    }
                }

                .mat-form-field-prefix {
                    .mat-icon {
                        svg {
                            fill: $mediumGreyColor;
                            color: $mediumGreyColor;
                        }
                    }
                }
            }

            .mat-input-element {
                caret-color: $greyColor;
                color: $mediumGreyColor;
            }
        }
    }

    &--deactivate-hover {
        .mat-form-field-container {
            .mat-form-field-flex {
                &:hover {
                    .mat-form-field-outline {
                        .mat-form-field-outline-end {
                            border-style: none;
                        }
                    }
                }
            }
        }
    }

    &__result {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &__avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            object-fit: cover;
            border: 1px solid white;
        }

        &__name {
            margin-left: 10px;

            &--highlight {
                color: #fe9300;
                font-weight: bold;
            }
        }
    }
}

@keyframes animate-search-loader {
    from {
        fill: $mediumGreyColor;
        color: $mediumGreyColor;
    }
    to {
        fill: $primaryColor;
        color: $primaryColor;
    }
}
