.job-x__tandem-check {
    &.mat-dialog-title {
        .mat-button-base {
            position: relative;
            top: unset;
            right: unset;
            margin-bottom: 1rem;
            &:first-of-type {
                margin-left: 2rem;
            }
            &:not(:last-of-type) {
                margin-right: 1rem;
            }
        }
    }

    &--large {
        .job-x__tandem-check-grid--col {
            &:not(:first-of-type) {
                font-size: 1.5rem;
            }
        }
    }

    &-grid {
        width: 100%;
        &--row {
            display: flex;
            padding-top: 2rem;
            padding-bottom: 2rem;
            align-items: center;

            &:not(:first-of-type) {
                border-top: 1px solid $mediumGreyColor;
            }

            &:first-of-type {
                align-items: flex-start;
            }
        }

        &--col {
            flex: 0 calc(35% - 2rem);
            width: calc(35% - 2rem);
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &:first-of-type {
                flex: 0 30%;
                width: 30%;
            }

            &:not(:first-of-type) {
                justify-content: center;
            }

            &:nth-of-type(2) {
                margin-right: 2rem;
            }

            .job-x__col--flex {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
            }
        }
    }

    &__avatar {
        border-radius: 50%;
        background-position: center;
        width: 128px;
        height: 128px;
        background-size: cover;
        object-fit: cover;
    }

    &__name {
        text-align: center;
        width: 100%;
        display: inline-block;
        margin-top: 1.5rem !important;
    }

    &__profile-message {
        text-align: center;
        width: 100%;
        display: inline-block;
        font-weight: 500;
        font-size: 0.9rem;
    }
}
