.general-information-sign-up,
.general-information-edit {
    .image-picker {
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: $layout-breakpoint-700 - 1px) {
        .general-information_gender {
            display: grid;
            grid-template-columns: auto auto;
            grid-column-gap: 1.25rem;
            grid-row-gap: 1.25rem;
            padding-left: 1.25rem;
        }

        .radio-option {
            margin: 0;
        }
    }

    @media screen and (min-width: $layout-breakpoint-700) {
        .general-information_gender {
            justify-content: space-between;
            display: flex;
        }

        .radio-option ~ .radio-option {
            margin-left: 8px;
        }
    }
}
