/* roboto-100 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: local('Roboto Thin'), local('Roboto-Thin'),
        url('../../../node_modules/@tandemploy/fonts/dist/roboto/roboto-v20-latin-100.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../../../node_modules/@tandemploy/fonts/dist/roboto/roboto-v20-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'),
        url('../../../node_modules/@tandemploy/fonts/dist/roboto/roboto-v20-latin-300.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../../../node_modules/@tandemploy/fonts/dist/roboto/roboto-v20-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Medium'), local('Roboto Medium'),
        url('../../../node_modules/@tandemploy/fonts/dist/roboto/roboto-v20-latin-500.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../../../node_modules/@tandemploy/fonts/dist/roboto/roboto-v20-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'),
        url('../../../node_modules/@tandemploy/fonts/dist/roboto/roboto-v20-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../../../node_modules/@tandemploy/fonts/dist/roboto/roboto-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: local('Roboto Italic'), local('Roboto-Italic'),
        url('../../../node_modules/@tandemploy/fonts/dist/roboto/roboto-v18-latin-italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../../../node_modules/@tandemploy/fonts/dist/roboto/roboto-v18-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('../../../node_modules/@tandemploy/fonts/dist/roboto/roboto-v20-latin-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../../../node_modules/@tandemploy/fonts/dist/roboto/roboto-v20-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

$fa-font-path: '../../../node_modules/font-awesome/fonts';
@import '~font-awesome/scss/font-awesome';

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(../../../node_modules/@tandemploy/fonts/dist/material-design-icons/MaterialIcons-Regular.eot); /* For IE6-8 */
    src: local('Material Icons'), local('MaterialIcons-Regular'),
        url('../../../node_modules/@tandemploy/fonts/dist/material-design-icons/MaterialIcons-Regular.woff2')
            format('woff2'),
        url('../../../node_modules/@tandemploy/fonts/dist/material-design-icons/MaterialIcons-Regular.woff')
            format('woff'),
        url('../../../node_modules/@tandemploy/fonts/dist/material-design-icons/MaterialIcons-Regular.ttf')
            format('truetype');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}
