@import '~@angular/material/theming';

@mixin simpleTaggerTheme($theme) {
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $quickSelectColor: mat-color($foreground, secondary-text, 0.6);
    $quickSelectErrorColor: mat-color($warn, 500);

    .suggested-skills-container__mat-form-field {
        .mat-form-field-wrapper {
            background-color: #fff;
            padding-bottom: 0 !important;
            z-index: 2;
        }
        .mat-form-field-wrapper.mat-focused {
            border-radius: 5px;
            box-shadow: 0 5px 5px 1px rgba(0, 0, 0, 0.3);
        }
        .skills-container-wrapper .close-skills-container-icon {
            top: 20px;
        }
    }

    .skills-container-wrapper {
        background-color: #e7e8ea;
        padding: 20px 25px 30px 25px;
        position: relative;
        z-index: 1;
        margin-top: -10px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        &.hidden {
            display: none;
        }

        &.personal-skills {
            padding-top: 10px;
        }

        .close-skills-container-icon {
            position: absolute;
            top: 10px;
            right: 5px;
            cursor: pointer;

            & > svg {
                height: 14px;
            }
        }
    }

    .tp-techwolf-skills-container {
        h2 {
            padding-top: 10px;
            margin-top: 20px;
            border-top: 1px solid #fff;
        }
        .tags-suggestion {
            .mat-chip.mat-standard-chip:not(.tags-suggestion__hide-button) {
                background-color: #fff;
                border-color: #8b92a7;
                color: #484e5f;
            }
        }
    }

    .tags-suggestion__select-all-button,
    .tags-suggestion__hide-button {
        .mat-chip.mat-standard-chip {
            background-color: transparent;
            border-color: #8b92a7;
            color: #484e5f;
            .mat-icon {
                &.mat-chip-trailing-icon {
                    margin-left: 0;
                    color: #8e93a4 !important;
                    width: auto;
                }
            }
        }
    }

    .tags-suggestion__select-all-button {
        .mat-chip.mat-standard-chip {
            flex-direction: row-reverse;
            .mat-icon {
                &.mat-chip-trailing-icon {
                    margin-left: 0 !important;
                    margin-right: 5px !important;
                    height: 12px !important;
                }
            }
        }
    }

    .mat-chip.mat-standard-chip {
        border-radius: 15px;
        border: 1px solid #8e93a4;
        background-color: #8e93a4;
        height: fit-content;
        color: #fff;
        padding: 3px 15px;
        min-height: 30px;
        margin: 5px;

        .mat-icon {
            color: #fff !important;
        }

        &.mat-chip-with-trailing-icon {
            padding: 3px 15px;
        }
    }

    .mat-form-field-type-tp-simple-tagger {
        &.ng-invalid:not(.mat-focused) {
            color: mat-color($warn, 500);
        }

        .simple-tagger {
            .suggest-progress-bar {
                display: none;
                bottom: -3px;
            }
            &.suggest-requesting {
                .suggest-progress-bar {
                    display: block;
                }
            }
        }
    }

    .tags-suggestion {
        margin-top: 5px;

        h2 {
            font-size: 14px;
            margin-bottom: 5px;
        }

        &__tags-list--empty {
            margin: 0;
        }

        &__tags_container {
            display: flex;
            tp-chip,
            .mat-chip {
                .mat-icon {
                    display: none;
                }
                &:hover {
                    .mat-icon {
                        display: block;
                    }
                }
            }
        }

        &__skill {
            position: relative;
            z-index: 0;
            .mat-icon {
                display: none !important;
            }
            &:hover,
            &.opened {
                z-index: 1;
                .mat-icon {
                    display: block !important;
                }
            }
            .tags-suggestion__skill-hide-modal {
                background-color: #fff;
                box-shadow: 0 0 6px 5px rgb(0 0 0 / 10%);
                color: #000;
                position: absolute;
                top: 105%;
                padding: 16px;
                width: 260px;
                right: -111px;
                border-radius: 8px;
                display: none;

                &.opened {
                    display: block;
                }

                &__arrow {
                    width: 0;
                    height: 0;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 8px solid #fff;
                    position: absolute;
                    top: -7px;
                    right: 0;
                    left: 0;
                    margin: auto;
                    z-index: 2;
                }

                &__hide-action,
                &__cancel-action {
                    cursor: pointer;
                    display: flex;
                    .mat-icon {
                        width: 24px !important;
                        height: 24px !important;
                        svg {
                            height: 24px !important;
                            margin-top: -1px !important;
                            path {
                                fill: #484e5f;
                            }
                        }
                    }
                }

                &__hide-action {
                    padding-bottom: 16px;
                    border-bottom: 1px solid #e1e1e1;
                    .mat-icon {
                        margin-right: 16px;
                    }
                }

                &__cancel-action {
                    padding-top: 16px;
                    justify-content: center;
                    .mat-icon {
                        margin-right: 6px;
                    }
                }
            }
        }
    }
    .mat-chip-list {
        .mat-chip {
            transform: translateY(0);
        }
    }
}
