.welcome-sign-up-layout {
    position: relative;
    display: grid;

    overflow-y: auto;
    overflow-x: hidden;

    width: 100vw;
    height: 100vh;
}

.welcome-sign-up-layout__menu {
    grid-column: 3;
    color: white;
    margin: 1rem;
    grid-row: 1;
    z-index: 1;
    text-align: right;

    button {
        background-color: rgba(0, 0, 0, 0.3);
    }
}

.welcome-sign-up-layout__main {
    display: grid;
    grid-template-rows: repeat(4, max-content);
    padding: 1rem 2.2rem;
    row-gap: 1rem;
    z-index: 1;
}

.welcome-sign-up-layout__aside {
    position: relative;
}

.welcome-sign-up-layout__text-gradient {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 500px;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 20%, rgba(255, 255, 255, 0) 100%);
}

.welcome-sign-up__teaser-container {
    grid-row: 3;
    z-index: 1;
    text-shadow: 1px 1px 6px rgb(0 0 0 / 30%);
}

@mixin setWelcomeSignUpLayoutSpaces(
    $mainMarginLeftRight,
    $mainPaddingLeftRight,
    $mainPaddingTopDown,
    $teaserPaddingLeftRight,
    $teaserPaddingTopDown
) {
    .welcome-sign-up-layout__main {
        margin: 0 $mainMarginLeftRight;
        padding: $mainPaddingTopDown $mainPaddingLeftRight;
    }

    .welcome-sign-up__teaser-container {
        padding: $teaserPaddingTopDown $teaserPaddingLeftRight;
        margin: 0 $mainMarginLeftRight;
    }
}

@media only screen and (max-width: #{$layout-breakpoint-xxs - 1}) {
    @include setWelcomeSignUpLayoutSpaces(0, 0.625rem, 1.5rem, 0.625rem, 3rem);
}

@media only screen and (min-width: $layout-breakpoint-xxs) and (max-width: #{$layout-breakpoint-500 - 1}) {
    .welcome-sign-up-layout__submit-button {
        width: 100%;
    }

    @include setWelcomeSignUpLayoutSpaces(1.25rem, 3.75rem, 1.5rem, 3.75rem, 3.75rem);
}

@media only screen and (min-width: $layout-breakpoint-500) and (max-width: #{$layout-breakpoint-xs - 1}) {
    .welcome-sign-up-layout__submit-button {
        width: 100%;
    }

    @include setWelcomeSignUpLayoutSpaces(4.375rem, 3.75rem, 1.5rem, 3.75rem, 3.75rem);
}

@media only screen and (min-width: $layout-breakpoint-xs) and (max-width: #{$layout-breakpoint-sm - 1}) {
    @include setWelcomeSignUpLayoutSpaces(3.75rem, 3.75rem, 1.5rem, 3.75rem, 3.75rem);
}

@media only screen and (max-width: #{$layout-breakpoint-sm - 1}) {
    .welcome-sign-up-layout {
        grid-template-rows: 3.75rem max-content max-content;
        background-position: center;
        background-image: url(/api/v2/tenant-settings/assets/background.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }

    .welcome-sign-up-layout__menu {
        grid-column: 2;
        grid-row: 1;
    }

    .welcome-sign-up-layout__main {
        grid-column: 1 / span 2;
        grid-row: 2 / span 2;
        background-color: white;
    }

    .welcome-sign-up-layout__aside {
        grid-row: 4;
        grid-column: 1 / span 2;
        display: grid;
        color: white;
    }
}

@media only screen and (min-width: $layout-breakpoint-sm) {
    .welcome-sign-up-layout {
        grid-template-columns: 340px auto auto;
    }

    .welcome-sign-up-layout__aside {
        background-position: center;
        background-image: url(/api/v2/tenant-settings/assets/background.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }

    .welcome-sign-up-layout__main {
        grid-row: 1 / span 2;
        width: 100%;
        padding: 1rem 2.2rem;
        row-gap: 1rem;
    }

    .welcome-sign-up-layout__aside {
        grid-column: 2 / span 2;
        grid-row: 1 / span 2;

        display: grid;
        grid-template-rows: 3.5rem 1fr auto 3.5rem;
        color: white;
    }

    .welcome-sign-up__teaser-container {
        grid-column: 2 / span 2;
        max-width: 1200px;
    }

    @include setWelcomeSignUpLayoutSpaces(0, 2.2rem, 2.5rem, 5rem, 0);
}
