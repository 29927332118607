.projects-entity-form {
    .image-picker {
        display: flex;
        justify-content: center;
    }

    @media only screen and (min-width: $layout-breakpoint-700) and (max-width: #{$layout-breakpoint-sm - 1}) {
        .projects-entity-form__title {
            grid-column: span 6;
        }
    }

    .mat-checkbox-layout {
        white-space: break-spaces;
    }
}

.details__content-1 {
    .mat-body-2 {
        margin-bottom: 2.5rem;
    }
}
