@import '../../../breakpoints';

mat-card.account-tile-layout {
    width: 100%;
    background: #fff;
    display: inline-block;
    border-radius: 10px;
    text-align: center;
    padding: 24px 15px;
    cursor: pointer;

    mat-card-content {
        margin-bottom: 0;
    }

    mat-card-actions {
        padding: 0;

        .mat-icon-button {
            margin-top: 10px;
            margin-bottom: -4px;

            mat-icon {
                height: 22px;
                width: auto;

                svg {
                    height: inherit;
                    max-width: inherit;
                }
            }
        }
    }

    .account-tile__image-container {
        position: relative;
        display: inline-block;
        height: 130px;
        width: 130px;
        margin: 10px 0 0;

        .mat-card-image {
            background-color: #f1f1f1;
            display: block;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background-position: center;
            overflow: hidden;
            background-size: cover;
            margin: 0;
        }

        .account-tile__get-to-know {
            position: absolute;
            bottom: -10px;
            right: -10px;

            tp-icon-button {
                button {
                    background-color: #fff;
                }
            }
        }
    }

    .profile-name {
        font-weight: 600;
        margin-top: 20px;
        font-size: 1.1rem;
        height: 3.75rem;
        line-height: 1.3rem;
        margin-bottom: 0;
        line-break: anywhere;
        overflow: hidden;
    }

    .profile-title-locations {
        font-weight: 400;
        font-size: 0.8rem;
        height: 1.4rem;
        margin-bottom: 0.7rem;
        white-space: nowrap;

        mat-icon {
            height: 1rem;
            width: 1rem;
            display: inline-flex;

            svg {
                height: 0.8rem;
            }
        }

        .list-item__tags {
            max-width: calc(100% - 45px);
            line-height: 0.9em;

            .list-item__tags-tag {
                height: 1.3em;
                line-height: 1.3em;
                max-width: calc(50% - 2.1em);
                position: relative;
                top: 3px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .card__tags-tag--matched {
            }
        }

        .btn-more {
            position: relative;
            top: -3px;
            line-height: 20px;
            width: 22px;
            height: 22px;
            font-size: 9px;
            box-shadow: none;

            .mat-button-wrapper {
                padding: 3px 0;
                line-height: 14px;
            }
        }
    }

    .profile-tags {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        margin-bottom: 0;
        height: 7.75rem;

        .tagger__chip {
            max-width: 100%;
            line-height: 1.2em;
            .tagger__tag {
                display: inline-block;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-middot {
                display: inline-block;
                margin-right: 0.5rem;
                font-size: 14px;

                &::after {
                    content: '\2022';
                }
            }
        }
    }

    .profile-tags,
    tp-tagger {
        margin-bottom: 20px;
        height: 4.5rem;
    }

    .profile-campaigns-tags,
    tp-campaigns-tagger {
        margin-bottom: 20px;
        height: 48px;
        overflow: hidden;

        .list-item-campaigns-icon {
            height: 24px;
            width: 24px;
            margin-right: 5px;
        }

        button.mat-mini-fab {
            position: relative;
            line-height: 20px;
            background-color: $mediumGreyColor;
            width: 20px;
            height: 20px;
            margin-left: -2px;
            font-size: 9px;
            box-shadow: none;

            span.mat-button-wrapper {
                padding: 3px 0;
                line-height: 14px;
            }
        }
    }
}

.account-tiles-container {
    display: grid;
    grid-gap: 0.625rem;
    margin-top: 1.5rem;

    grid-template-columns: auto;
    max-width: 1024px;

    @include gt-xs-screen {
        grid-template-columns: repeat(auto-fill, 275px);
    }

    @include gt-sm-screen {
        grid-template-columns: repeat(auto-fill, 248px);
    }
}
