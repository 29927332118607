.single-skill-tagger-input {
    .mat-form-field {
        width: 100%;

        input {
            width: 100%;
            flex: 1 0;
        }

        .mat-chip {
            line-height: 100%;
            margin-top: -2px;
            margin-bottom: -2px;
        }
    }
}
