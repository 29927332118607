.about-saas-page {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 900px;

    h1,
    h2 {
        text-align: center;
    }

    .info-text-img-container {
        display: flex;
        position: relative;
        justify-content: center;
        margin: 3rem;

        img {
            max-width: 100%;
            height: auto;
            align-self: flex-start; // required for Safari
            border-radius: 4px;
        }
    }

    .call-to-action {
        margin: 2rem 0 5rem;
        flex-grow: 0;
    }
}
