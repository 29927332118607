.dashboard-invitation-tile {
    .tile-layout_content {
        background-color: #959a2f;

        grid-template-columns: 100%;
        grid-template-rows: 40% 60%;
        align-items: center;
        justify-items: center;
        position: relative;
        cursor: pointer;

        .tile-layout_content-headline {
            color: #fff;
            font-size: 24px;
            font-weight: normal;
            align-self: flex-end;
            margin-bottom: 20px;
            text-align: center;
        }
    }

    .tile-layout_content-icon {
        opacity: 1;
        justify-self: center;
        grid-row: 2 / 2;
        grid-column: 1 / 1;
        align-self: start;
        color: #fff;
    }

    .invitation-tile_slide-in {
        position: absolute;
        top: 100%;
        width: 80%;
        grid-row: 2 / 2;
        grid-column: 1 / 1;
        opacity: 0;
        text-align: center;
        height: 100%;
        margin: 0;
        padding: 1em 0;

        &::before {
            content: '';
            margin-bottom: 1em;
            position: absolute;
            display: block;
            margin-left: 15%;
            width: 70%;
            top: 10px;
            border-top: solid 1px #fff;
        }
    }

    @media only screen and (min-width: $layout-breakpoint-xs) {
        .tile-layout_content-icon,
        .invitation-tile_slide-in {
            transition: ease-in-out 0.3s;
        }

        &:hover,
        &:focus {
            .invitation-tile_slide-in {
                opacity: 1;
                transform: translateY(-100%);
            }

            .tile-layout_content-icon {
                opacity: 0;
            }
        }
    }
}
