$conversationInfoHeight: 4.6875rem;
$header-horizontal-padding: 3.125rem;
$groupConversationHeight: 8.2rem;

.direct-messages {
    .main-layout_main {
        height: calc(100% - 8.9375rem);
    }

    .main-layout_main-container {
        padding: 0;
    }

    &__header {
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem;

        background: #f8f8f9;

        h1 {
            border-top: 0.0625rem solid #fff;
            padding-left: $header-horizontal-padding;
            padding-right: $header-horizontal-padding;
            padding-top: 0.9375rem;
            margin: 0;
        }

        .global-search {
            &__wrapper {
                padding-left: $header-horizontal-padding;
                padding-right: $header-horizontal-padding;
            }
        }
    }

    &__mat-menu {
        &__content {
            display: flex;
            flex-direction: column;
            padding: 1.25rem;
            font-size: 0.875rem;
            line-height: 1.125rem;
        }

        &__item {
            padding: 1.25rem;

            &:not(:last-of-type) {
                border-bottom: 0.0625rem solid $lightGrey;
            }

            &:first-of-type {
                padding-top: 0;
            }

            &:last-of-type {
                padding-bottom: 0;
            }

            mat-icon ~ tp-secondary-button {
                margin-left: 1.5rem;
            }
        }
    }

    &__contact-search {
        width: 100%;
    }

    &__conversations-loading-message,
    &__no-contacts-of-type-message {
        padding-left: 0.9375rem;
        color: $mediumGreyColor;
    }

    &__no-contacts-of-type-message {
        padding-top: 0.125rem;
        font-size: 0.875rem;
    }
}

.manage-participants {
    &__dialog-content {
        min-height: 505px;
    }

    h1:first-of-type {
        padding-bottom: 0;
    }

    &__helper-text {
        font-size: 0.875rem;
        margin-bottom: 1.875rem;
    }

    &__participant-list {
        display: flex;
        flex-direction: column;
        min-width: 34.375rem;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(65vh - 7.4375rem);
        min-height: 24.125rem;

        &--no-results {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                flex: 1;
                width: 27.375rem;
                height: auto;
                margin-top: 1rem;
            }
        }

        &__item {
            padding: 1.25rem 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 0.0625rem solid $lightGrey;

            &:last-of-type {
                border-bottom: none;
                padding-bottom: 0;
            }

            &__general-data {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-right: 7.8125rem;
                cursor: pointer;
            }

            &__avatar {
                width: 2.5rem;
                height: 2.5rem;
                background-size: cover;
                object-fit: cover;
                border-radius: 50%;
                background-position: center;
                margin-right: 0.9375rem;
            }

            &__name {
                font-size: 0.875rem;
                line-height: 1rem;
                font-weight: 500;
            }

            tp-primary-button {
                margin-right: 0.5rem;
            }
        }
    }
}

.message-collection {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    &__header {
        padding-top: 2.5rem;
        padding-right: 1.25rem;
        padding-left: 1.5rem;
        padding-bottom: 1.25rem;
        display: flex;
        align-items: center;

        tp-icon-button:last-of-type {
            margin-left: auto;
        }
    }

    &__wrapper {
        overflow-x: hidden;
        overflow-y: auto;
        padding: 2.5rem 6.875rem 0 5.625rem;
        display: flex;
        flex-direction: column;

        &::-webkit-scrollbar-button {
            display: none;
            width: 0px;
            height: 0px;
        }

        &::-webkit-scrollbar,
        &::-webkit-scrollbar-track {
            background-color: #fff;
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            width: 5px;
            height: 60px;
            -webkit-border-radius: 7px;
            border-radius: 7px;
            background-color: $mediumGreyColor;
            &:hover {
                background-color: $mediumGreyColor;
            }
        }

        &--group-type {
            padding-left: 2.5rem;
            padding-right: 3.75rem;
        }
    }

    &__note {
        font-size: 1.75rem;
        color: rgba(0, 0, 0, 0.5);
        margin-bottom: auto;
        margin-top: auto;
        align-self: center;
        word-break: break-all;
        position: relative;

        &--top {
            margin-top: 5rem;
        }

        &__placeholder {
            margin-right: 7.8125rem;
        }

        section {
            position: absolute;
            top: 7.1875rem;
            left: 50%;
            transform: translate(-50%, 0);
            text-align: center;
            max-width: 11.875rem;
            word-break: break-word;
            color: $foregroundColor;
        }

        &__headline {
            font-size: 1.125rem;
            font-weight: bold;
            margin-bottom: 0.625rem;
        }

        &__content {
            font-size: 0.875rem;
            font-weight: normal;
        }
    }
}

.avatar-bar {
    display: flex;
    &__avatar {
        width: 30px;
        height: 30px;
        background-size: cover;
        object-fit: cover;
        border-radius: 50%;
        background-position: center;
        margin-right: 20px;
        position: relative;

        &:last-of-type {
            margin-right: 0;
        }

        &:first-of-type {
            &:after {
                content: '';
                background-color: $highlightColor;
                width: 40px;
                height: 40px;
                z-index: -1;
                position: absolute;
                left: -5px;
                top: -5px;
                border-radius: 50%;
            }
        }
    }

    &__badge {
        text-align: center;
        display: inline-block;
        border-radius: 50%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background-color: $foregroundColor;
        color: #fff;
        width: 23px;
        height: 23px;
        line-height: 23px;
        font-size: 12px;
        font-weight: bold;
        align-self: center;
        cursor: pointer;
    }

    .mat-badge {
        align-self: center;

        .mat-badge-content {
            background-color: $foregroundColor;
        }
    }
}

.direct-messages__layout-wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;

    .direct-messages__conversation-list {
        max-width: 16.875rem;
        min-width: 16.875rem;
        overflow-x: hidden;
        height: 100%;
        border: 0.125rem solid #f2f2f2;
        display: grid;

        .direct-messages__conversation-collection {
            flex: 1;
            overflow: hidden;

            &--full-height {
                height: 100%;
            }

            &--groups {
                min-height: 0;
            }

            &__scrollable-container {
                overflow-y: auto;
                overflow-x: hidden;
                height: calc(100% - 1.875rem);

                &::-webkit-scrollbar-button {
                    display: none;
                    width: 0px;
                    height: 0px;
                }

                &::-webkit-scrollbar,
                &::-webkit-scrollbar-track {
                    background-color: #fff;
                    width: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    width: 5px;
                    height: 60px;
                    -webkit-border-radius: 7px;
                    border-radius: 7px;
                    background-color: $mediumGreyColor;
                    &:hover {
                        background-color: $mediumGreyColor;
                    }
                }
            }

            &__header {
                background: #f2f2f2;
                height: 1.875rem;
                font-size: 0.75rem;
                font-weight: 500;
                text-transform: uppercase;
                padding-left: 0.9375rem;
                display: flex;
                align-items: center;
            }

            .direct-messages__conversation {
                display: flex;
                align-items: flex-start;
                padding-top: 0.9375rem;
                padding-bottom: 0.625rem;
                border-top: 0.125rem solid #f2f2f2;
                padding-left: 1.25rem;
                position: relative;

                &:hover {
                    cursor: pointer;
                    background: #fdefd8;
                    box-shadow: 0 0.625rem 0.9375rem 0 rgba(0, 0, 0, 0.08);
                    border-bottom: none;
                }

                &:first-of-type {
                    border-top: none;
                }

                &:last-of-type {
                    border-bottom: 0.125rem solid #f2f2f2;
                }

                &__unread-messages-indicator {
                    position: absolute;
                    width: 0.625rem;
                    height: 0.625rem;
                    background-color: $primaryColor;
                    border-radius: 50%;
                    right: 0.625rem;
                    top: 0.625rem;
                }

                &.direct-messages__conversation--selected {
                    background: #fdefd8;
                    box-shadow: 0 0.625rem 0.9375rem 0 rgba(0, 0, 0, 0.08);
                    border-bottom: none;

                    &:hover {
                        cursor: default;
                    }
                }

                .direct-messages__conversation__avatar {
                    border-radius: 50%;
                    background-position: center;
                    height: 2.5rem;
                    min-height: 2.5rem;
                    max-height: 2.5rem;
                    width: 2.5rem;
                    min-width: 2.5rem;
                    max-width: 2.5rem;
                    background-size: cover;
                    object-fit: cover;
                }

                .direct-messages__conversation__contact-info {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding-left: 0.625rem;
                    padding-right: 0.625rem;
                    padding-top: 0.9375rem;
                    align-items: flex-start;
                    line-height: 1rem;

                    .direct-messages__conversation__contact-info__details {
                        display: flex;
                        justify-content: space-between;
                        font-size: 0.875rem;

                        .direct-messages__conversation__contact-info__details__name {
                            flex: 1;
                            font-weight: bold;
                            margin-bottom: 0.9375rem;
                        }
                    }

                    .direct-messages__conversation__contact__last-message {
                        font-size: 0.75rem;
                        margin-bottom: 0.9375rem;

                        &.direct-messages__conversation__contact__last-message--unread {
                            font-weight: bold;
                        }

                        &.direct-messages__conversation__contact__last-message--from-deleted {
                            filter: blur(0.19rem);
                        }
                    }

                    .direct-messages__conversation__origin {
                        font-size: 0.625rem;
                        text-align: right;
                        font-style: italic;
                        align-self: flex-end;
                        color: $foregroundColor;

                        > span:first-of-type {
                            color: #484e5f;
                        }
                    }
                }
            }
        }
    }

    .direct-messages__conversation-frame {
        border: 0.125rem solid #f2f2f2;
        border-left: none;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;

        .direct-messages__conversation-info {
            border-bottom: 0.0625rem solid black;
            flex: 0 $conversationInfoHeight;
            min-height: $conversationInfoHeight;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0;
            margin: 0;

            &.direct-messages--no-conversation-selected {
                border: none;
            }

            & > div {
                height: 100%;
                display: flex;
                flex: 1 50%;
                align-items: center;
                justify-content: center;

                &:first-of-type {
                    border-right: 0.0625rem solid black;
                }
            }

            .direct-messages__conversation-info__participants {
                flex-direction: column;
            }
        }

        .message-collection {
            .direct-messages__message {
                max-width: 75%;

                .direct-messages__message__wrapper {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 1.125rem;

                    .direct-messages__message__content-wrapper {
                        display: flex;
                        flex-direction: row;
                    }

                    .direct-messages__message__avatar {
                        margin-top: 1.25rem;
                        border-radius: 50%;
                        background-position: center;
                        height: 2.5rem;
                        min-height: 2.5rem;
                        max-height: 2.5rem;
                        width: 2.5rem;
                        min-width: 2.5rem;
                        max-width: 2.5rem;
                        background-size: cover;
                        object-fit: cover;
                    }

                    .direct-messages__message__sender-name {
                        font-size: 0.625rem;
                        padding-top: 1.75rem;
                        margin-right: 0.3125rem;
                        color: $mediumGreyColor;
                    }

                    .direct-messages__message-content {
                        padding: 1.25rem 1.5625rem;
                        position: relative;
                        border-radius: 0.625rem;
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .deleted-message {
                            filter: blur(0.19rem);
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            top: 2.5rem;
                            width: 0;
                            height: 0;
                            border: 0.625rem solid transparent;
                            margin-top: -0.625rem;
                        }

                        p {
                            margin-bottom: 0;
                        }
                    }

                    .direct-messages__message__timestamp {
                        margin-top: 0.375rem;
                        font-size: 0.625rem;
                        color: $foregroundColor;
                        line-height: 1rem;
                        font-style: italic;
                    }
                    .direct-messages__message__message-icon {
                        margin-right: 15px;
                        margin-left: -15px;
                        height: 4rem;
                        width: 4rem;
                        align-self: flex-start;
                        svg {
                            height: 100%;
                        }
                    }
                    .direct-messages__message__message-title {
                        font-size: 80%;
                        color: $foregroundColor;
                        clear: both;
                        display: inline-block;
                        width: 100%;
                        position: relative;
                        top: -0.6rem;
                    }
                }

                &.direct-messages__message--direction-sent {
                    align-self: flex-end;

                    .direct-messages__message__avatar {
                        order: 1;
                    }

                    .direct-messages__message__sender-name {
                        order: 2;
                        margin-right: 0;
                        margin-left: 0.3125rem;
                    }

                    .direct-messages__message-content {
                        background: #f2f2f2;
                        margin-right: 1.375rem;

                        &:after {
                            right: 0;
                            border-right: 0;
                            border-left-color: #f2f2f2;
                            margin-right: -0.625rem;
                        }
                    }

                    .direct-messages__message__timestamp {
                        align-self: flex-end;
                        margin-right: 3.875rem;
                    }
                }

                &.direct-messages__message--direction-received {
                    align-self: flex-start;

                    .direct-messages__message__avatar {
                        cursor: pointer;
                        order: 0;
                    }

                    .direct-messages__message-content {
                        background: #fdefd8;
                        margin-left: 1.375rem;

                        &:after {
                            left: 0;
                            border-left: 0;
                            border-right-color: #fdefd8;
                            margin-left: -0.625rem;
                        }
                    }

                    .direct-messages__message__timestamp {
                        align-self: flex-start;
                        margin-left: 3.875rem;
                    }
                }
            }
        }

        .message-sender {
            display: flex;
            flex-direction: column;
            padding: 1.25rem;
            background: #f8f8f9;
            min-height: 8.4375rem;

            &__send-indicator {
                font-size: 0.75rem;
                align-self: flex-start;
                justify-self: center;
                opacity: 0;
            }

            &__send-button {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: -0.6875rem;
                top: 0;
                height: 100%;
                background: #484e5f;
                color: #ffffff;
                width: 3em;
                border-radius: 0 10px 10px 0;

                &.disabled {
                    background: #f2f2f2;
                    .mat-icon-button:disabled {
                        border: 0;
                        svg path {
                            color: $foregroundColor;
                        }
                    }
                }
            }

            &--no-write-access,
            &--loading {
                align-self: center;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 0.875rem;
            }

            .mat-form-field-container {
                padding-bottom: 0;

                .mat-form-field {
                    &:not(.mat-form-field-disabled) {
                        background: #ffffff;
                    }
                }

                .mat-form-field-infix {
                    padding: 0 2.3rem 0 0;
                }

                .mat-form-field-wrapper {
                    padding-bottom: 0;
                }
            }

            &__editor-bar {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .fr-element p {
                margin: 0;
            }
            .fr-box.fr-basic.fr-bottom {
                .fr-wrapper {
                    border-top-right-radius: 0;
                }
                .fr-toolbar {
                    border-bottom-right-radius: 0;
                }
            }
        }
    }
}
@include lt-xs-screen {
    .direct-messages {
        .main-layout_main {
            height: 100vh;
        }
    }

    .direct-messages--mobile-view {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .direct-messages__header {
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        h1 {
            padding: 0 1rem;
        }
    }

    .direct-messages__header h1,
    .message-collection__header h1 {
        font-size: 1rem;
        margin: 0;
    }
    .message-collection__header {
        .mat-icon-button.mat-accent {
            border: 0;
            &:hover {
                border: 0;
            }
        }
    }

    .direct-messages__layout-wrapper {
        overflow: auto;

        .direct-messages__conversation-list {
            max-width: 100%;
            min-width: auto;
            flex: 1;
        }

        .direct-messages__conversation-frame {
            .message-collection {
                &__header {
                    padding: 1rem 1rem 1rem 1.5rem;
                    background-color: #f2f2f2;

                    .mat-stroked-button {
                        background-color: white;
                        padding: 0;
                    }
                }
                &__wrapper {
                    padding: 1rem 1rem 0 1rem;
                }

                .direct-messages__message .direct-messages__message__wrapper .direct-messages__message__message-icon {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
        }
    }

    .direct-messages__layout-wrapper .direct-messages__conversation-frame .message-sender__send-button {
        right: -0.3rem;
    }
}
@include gt-sm-screen {
    .direct-messages__layout-wrapper {
        .direct-messages__conversation-frame {
            .message-collection {
                .direct-messages__message .direct-messages__message__wrapper .direct-messages__message__message-icon {
                    width: 6.2rem;
                    height: 6.2rem;
                }
            }
        }
    }
}
