.grid-layout {
    position: relative;
    display: grid;
    width: 100%;

    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: auto;
    column-gap: 1.25rem;

    & > .grid-layout_field {
        position: relative;
        display: inline-block;
        padding: 0.875rem 0;

        &--big-padding {
            padding-top: 1.625rem;
        }

        &:last-child {
            padding-bottom: 0;
        }

        &-small-padding {
            padding: 0.5rem 0;
            .checkbox-wrapper {
                width: 100%;
                height: 100%;
                min-height: 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                &.highlighted {
                    background-color: #ffe9cc;
                }
            }
            tp-checkbox-item {
                width: fit-content;
            }
        }

        &.flex-center {
            display: flex;
            align-items: center;
            text-align: center;
            padding: 0;
        }

        &.padding-center {
            padding-top: 1.325rem;
        }

        button {
            display: inline-block;
            vertical-align: baseline;
        }
    }

    .grid-layout_inline-button-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        padding-bottom: 36px;
    }

    .grid-layout_inline-checkbox-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        padding: 1.25rem 0;
    }

    &.grid-layout--footer {
        .mat-form-field-container {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}

.grid-layout > .grid-layout_span-1 {
    grid-column: span 1;
}

.grid-layout > .grid-layout_span-2 {
    grid-column: span 2;
}

.grid-layout > .grid-layout_span-3 {
    grid-column: span 3;
}
.grid-layout > .grid-layout_span-4 {
    grid-column: span 4;
}

.grid-layout > .grid-layout_span-5 {
    grid-column: span 5;
}

.grid-layout > .grid-layout_span-6 {
    grid-column: span 6;
}
.grid-layout > .grid-layout_span-7 {
    grid-column: span 7;
}

.grid-layout > .grid-layout_span-8 {
    grid-column: span 8;
}

.grid-layout > .grid-layout_span-9 {
    grid-column: span 9;
}
.grid-layout > .grid-layout_span-10 {
    grid-column: span 10;
}

.grid-layout > .grid-layout_span-11 {
    grid-column: span 11;
}

.grid-layout > .grid-layout_span-12 {
    grid-column: span 12;
}

@media (max-width: 1280px) and (min-width: $layout-breakpoint-sm) {
    .grid-layout {
        .grid-layout_field {
            tp-primary-icon-button {
                button {
                    padding-right: 1rem !important;
                }
            }
        }
    }
}

@media screen and (max-width: $layout-breakpoint-700 - 1px) {
    .grid-layout > .grid-layout_span-1,
    .grid-layout > .grid-layout_span-2,
    .grid-layout > .grid-layout_span-3,
    .grid-layout > .grid-layout_span-4,
    .grid-layout > .grid-layout_span-5,
    .grid-layout > .grid-layout_span-6,
    .grid-layout > .grid-layout_span-7,
    .grid-layout > .grid-layout_span-8,
    .grid-layout > .grid-layout_span-9,
    .grid-layout > .grid-layout_span-10,
    .grid-layout > .grid-layout_span-11,
    .grid-layout > .grid-layout_span-12 {
        &:not(.stricked-grid) {
            grid-column: span 12;
        }
    }
}

@media screen and (min-width: $layout-breakpoint-700) and (max-width: $layout-breakpoint-sm - 1px) {
    .grid-layout > .grid-layout_span-1,
    .grid-layout > .grid-layout_span-2,
    .grid-layout > .grid-layout_span-3,
    .grid-layout > .grid-layout_span-4,
    .grid-layout > .grid-layout_span-5,
    .grid-layout > .grid-layout_span-6 {
        &:not(.stricked-grid) {
            grid-column: span 6;
        }
    }

    .grid-layout > .grid-layout_span-7,
    .grid-layout > .grid-layout_span-8,
    .grid-layout > .grid-layout_span-9,
    .grid-layout > .grid-layout_span-10,
    .grid-layout > .grid-layout_span-11,
    .grid-layout > .grid-layout_span-12 {
        &:not(.stricked-grid) {
            grid-column: span 12;
        }
    }
}
