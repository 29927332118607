.invitation {
    .invitation__content-title {
        margin-bottom: 0;
        width: 80%;
    }

    .invitation__content-icon {
        position: absolute;
        right: 13rem;
        top: 0;
        font-size: 6rem;
        color: lightgrey;
    }

    .invitation__content-footer nav {
        justify-content: center;
    }

    .invitation__send-btn {
        &.mat-flat-button mat-icon {
            font-size: 1rem;
            line-height: 1.2rem;
        }
    }

    @media screen and (max-width: $layout-breakpoint-sm - 1px) {
        .invitation__content-title {
            width: 100%;
        }

        .invitation__content-icon {
            display: none;
        }
    }
}
