.motivation-select {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 400px;
    padding: 15px 15px 0 15px;

    .motivation-select__option {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        user-select: none;

        border-radius: 8px;
        box-shadow: 0 1px 6px 3px rgb(0 0 0 / 8%);
        padding: 2rem 5rem;
        transition: background-color 0.1s ease-in;
        font-size: 1.1rem;
        color: #333;

        cursor: pointer;

        &:not(:first-child) {
            margin-top: 1rem;
        }

        &.selected,
        &:hover {
            transition: background-color 0.1s ease-out;
            background-color: $highlightColor;
        }
    }

    .motivation-select__errors {
        position: relative;
        margin-top: 8px;
        width: 500px;
        max-width: 100%;
        display: none;
        flex-shrink: 0;
        flex-grow: 0;
        color: $errorColor;
        height: 1.5rem;
    }

    .motivation-select__label-wrapper {
        display: inline-block;
        width: 350px;
    }

    .motivation-select__checkbox {
        display: inline-block;
        margin-right: 1rem;
    }

    @media only screen and (max-width: #{$layout-breakpoint-700}) {
        .motivation-select__label-wrapper {
            width: 300px;
        }
    }

    @media only screen and (max-width: #{$layout-breakpoint-sm - 1}) {
        .motivation-select__option {
            padding: 2rem;
        }
    }
}
@media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
    .motivation-select {
        padding: 15px 0;

        .motivation-select__option {
            width: 100%;
            padding: 1rem;
        }
        .motivation-select__label-wrapper {
            width: calc(100% - 4rem);
        }
    }
}
