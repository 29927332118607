.tp-button {
    position: relative;

    mat-icon.fa-spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -0.76rem;
        margin-left: -0.75rem;
        display: none;
    }

    &.loading {
        mat-icon.fa-spinner {
            display: block;
        }

        .label {
            visibility: hidden;
        }
    }
}

tp-icon-button {
    line-height: 0;
}

.call-to-action {
    /*
    font-size: 0.9rem;
    font-weight: 400;

    margin: 0 0.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0.4rem 2.5rem;
    */

    &.small {
        //padding: 0 1rem;
    }
    &.w-100 {
        width: 100%;
    }
    span.call-to-action__text {
        vertical-align: unset;
    }
    @media (max-width: 1280px) and (min-width: 960px) {
        span.call-to-action__text {
            display: none;
        }
        mat-icon {
            margin-right: 0;
        }
    }
}
.mat-button-base,
.call-to-action {
    .mat-button-wrapper span.loading--hidden {
        vertical-align: unset;
    }
    .loading--shown {
        display: none;
    }
    .loading--hidden {
        display: inline-block;
    }
    &.loading {
        .mat-icon {
            font-size: 1.5em;
        }
        .loading--hidden {
            display: none;
        }
        .loading--shown {
            display: inline-block;
        }
    }
}

button {
    &.loading:not(.on-edge-in-list) {
        span {
            background-color: inherit;
        }
    }

    &.on-edge-in-list {
        color: rgba(0, 0, 0, 0);
        span {
            color: rgba(0, 0, 0, 0);
        }
    }

    tp-search-loader {
        background-color: inherit;

        .spinner {
            width: 100%;
            height: 100%;
            background-color: inherit;
            position: absolute;
            opacity: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            border-radius: 30px;
            z-index: 1;

            & > svg {
                width: 20px !important;
                height: 20px !important;
                margin-top: 8px;
                fill: #fff;
            }
        }
    }

    &[mat-stroked-button] {
        tp-search-loader {
            background-color: #fff;
            opacity: 1;
            .spinner {
                & > svg {
                    fill: #ff9300;
                }
            }
        }
    }

    &.message-sender__send-button {
        .spinner {
            margin: auto !important;
        }
    }
}
