.card {
    &__tags {
        display: block;
        position: relative;
        margin: 1rem 0;
        width: 100%;
        font-size: 0.875rem;

        &-header {
            font-size: 0.625rem;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.5px;
        }

        &-tag {
            display: inline-block;
            margin: 0.125rem 0.5rem 0.125rem 0;
            padding: 0px 6px;

            &:first-of-type {
                margin-left: 0;
            }

            &--matched {
                border-radius: 5px;
                padding: 0px 6px;
                background-color: $highlightColor;
                font-weight: bold;
                line-height: 1.2rem;
            }

            &--level {
                padding: 0;
                margin: 0 0.5rem 0 0;
            }
        }

        &-middot {
            display: inline-block;
            margin-right: 0.5rem;

            &:not(:last-of-type)::after {
                content: '\2022';
            }
        }

        &-row {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            &--bold {
                font-weight: bold;
            }

            &--normal {
                .card__tags-tag--matched {
                    font-weight: normal;
                }
            }

            .card__tags-tag {
                margin: 0.125rem 0.25rem;
            }
        }

        @media screen and (max-width: 747px) {
            &-header {
                display: none;
            }
        }

        @media screen and (max-width: 499px) {
            @include paddingMobile();
        }

        @media screen and (min-width: 500px) and (max-width: 747px) {
            @include paddingDesktop();
        }
    }
}
