$pagination-buttons-breakpoint: 1215px !default;

.slider-tiles__container {
    position: relative;
    display: inline-block;

    > .slider-tiles__tile {
        display: inline-block;
    }

    > .slider-tiles__tile:not(:last-of-type) {
        margin-right: 0.625rem;
        margin-bottom: 0.625rem;
    }

    mat-card.account-tile-gen-x,
    mat-card.account-tile-nla {
        width: 320px;
    }

    @include lt-xxs-screen {
        mat-card.account-tile-gen-x,
        mat-card.account-tile-nla {
            width: 255px;
        }
    }

    @include gt-xs-screen {
        mat-card.account-tile-gen-x,
        mat-card.account-tile-nla {
            width: 228px;
        }
    }

    @include gt-sm-screen {
        mat-card.account-tile-gen-x,
        mat-card.account-tile-nla {
            width: 248px;
        }
    }
}

.sidenav-opened {
    @media only screen and (min-width: 960px) and (max-width: 1020px) {
        .slider-tiles__wrapper {
            text-align: left;
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .slider-tiles__wrapper {
        text-align: left;
    }
}

.slider-tiles__wrapper {
    text-align: center;
    max-width: 1000px;

    .mat-paginator {
        display: none;
    }

    &--hide-paginator {
        text-align: left;

        .generation-exchange-paginator,
        tp-generation-exchange-paginator-side-buttons {
            display: none;
        }
    }
}

.slider-tiles__container {
    .btn-prev,
    .btn-next {
        position: absolute;
        top: 50%;
        margin-top: -25px;
        width: 128px;
        display: none;
    }

    button.btn-prev {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0;
        left: -128px;
    }

    button.btn-next {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        right: -128px;
    }

    @media only screen and (min-width: $pagination-buttons-breakpoint) {
        .btn-prev,
        .btn-next {
            display: block;
        }
    }
}
