.card {
    &__badge {
        font-size: 0.75em;
        line-height: 1.4em;
        font-weight: 500;
        letter-spacing: 0.5px;
        background-color: $mediumGreyColor;
        color: #ffffff;
        text-transform: uppercase;
        padding: 0 5px;
        position: absolute;
        top: 0;
    }
}
