.required-skills-input__table {
    width: 100%;
    border-top: 1px solid $foregroundColor;
    border-collapse: collapse;

    &.required-skills-input__table-topics {
        .grid-layout {
            & > .grid-layout_field {
                &-small-padding {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    text-align: left;
                }
            }
        }
    }

    thead {
        background-color: #eee;

        td:first-of-type {
            padding-left: 1rem;
        }

        td:last-of-type {
            padding-right: 1rem;
        }

        td {
            line-height: 1.2;
            font-size: 0.65rem;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;
            color: #444;
        }
    }

    &-topics {
        tp-info-toggle {
            font-size: 0.875rem;
            text-align: left;
            .mat-icon {
                margin-right: 0.5rem;
            }
        }
        thead {
            .grid-layout > .grid-layout_field {
                padding: 0.5rem 1rem;
            }
        }
    }

    tbody {
        tr:not(:last-child) {
            border-bottom: 1px solid rgba(#848a9f, 0.2);
        }
    }

    td > :not(button) {
        width: 100%;
    }

    td > button {
        position: absolute;
        top: 1rem;
        right: 0;
    }

    td > .required-skills-input__info-toggle {
        width: auto;
        position: absolute;
        top: 50%;
        transform: translateY(-70%);
        right: 0;

        svg path {
            color: #484e5f;
        }
    }

    .related-term-info {
        &__title {
            display: flex;
        }
        &__tags {
            text-align: left;
            padding-left: 2rem;
            margin-bottom: 0.3rem;
            font-weight: bold;
        }
        .mat-icon {
            margin-right: 0.5rem;
        }
        span {
            font-size: 0.9375rem;
            padding-top: 0.125rem;
            text-align: left;
        }
    }
}

.required-skills-input__add-button {
    margin-left: 0.5rem;
}

@media screen and (max-width: $layout-breakpoint-sm - 1px) {
    .required-skills-input {
        &__list {
            display: grid;
            row-gap: 2rem;
        }

        &__list-item {
            display: grid;
            grid-template-columns: 1fr;
            column-gap: 0.5rem;
            row-gap: 0.25rem;

            > :not(button) {
                grid-column: 1;
            }
        }

        &__checkbox {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        &__delete-item-button {
            grid-row: 1 / span 2;
            align-self: center;
            grid-column: 2;
        }
    }

    .required-skills-input__table {
        &-topics {
            thead {
                .grid-layout > .grid-layout_field {
                    padding: 0.5rem;
                }
            }
            tp-info-toggle {
                font-size: 0.875rem;
                .mat-icon {
                    margin-right: 0.5rem;
                }
            }
        }
    }
    .grid-layout {
        & > .grid-layout_field {
            &.grid-layout_field-no-padding {
                padding-top: 0;
            }
            &.grid-layout_field-small-padding {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (min-width: $layout-breakpoint-sm) {
    .required-skills-input__table {
        .grid-layout_span-5 {
            grid-column: span 5;
        }

        .grid-layout_span-3 {
            grid-column: span 3;
        }

        .grid-layout_span-1 {
            grid-column: span 1;
        }
    }

    .required-topic-input__checkbox {
        display: inline-block;
        text-align: center;
    }
}

@media screen and (max-width: #{$layout-breakpoint-xs - 1}) {
    .grid-layout {
        .grid-layout_span-8 {
            margin-bottom: 0.5rem;
        }

        tp-skill-level-button {
            margin-top: 0.75rem;
            margin-bottom: 0.5rem;
        }
    }
}
