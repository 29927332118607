.popover-tooltip {
    display: flex;
    flex-direction: column;
    padding: 30px;
    color: $foregroundColor;

    &__footer {
        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid $lightGrey;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        tp-tertiary-button ~ tp-primary-button,
        tp-secondary-button ~ tp-primary-button,
        tp-secondary-button ~ tp-primary-icon-button,
        tp-secondary-icon-button ~ tp-primary-button,
        tp-secondary-icon-button ~ tp-sta-entity-participate-button,
        tp-secondary-icon-button ~ tp-projects-entity-participate-button {
            margin-left: 1.25rem;
        }
    }
    &__after-footer {
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .mat-icon {
            margin-right: 5px;
        }
    }
}
