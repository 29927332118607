$ratio: 0.7;

@mixin dashboardFullscreenBigTilesLimits() {
    max-width: 765px;
    max-height: 765px * $ratio;
}
@mixin dashboardFullscreenSmallTilesLimits() {
    max-width: 765px;
    max-height: 500px * $ratio;
}

@mixin beyondFullscreenDashboardLayout() {
    $gutter: 15px;
    $column: calc(((100vw - 270px - 3 * #{$gutter}) / 2));
    $rowBig: min(#{$column} * #{$ratio}, #{765px * $ratio});

    .dashboard .main-layout_main > div {
        padding: $gutter;
        grid-template-rows: #{$rowBig} #{$rowBig} #{$rowBig};
        grid-template-columns: #{$column} #{$column};
    }

    .dashboard_tile {
        margin: $gutter;
        font-size: 1rem;
    }

    .dashboard_tile-1 {
        grid-row: 1 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-2 {
        grid-row: 1 / span 1;
        grid-column: 2 / span 1;
    }
    .dashboard_tile-3 {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-4 {
        grid-row: 2 / span 1;
        grid-column: 2 / span 1;
    }
    .dashboard_tile-5 {
        grid-row: 3 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-6 {
        grid-row: 3 / span 1;
        grid-column: 2 / span 1;
    }
    .dashboard_tile-7 {
        grid-row: 4 / span 1;
        grid-column: 1 / span 1;
    }
}

@mixin fullscreenDashboardLayout() {
    $gutter: 15px;

    .dashboard_tile {
        margin: $gutter;
        font-size: 1rem;
    }

    .dashboard .main-layout_main > div {
        &.dashboard_tile_wrapper-2,
        &.dashboard_tile_wrapper-3,
        &.dashboard_tile_wrapper-4,
        &.dashboard_tile_wrapper-6 {
            $column: calc(((100vw - 270px - 3 * #{$gutter}) / 2));
            $rowBig: min(#{$column} * #{$ratio}, #{765px * $ratio});
            padding: $gutter;

            $allowedBig: min(#{$rowBig}, #{430px});

            grid-template-rows: #{$allowedBig} #{$allowedBig} #{$allowedBig};
            grid-template-columns: #{$column} #{$column};

            .dashboard_tile-1 {
                grid-row: 1 / span 1;
                grid-column: 1 / span 1;
            }
            .dashboard_tile-2 {
                grid-row: 1 / span 1;
                grid-column: 2 / span 1;
            }
            .dashboard_tile-3 {
                grid-row: 2 / span 1;
                grid-column: 1 / span 1;
            }
            .dashboard_tile-4 {
                grid-row: 2 / span 1;
                grid-column: 2 / span 1;
            }
            .dashboard_tile-5 {
                grid-row: 3 / span 1;
                grid-column: 1 / span 1;
            }
            .dashboard_tile-6 {
                grid-row: 3 / span 1;
                grid-column: 2 / span 1;
            }
            .dashboard_tile-7 {
                grid-row: 4 / span 1;
                grid-column: 1 / span 1;
            }
        }

        &.dashboard_tile_wrapper-5 {
            $column: calc(((100vw - 270px - 2 * #{$gutter}) / 6));
            $rowBig: calc(
                (((100vw - 270px - 2 * #{$gutter}) / 6) - 2 / 3 * #{$gutter}) * #{$ratio} + 2 / 3 * #{$gutter}
            );
            $rowSmall: $rowBig;
            padding: $gutter;

            $allowedBig: min(#{$rowBig}, #{143px});
            $allowedSmall: min(#{$rowSmall}, #{215px});

            grid-template-rows: #{$allowedBig} #{$allowedBig} #{$allowedBig} #{$allowedSmall} #{$allowedSmall} #{$allowedSmall};
            grid-template-columns: #{$column} #{$column} #{$column} #{$column} #{$column} #{$column};

            .dashboard_tile-1 {
                grid-row: 1 / span 3;
                grid-column: 1 / span 3;
            }
            .dashboard_tile-2 {
                grid-row: 1 / span 3;
                grid-column: 4 / span 3;
            }
            .dashboard_tile-3 {
                grid-row: 4 / span 2;
                grid-column: 1 / span 2;
            }
            .dashboard_tile-4 {
                grid-row: 4 / span 2;
                grid-column: 3 / span 2;
            }
            .dashboard_tile-5 {
                grid-row: 4 / span 2;
                grid-column: 5 / span 2;
            }
            .dashboard_tile-6 {
                grid-row: 6 / span 3;
                grid-column: 1 / span 3;
            }
            .dashboard_tile-7 {
                grid-row: 6 / span 3;
                grid-column: 4 / span 3;
            }
        }

        &.dashboard_tile_wrapper-7,
        &.dashboard_tile_wrapper-8 {
            $column: calc(((100vw - 270px - 2 * #{$gutter}) / 6));
            $rowBig: calc(
                (((100vw - 270px - 2 * #{$gutter}) / 6) - 2 / 3 * #{$gutter}) * #{$ratio} + 2 / 3 * #{$gutter}
            );
            $rowSmall: calc((((100vw - 270px - 2 * #{$gutter}) / 6) - #{$gutter}) * #{$ratio} + #{$gutter});
            padding: $gutter;

            $allowedBig: min(#{$rowBig}, #{143px});
            $allowedSmall: min(#{$rowSmall}, #{215px});

            grid-template-rows: #{$allowedBig} #{$allowedBig} #{$allowedBig} #{$allowedSmall} #{$allowedSmall} #{$allowedBig} #{$allowedBig} #{$allowedBig} #{$allowedBig} #{$allowedBig} #{$allowedBig};
            grid-template-columns: #{$column} #{$column} #{$column} #{$column} #{$column} #{$column};

            .dashboard_tile-1 {
                grid-row: 1 / span 3;
                grid-column: 1 / span 3;
            }
            .dashboard_tile-2 {
                grid-row: 1 / span 3;
                grid-column: 4 / span 3;
            }
            .dashboard_tile-3 {
                grid-row: 4 / span 2;
                grid-column: 1 / span 2;
            }
            .dashboard_tile-4 {
                grid-row: 4 / span 2;
                grid-column: 3 / span 2;
            }
            .dashboard_tile-5 {
                grid-row: 4 / span 2;
                grid-column: 5 / span 2;
            }
            .dashboard_tile-6 {
                grid-row: 6 / span 3;
                grid-column: 1 / span 3;
            }
            .dashboard_tile-7 {
                grid-row: 6 / span 3;
                grid-column: 4 / span 3;
            }
            .dashboard_tile-8 {
                grid-row: 9 / span 3;
                grid-column: 1 / span 3;
            }
        }
    }
}

@mixin twoColumnsDashboardLayoutWithSidenavBeyondLimits() {
    $gutter: 8px;
    $column: 500px + $gutter * 2;
    $row: 500px * $ratio + $gutter * 2;

    .dashboard .main-layout_main > div {
        padding: $gutter;
        grid-template-rows: $row $row $row;
        grid-template-columns: $column $column;
    }

    .dashboard_tile {
        margin: $gutter;
        width: 500px;
        height: 500px * $ratio;
        font-size: 1rem;
    }

    .dashboard_tile-1 {
        grid-row: 1 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-2 {
        grid-row: 1 / span 1;
        grid-column: 2 / span 1;
    }
    .dashboard_tile-3 {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-4 {
        grid-row: 2 / span 1;
        grid-column: 2 / span 1;
    }
    .dashboard_tile-5 {
        grid-row: 3 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-6 {
        grid-row: 3 / span 1;
        grid-column: 2 / span 1;
    }
    .dashboard_tile-7 {
        grid-row: 4 / span 1;
        grid-column: 1 / span 1;
    }
}

@mixin twoColumnsDashboardLayoutWithSidenav() {
    $gutter: 16px;
    $column: calc(((100vw - 270px - 3 * #{$gutter}) / 2));
    $row: min(#{$column} * #{$ratio}, #{500px * $ratio});

    .dashboard .main-layout_main > div {
        padding: $gutter;
        grid-auto-rows: auto;
        grid-template-columns: $column $column;
        gap: $gutter;
    }

    .dashboard_tile {
        max-width: 765px;
        width: $column;
        max-height: 500px * $ratio;
        min-height: $row;
        font-size: 1rem;
    }

    .dashboard_tile-1 {
        grid-row: 1 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-2 {
        grid-row: 1 / span 1;
        grid-column: 2 / span 1;
    }
    .dashboard_tile-3 {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-4 {
        grid-row: 2 / span 1;
        grid-column: 2 / span 1;
    }
    .dashboard_tile-5 {
        grid-row: 3 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-6 {
        grid-row: 3 / span 1;
        grid-column: 2 / span 1;
    }
    .dashboard_tile-7 {
        grid-row: 4 / span 1;
        grid-column: 1 / span 1;
    }
}

@mixin twoColumnsDashboardLayoutWithoutSidenav() {
    $gutter: 14px;
    $column: calc(((100vw - 3 * #{$gutter}) / 2));
    $row: min(calc(#{$column} * #{$ratio} + 3 * #{$gutter}), #{500px * $ratio});

    .dashboard .main-layout_main > div {
        padding: $gutter;
        grid-template-rows: auto auto auto;
        grid-template-columns: $column $column;
        gap: $gutter;
    }

    .dashboard_tile {
        max-width: 765px;
        max-height: 500px * $ratio;
        width: $column;
        min-height: $row;
        font-size: 1rem;
    }

    .dashboard_tile-1 {
        grid-row: 1 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-2 {
        grid-row: 1 / span 1;
        grid-column: 2 / span 1;
    }
    .dashboard_tile-3 {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-4 {
        grid-row: 2 / span 1;
        grid-column: 2 / span 1;
    }
    .dashboard_tile-5 {
        grid-row: 3 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-6 {
        grid-row: 3 / span 1;
        grid-column: 2 / span 1;
    }
    .dashboard_tile-7 {
        grid-row: 4 / span 1;
        grid-column: 1 / span 1;
    }
}

@mixin oneColumnsDashboardLayout() {
    $gutter: 8px;
    $column: calc(100vw - 2 * #{$gutter});
    $row: min(calc(#{$column} * #{$ratio}), #{500px * $ratio});

    .dashboard .main-layout_main > div {
        padding: $gutter;
        grid-template-rows: auto auto auto;
        grid-template-columns: auto;
        justify-content: center;
        row-gap: 2 * $gutter;
    }

    .dashboard_tile {
        max-width: 765px;
        max-height: 500px * $ratio;
        width: $column;
        min-height: $row;
        font-size: 1rem;
    }

    .dashboard_tile-1 {
        grid-row: 1 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-2 {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-3 {
        grid-row: 3 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-4 {
        grid-row: 4 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-5 {
        grid-row: 5 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-6 {
        grid-row: 6 / span 1;
        grid-column: 1 / span 1;
    }
    .dashboard_tile-7 {
        grid-row: 7 / span 1;
        grid-column: 1 / span 1;
    }
}
