.experience {
    .experience__add-new-position-btn {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: 8px;
    }
}

@media screen and (max-width: $layout-breakpoint-sm - 1px) {
    .experience {
        .experience__add-new-position-btn {
            grid-column: span 12;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.experience-new-position {
    @media only screen and (max-width: 959px) {
        .position-form_description {
            grid-column: span 12;
        }

        .position-form_years {
            grid-column: span 6;
        }

        .position-form_months {
            grid-column: span 6;
        }
    }
}

.experience-positions {
    $headerTopBorder: #999;
    $headerBackground: whitesmoke;

    .experience-positions__table {
        width: 100%;
        border-spacing: 0;
        border-top: 1px solid $headerTopBorder;
    }

    .experience-positions__table-header-row {
        min-height: auto;
        background-color: $headerBackground;
    }

    .experience-positions__table-header-column {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.65rem;
    }

    .experience-positions__list {
        padding-right: 0;
        list-style-type: none;
        padding-left: 0;
    }
}

@mixin setCdkDragStyles() {
    &.cdk-drag-preview {
        display: inline-flex;
        align-items: center;

        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    &.cdk-drag-placeholder {
        opacity: 0;
    }

    &.cdk-drag-animating {
        transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
    }
}

@mixin setRemoveExperiencePositionButtonStyles() {
    height: 1.5rem;
    width: 1.5rem;
    line-height: 1.5rem;
}

// Note, Drag&drop feature creates copy of table row/list row inside body.
// That's why it must be styled outside of .experience-positions class.
.experience-positions__table-row {
    width: 100%;
    background: white;
    cursor: move;

    .experience-positions__table-column-years,
    .experience-positions__table-column-months,
    .experience-positions__table-column-remove {
        text-align: center;
        padding: 0.5rem 1rem;
    }

    .experience-positions__table-column-name {
        width: 60%;
        padding: 0.5rem 1rem;
    }

    .experience-positions__table-column-years,
    .experience-positions__table-column-months {
        width: 11.5%;
    }

    .experience-positions__table-column-remove {
        width: 17%;
        vertical-align: middle;

        .mat-icon-button {
            @include setRemoveExperiencePositionButtonStyles();
        }
    }

    @include setCdkDragStyles();
}

.experience-positions__list-row {
    display: inline-flex;
    align-items: center;
    width: 100%;
    background: white;
    cursor: move;
    margin: 0.25rem 0;

    .mat-icon-button {
        @include setRemoveExperiencePositionButtonStyles();
        margin-right: 0.625rem;
    }

    .experience-positions__list-name {
        margin: 0;
    }

    @include setCdkDragStyles();
}
