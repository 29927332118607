$tp-primary: (
    50: #fff2e0,
    100: #ffdfb3,
    200: #ffc980,
    300: #ffb34d,
    400: #ffa326,
    500: #ff9300,
    600: #ff8b00,
    700: #ff8000,
    800: #ff7600,
    900: #ff6400,
    A100: #ffffff,
    A200: #fff6f2,
    A400: #ffd4bf,
    A700: #ffc3a6,
    contrast: (
        50: #ffffff,
        100: #ffffff,
        200: #ffffff,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #ffffff,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$tp-accent: (
    50: #e9eaec,
    100: #c8cacf,
    200: #a4a7af,
    300: #7f838f,
    400: #636977,
    500: #484e5f,
    600: #414757,
    700: #383d4d,
    800: #303543,
    900: #212532,
    A100: #7b96ff,
    A200: #486dff,
    A400: #1544ff,
    A700: #0032fb,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$primaryColor: map-get($tp-primary, 500);
$accentColor: map-get($tp-accent, 500);
$accentContrastMap: map-get($tp-accent, 'contrast');
$accentContrastColor: map-get($accentContrastMap, 500);

$foregroundColor: #484e5f;
$backgroundColor: white;

$card-dark-color: $foregroundColor;

$warnColor: #123456;
$navTopColor: #191b21;
$navBottomColor: #484e5f;
$navContrastColor: white;
$errorColor: red;

$greyColor: #484e5f;
$greyContrastColor: #ffffff;
$mediumGreyColor: #8e93a4;
$mediumGreyContrastColor: #000000;
$lightGrey: #f2f2f2;

$highlightColor: #ffe9cc;
$alertColor: #c44438;
$alertLightColor: #f7d9ce;
$successColor: #64a71a;
$successLightColor: #dcedc4;

$buttonBorderRadius: 30px;
$buttonHeight: 2.5rem;
$buttonFontSize: 0.875rem;
$overlayMaxWidth: 940px;
$overlayPadding: 3.75rem;
$overlayRadius: 0.625rem;
$formFieldLeftRightPadding: 1rem;

$blogArticlesBackgroundColor: #333333;
