.dashboard-profile-tile {
    &__title {
        border-bottom: 1px solid $mediumGreyColor;
    }

    &__content {
        grid-column: 1 / span 2;
        display: grid;
        grid-template-columns: 25% auto;
        grid-gap: 1rem;

        align-items: center;
        justify-content: center;

        h3 {
            font-weight: bold;
        }

        .mat-stroked-button {
            margin-top: 8px;
        }
    }
}
