.workshop-participants {
    dl {
        display: block;
    }

    dd,
    dt {
        display: inline-block;
    }

    dt:after {
        content: ':';
    }

    dd {
        font-weight: bold;
        margin-left: 0.5rem;
    }

    .table--no-first-last-cell-padding {
        mat-cell {
            &:last-of-type {
                padding-right: 7px !important;
            }
        }
    }

    .icon-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: $mediumGreyColor;
    }

    .icon-column__big-icon {
        width: 5rem;
        height: 5rem;
        svg {
            height: 5rem;
        }
    }

    h3 {
        margin-top: 2rem;
    }

    .form-footer {
        justify-items: center;
    }

    .avatar-block {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.5rem;
    }

    .name-and-location {
        width: calc(100% - 3rem);
        margin-left: 1.4rem;

        .name {
            display: block;
            line-height: 1.1;
            font-weight: bold;
            white-space: normal;
            padding-right: 0.75rem;
        }
    }

    .user-icon {
        display: flex;
        justify-content: center;
        margin-right: 0.5rem;
        margin-top: 1rem;

        &.green {
            color: $successColor;
        }
        mat-icon {
            display: inline-block;
            height: 1.125rem;
            width: 1.125rem;
            svg {
                height: 100%;
            }
        }
    }

    .image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 1rem;
    }

    .user-cell {
        flex-basis: 140px;
        flex-grow: 1;
        flex-shrink: 0;
        max-width: 195px;

        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: unset;
        white-space: pre-wrap;
    }
    .table__cell.user-cell {
        cursor: pointer;
    }

    .actions-cell {
        flex: 0 0 7%;
    }

    .mat-column {
        &-topics,
        &-skills {
            flex-basis: 240px;
            flex-grow: 0;
            padding-left: 12px;
        }
        &-registeredAt {
            flex-basis: 140px;
            flex-grow: 0;
            position: absolute;
            right: 75px;
        }
        &-topics {
            font-weight: bold;
        }
        &-skills {
            color: $mediumGreyColor;
        }
    }

    .created-at {
        font-size: 12px;
        color: $mediumGreyColor;
        margin-right: 0.625rem;
    }
}

.application-table {
    &__row {
        display: grid;
        grid-template-columns: 140px 1fr max-content;
        column-gap: 1.1875rem;

        padding-top: 5px;
        padding-bottom: 5px;

        align-items: center;

        font-size: 0.875rem;
        word-break: break-word;
    }

    &__label-cell {
        text-transform: uppercase;
        font-size: 0.625rem;
        line-height: 0.625rem;
        font-weight: 500;
        letter-spacing: 0.4px;
        text-align: right;
    }

    &__identity-img-cell {
        justify-self: flex-end;
        display: flex;
        align-items: center;

        .green {
            color: $successColor;
        }

        .identity-avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-position: center;
            object-fit: cover;
            background-size: cover;
        }
    }

    &__identity-name-and-location-cell {
        .name {
            display: block;
            line-height: 1.1;
            font-weight: bold;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
    }

    &__skills-cell {
        display: flex;
        font-weight: bold;

        p {
            margin-bottom: 0;
        }
    }
    &__interestInSkills-cell {
        color: $mediumGreyColor;
    }

    &__status-cell--disabled {
        padding: 0;
        color: $accentColor;
    }

    hr {
        margin-top: 1.875rem;
        margin-bottom: 1.875rem;
        color: $lightGrey;
    }
}
.waiting-list {
    &__header {
        border-top: 1px solid #999;
        border-bottom: 5px solid #fff;
        background-color: whitesmoke;
        width: 100%;
        padding-left: 24px;
        color: rgba(0, 0, 0, 0.54);
        font-size: 0.625rem;
        font-weight: bold;
    }
    .table__row {
        background-color: whitesmoke;
    }
    .table__row:not(:last-of-type) {
        border-bottom: 1px solid #fff;
    }
    .table__row:last-of-type {
        border-bottom: 1px solid #999;
    }
    &.collapsable {
        .collapsable__indicator {
            position: relative;
            display: inline-block;
        }
        .collapsable__indicator::before {
            content: '\f054';
            font-family: 'FontAwesome';
            display: block;
            position: absolute;
            left: -15px;
            top: 0;

            transform: rotate(0deg);
            transition: transform 0.3s linear;
        }
        &--opened {
            .collapsable__indicator::before {
                transform: rotate(90deg);
                transition: transform 0.3s linear;
            }
        }
    }
}

@media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
    .application-table {
        &__row {
            grid-template-columns: 75px 1fr max-content;
            column-gap: 0.9375rem;
        }
    }
}

@media only screen and (max-width: #{$layout-breakpoint-sm - 1}) {
    .workshop-participants {
        dt {
            margin-right: 0.5rem;
        }
        dd {
            margin-left: 0;
        }
        .icon-column {
            display: none;
        }
        .user-icon {
            display: block;
            margin-top: 0.5rem;
            margin-right: 0;
            .mat-icon {
                height: 1rem;
                svg {
                    height: 1rem;
                }
            }
        }
    }

    .application-table {
        &__row {
            align-items: start;

            &:not(.application-table__row-user-pic) {
                grid-template-columns: auto;
            }
            &.application-table__row-user-pic {
                grid-template-columns: 85px 1fr max-content;
            }

            .application-table__identity-name-and-location-cell {
                display: flex;
                align-items: center;
                height: 60px;
            }
        }
        &__label-cell {
            text-align: left;
            line-height: inherit;
        }
        &__identity-img-cell {
            justify-self: start;
            margin-bottom: 1rem;
        }
    }
}
