.card {
    .card__facts {
        display: block;
        width: 100%;
        border-bottom: 1px solid $lightGrey;
        margin-top: 0.75rem;
        font-size: 0.75rem;
        white-space: pre-wrap;
        font-weight: 600;
        padding-bottom: 0.5em;

        &-segment {
            display: inline-flex;
            align-items: center;
            vertical-align: middle;
            line-height: 1.5rem;
            white-space: break-spaces;
            margin-bottom: 0.625rem;

            &:not(:last-child) {
                margin-right: 1.625rem;
            }

            &-icon {
                font-size: 1.5em;
                height: auto;
                width: auto;
                margin-right: 0.5rem;
            }

            &-button {
                position: relative;
                margin-left: 5px;
                line-height: 20px;
                width: 20px;
                height: 20px;
                font-size: 9px;
                box-shadow: none;
                background-color: $mediumGreyColor;

                .mat-button-wrapper {
                    padding: 3px 0;
                    line-height: 14px;
                }
            }

            &--uppercase {
                text-transform: uppercase;
            }

            &--highlight {
                display: inline-block;
                padding: 0 0.2rem;
                background-color: $highlightColor;
                font-weight: bold;
                line-height: 1.3rem;
            }

            &-company {
                max-width: 7.8em;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        @media screen and (max-width: 499px) {
            @include paddingMobile();
        }

        @media screen and (min-width: 500px) and (max-width: 747px) {
            @include paddingDesktop();
        }
    }
}
