.card {
    &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        font-size: 0.875rem;
        font-weight: 500;

        padding-top: 1rem;
        padding-bottom: 1rem;

        @media screen and (max-width: 499px) {
            padding-top: 1rem;
            padding-bottom: 1rem;
            @include paddingMobile();
        }

        @media screen and (min-width: 500px) and (max-width: 747px) {
            padding-top: 1rem;
            padding-bottom: 1rem;
            @include paddingDesktop();
        }

        @media screen and (min-width: 748px) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        mat-icon {
            height: 60px;
            width: 60px;
            margin-right: 0.5rem;
            svg {
                height: 60px;
            }
        }

        span {
            display: inline-block;
        }

        &--bold {
            font-weight: bold;
        }
    }

    &--placeholder {
        .card__text {
            font-size: 1rem;
            font-weight: 500;

            padding-left: 0;
            padding-right: 0;

            @media screen and (min-width: 748px) {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
    }
}
