.platform-administration {
    display: block;
    max-width: 1024px;

    .form-footer {
        padding: 2em 0 3em 0;
    }
}

.platform-administration-desktop-view {
    .mat-row,
    .mat-footer-row {
        padding: 0;
        min-height: 52px;
        .mat-column {
            &-admin,
            &-footerAdmin {
                background-color: whitesmoke;
                min-height: 50px;
            }
        }
    }
    .mat-footer-row,
    .mat-footer-row .mat-column-footerAdmin {
        min-height: 90px;
    }
    .second-footer-row {
        min-height: 40px;
    }

    .mat-footer-row {
        .mat-footer-cell--add-new {
            padding: 0;
            .cell-row--item-center {
                width: 100%;
                text-align: center;
                border-top: 1px solid whitesmoke;
                padding: 10px 0;
            }
        }
    }
    .mat-column-statistics,
    .mat-column-adminMail,
    .mat-column-admin {
        padding: 0;
    }
}

.platform-administration-desktop-view,
.platform-administration-mobile-view {
    tp-checkbox-item {
        &.disabled {
            opacity: 0.5;
        }
    }

    .btn-add-user-roles {
        button {
            border: 0;
            background-color: transparent !important; //remove button style overrides when storybook version will be ready
        }
        &:hover {
            button mat-icon {
                color: $primaryColor;
            }
        }
    }

    .table-scrollable-container {
        max-height: 520px;
        max-width: 100%;
        overflow: auto;
        box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.2);
    }

    .table--no-first-last-cell-padding {
        mat-header-cell:last-of-type,
        mat-footer-cell:last-of-type {
            padding-right: 0;
        }
        mat-cell:first-of-type {
            padding-left: 10px;
        }
    }

    .mat-form-field {
        width: 100%;
    }

    .mat-footer-row {
        border-bottom: 0;

        .mat-footer-cell {
            padding-top: 0;
        }
    }

    .mat-column {
        padding: 1rem 0;
        flex-shrink: 0;

        &-email {
            font-weight: bold;
        }

        &-date {
            font-size: 12px;
            text-transform: uppercase;
        }

        &-date,
        &-footerEmpty {
            flex-basis: 80px;
            flex-grow: 0;
        }
        &-email,
        &-footerEmail {
            flex-basis: 165px;
            word-break: break-all;
        }
        &-statistics,
        &-footerStatistics,
        &-adminMail,
        &-footerAdminMail,
        &-staffing,
        &-footerStaffing,
        &-admin,
        &-footerAdmin {
            flex-basis: 90px;
            max-width: 90px;
            justify-content: space-around;
        }
    }
}

.platform-administration-mobile-view {
    .table__row {
        margin: 0 15px;
    }

    .table-scrollable-container {
        max-height: 500px;
    }
    .table__header-cell {
        justify-content: space-between;

        .header-cell__wrapper {
            display: flex;
            flex-wrap: nowrap;
        }
    }
    .table__cell {
        flex-direction: column;
        .cell-row {
            display: flex;
            width: 100%;

            &__col-date {
                font-size: 12px;
                text-transform: uppercase;
            }
            &__col-email {
                font-weight: bold;
                padding-left: 15px;
                word-break: break-all;
            }
            &--checkboxes {
                justify-content: space-around;
                padding: 5px 0;
            }
            &__col-checkbox {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex-basis: 100px;
                .checkbox-label {
                    font-size: 10px;
                }
            }
        }
    }

    .table--no-first-last-cell-padding {
        .mat-footer-cell {
            &--add-new:last-of-type {
                padding: 0 25px;
            }
        }
    }

    .mat-footer-row {
        padding: 1em 0 0 0;

        .mat-footer-cell {
            &--add-new {
                .cell-row--item-center {
                    justify-content: center;
                    padding: 10px 0;
                    border-top: 1px solid whitesmoke;
                    margin-top: 10px;
                }
            }
        }
    }
}

.table-details {
    .col-label {
        vertical-align: top;
        text-transform: uppercase;
        font-weight: bold;
        padding-right: 15px;
    }
}
