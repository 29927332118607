.card {
    &__sub-content {
        display: flex;
        overflow: hidden;
        background-color: $lightGrey !important;

        @media screen and (max-width: 747px) {
            flex-direction: row;
            flex-wrap: wrap;
            background-image: none !important;

            color: $foregroundColor;

            &--hide-on-mobile {
                display: none;
            }
        }

        @media screen and (min-width: 748px) {
            flex-direction: column;
            justify-content: space-between;
            flex-basis: 900px * 0.26;

            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            @include paddingDesktop();
            padding-top: 2rem;
            padding-bottom: 1.5rem;

            color: white;
        }

        &--contrast-color {
            color: $greyColor;
        }
    }

    &--placeholder {
        .card__sub-content {
            @media screen and (min-width: 748px) {
                flex-basis: 305px;
                background-image: url('/assets/images/backgrounds/invite.jpg');
            }
        }
    }
}
