.slide-list-layout {
    display: grid;
    grid-template-columns: 10% 80% 10%;
    grid-template-rows: 100%;
    height: 100%;
    width: 100%;

    &__arrow {
        z-index: 2;
        cursor: pointer;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 1rem;

        &:hover {
            background-color: rgba(50, 50, 50, 0.1);
            box-shadow: 0px 0px 30px rgba(50, 50, 50, 0.1);
        }

        mat-icon {
            color: white;

            height: 60px;
            width: 24px;
            svg {
                height: auto;
                width: 24px;
            }
        }
    }

    nav {
        position: absolute;
        flex: 1 1;
        align-self: flex-end;
        margin-bottom: 10%;
        height: 30px;

        z-index: 3;

        cursor: pointer;
    }

    &__navigation {
        position: absolute;
        display: flex;

        flex-flow: row nowrap;
        justify-content: center;
        align-items: stretch;

        flex: 1 1 100%;
        bottom: 0;
        left: 0;

        margin-bottom: 0;

        min-width: 100%;
        max-width: 100%;

        z-index: 1;

        ul {
            padding: 0;
            li {
                display: inline-block;
                border: 1px solid #fff;
                border-radius: 10px;

                width: 10px;
                height: 10px;
                margin: 1px 3px;

                &.active {
                    width: 12px;
                    height: 12px;
                    margin: 0 3px;
                    background-color: #fff;
                }
            }
        }
    }

    &__viewport {
        z-index: 1;
        overflow: hidden;
        width: 126%;
        position: relative;
        left: -13%;
    }
}

.slide-layout {
    height: 100%;
    width: 100%;
    display: grid;
    align-items: end;

    background-size: cover;
    background-repeat: no-repeat;
    &::before {
        // Darken background to make text readable
        content: '';
        position: absolute;
        display: block;

        width: 100%;
        height: 70px;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    }
    &__content {
        font-weight: bold;
        line-height: 1.5625rem;
        text-align: center;
        padding: 1rem 10%;
        color: white;
        z-index: 2;
        font-size: 1.25rem;
    }
}
