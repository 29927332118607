.projects-entity-applications-table-view {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 1.875rem;

    .mat-cell {
        font-size: 0.875rem;
    }

    .complete-staffing-btn {
        margin-bottom: 1rem;
    }

    .skills-list {
        width: calc(100% - 1.5rem);
    }

    mat-icon.checked {
        display: inline-block;
        float: left;
        height: 1.125rem;
        width: 1.125rem;
        padding: 0.25rem;
        margin-right: 0.25rem;
        border-radius: 50%;
        background-color: #64a71a;
        visibility: hidden;
        margin-top: 1rem;

        &.visible {
            visibility: visible;
        }
    }

    .mat-column {
        padding: 1rem 0;

        &-identity {
            flex-basis: 140px;
            flex-grow: 1;
            flex-shrink: 0;

            display: flex;
            justify-content: flex-start;
            align-items: unset;

            white-space: pre-wrap;

            &.mat-cell {
                flex-direction: column;
            }

            .avatar-block {
                display: flex;
                flex-direction: row;
                margin-bottom: 0.5rem;
            }

            .identity-avatar {
                width: 50px;
                min-width: 50px;
                height: 50px;
                border-radius: 50%;
                background-position: center;
                margin-right: 1rem;
                object-fit: cover;
                background-size: cover;
            }

            .name-and-location {
                width: calc(100% - 3rem);
                margin-left: 1.4rem;

                .name,
                .location {
                    display: block;
                    line-height: 1.1;
                }
                .location {
                    white-space: normal;
                }
                .name {
                    font-weight: bold;
                    word-break: break-word;
                }
            }
        }

        &-skills {
            flex-basis: 190px;
            flex-grow: 1;
            flex-shrink: 0;

            .column-mode {
                max-width: 190px;
            }
        }

        &-relatedSkills {
            flex-basis: 190px;
            flex-grow: 1;
            flex-shrink: 0;

            .column-mode {
                max-width: 190px;
            }
        }

        &-actions {
            flex-basis: 110px;
            flex-grow: 0;
            align-items: unset;

            tp-icon-button + tp-projects-entity-applications-reject-button,
            tp-icon-button + tp-projects-entity-applications-cancel-button,
            tp-projects-entity-applications-reject-button + tp-projects-entity-applications-cancel-button,
            tp-icon-button + .button-wrapper {
                margin-left: 0.625rem;
            }

            .button-wrapper {
                line-height: 0;
                display: inline-block;
            }
        }

        &-status {
            flex-basis: 150px;
            flex-shrink: 0;
            flex-grow: 0;
            justify-content: flex-end;
            align-items: flex-start;

            .mat-icon.approved {
                color: $successColor;
            }

            .mat-button[disabled='true'] {
                color: inherit;

                .mat-icon {
                    color: $primaryColor;
                }
            }
        }
    }
}

.projects-entity-applications-list-view {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 1.875rem;

    .mat-cell {
        font-size: 0.875rem;
    }

    .complete-staffing-btn {
        display: block;
        margin: 0 0 2rem auto;
    }

    mat-icon.checked {
        display: block;
        margin-top: -2.5rem;
        margin-right: 0;
        float: left;
        height: 1.125rem;
        width: 1.125rem;
        border-radius: 50%;
        padding: 0.25rem;
        background-color: #64a71a;
        visibility: hidden;

        svg {
            height: 0.625rem;
            width: 0.625rem;
        }

        &.visible {
            visibility: visible;
        }
    }

    .application-table {
        &__row {
            display: grid;
            grid-template-columns: 140px 1fr max-content;
            column-gap: 1.1875rem;

            padding-top: 5px;
            padding-bottom: 5px;

            align-items: center;

            font-size: 0.875rem;
            word-break: break-word;

            &.not-actual {
                .identity-avatar {
                    opacity: 0.5;
                }
                * {
                    color: $mediumGreyColor;
                }
            }
        }

        &__label-cell {
            display: none;
        }

        &__identity-img-cell {
            justify-self: flex-end;

            .identity-avatar {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-position: center;
                object-fit: cover;
                background-size: cover;
            }
        }

        &__identity-name-and-location-cell {
            .name,
            .location {
                display: block;
                line-height: 1.1;
            }
            .name {
                font-weight: bold;
            }
        }

        &__actions {
            display: flex;
            flex-direction: column;

            button + button,
            button + .button-wrapper {
                margin-top: 5px;
            }
        }

        &__skills-cell {
            display: flex;

            &.column-direction {
                flex-direction: column;
                align-items: start;
            }

            p {
                margin-bottom: 0;
            }
        }

        &__status-cell--disabled {
            padding: 0;
            color: $accentColor;
        }

        hr {
            margin-top: 1.875rem;
            margin-bottom: 1.875rem;
            color: $lightGrey;
        }
    }

    @media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
        .complete-staffing-btn {
            margin-bottom: 2rem;
        }

        .application-table {
            &__row {
                grid-template-columns: 75px 1fr max-content;
                column-gap: 0.9375rem;
            }
        }
    }

    @media only screen and (max-width: #{$layout-breakpoint-sm - 1}) {
        .application-table {
            &__row {
                align-items: start;
                &.application-table__row-user-pic {
                    grid-template-columns: 85px 1fr max-content;
                    column-gap: 0.9375rem;
                }
                &.application-table__row--actions-status {
                    justify-content: space-between;

                    .application-table__actions {
                        flex-direction: row;
                        justify-content: flex-start;

                        & > * {
                            margin-right: 1rem;
                        }
                    }
                }
                &:not(.application-table__row-user-pic) {
                    column-gap: unset;
                    grid-template-columns: unset;
                    display: flex;
                    align-content: flex-start;

                    .application-table__skills-cell {
                        min-width: 180px;
                        margin-right: 2rem;
                    }
                }
            }

            &__identity-name-and-location-cell {
                display: flex;
                align-items: center;
                height: 60px;
            }

            &__identity-img-cell {
                justify-self: start;
                margin-bottom: 1rem;
            }
        }
    }
}
