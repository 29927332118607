.admin-stats {
    background-color: #f2f2f2;
    margin: -3.125rem;
    padding: 0 3.125rem 3.125rem;
    display: block;

    .custom-reports-container {
        margin: 25px 6px 10px 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        a {
            &:not(:last-of-type) {
                margin-right: 40px;
            }
            &:hover {
                mat-icon {
                    color: $primaryColor;
                }
            }
            mat-icon {
                margin-right: 5px;
                align-self: center;
                padding-top: 8px;
            }
        }
    }

    .power-bi-container {
        height: calc(100vh - 240px);
        min-height: 600px;
        max-width: $layout-breakpoint-lg;
        iframe {
            border: 0;
        }
    }
}

@include gt-md-screen {
    .admin-stats {
        .power-bi-container {
            min-height: 50vw;
        }
    }
}

.admin-emails {
    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        @media screen and (max-width: 747px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        h2 {
            margin-bottom: 0;
        }
    }

    main,
    nav,
    header,
    .messages-table .table {
        max-width: 900px;

        .mat-icon {
            width: auto;
            margin-right: 5px;
        }

        .badge-draft {
            background-color: $mediumGreyColor;
            border-radius: 3px;
            display: inline-block;
            font-size: 0.6rem;
            padding: 0.2rem;
            line-height: 0.6rem;
            text-transform: uppercase;
            color: #fff;
            margin: 0 10px;
        }
        .date-cell {
            display: flex;
            justify-content: space-between;
        }
        .actions-cell {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            tp-icon-button + tp-icon-button {
                margin-left: 0.625rem;
            }
        }
        .actions-header-cell {
            justify-content: flex-end;
        }

        .mat-column {
            &-createdAt {
                flex: 0 0 18% !important;
                width: 18% !important;
            }
            &-subject {
                flex: 0 0 32% !important;
                width: 32% !important;
            }
            &-to {
                flex: 0 0 20% !important;
                width: 20% !important;
            }
            &-author {
                flex: 0 0 15% !important;
                width: 15% !important;
            }
            &-actions {
                flex: 0 0 15% !important;
                width: 15% !important;
            }
        }
    }
}

.emails-send-button-popover {
    .emails-send-button-list {
        &__list-item {
            font-size: 0.875rem;
            font-weight: 500;

            .mat-icon {
                margin-right: 0.5rem;
            }
        }
        &__divider {
            border-top-color: whitesmoke;
            margin: 0 1rem;
        }
    }
}

.admin-dialog {
    .mat-dialog-actions {
        justify-content: flex-end;
    }
}
