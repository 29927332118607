@import '~@angular/material/theming';

.welcome {
    .welcome-sign-up-layout_logo {
        width: 100%;
    }

    .welcome-sign-up__teaser-headline {
        color: white;
        margin-bottom: 3rem;
    }

    .welcome-sign-up-layout__subline {
        width: 100%;
        white-space: pre-wrap;
        margin-top: -0.5rem;
        margin-bottom: calc(16px + 0.5rem);
    }

    .welcome-sign-up-layout__form {
        display: grid;
    }

    .welcome-sign-up-layout__form-field-name {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .welcome-sign-up__cards {
        margin-bottom: -30px;
        padding: 0;
        display: flex;

        mat-card {
            margin: 0;
            padding: 0;
            color: white;
            max-width: 15rem;

            background: transparent;
            box-shadow: none;
            text-align: center;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            mat-card-header {
                display: flex;
                flex: 1;
                justify-content: center;
            }

            mat-card-title {
                mat-icon {
                    margin: 0;
                    height: 36px;
                    width: 36px;
                    svg {
                        height: 36px;
                        width: 36px;
                    }
                }

                h3 {
                    font-weight: bold;
                    margin: 0;
                }
            }

            mat-card-footer {
                margin: 0 0 16px 0;
                .info-toggle__icon svg path {
                    color: inherit;
                }
            }
        }
    }

    @media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
        .welcome-sign-up-layout__submit-button {
            width: 100%;
        }
        .welcome-sign-up__cards {
            flex-direction: column;
            align-items: center;
        }
    }

    @media only screen and (min-width: $layout-breakpoint-xs) and (max-width: #{$layout-breakpoint-sm - 1}) {
        .welcome-sign-up-layout__submit-button {
            justify-self: center;
        }
        .welcome-sign-up-layout__subline {
            width: 80%;
        }
        .welcome-sign-up__cards {
            flex-direction: column;
            align-items: center;
        }
    }

    @media only screen and (min-width: $layout-breakpoint-sm) {
        $typographyConfig: mat-typography-config();

        .welcome-sign-up-layout__submit-button {
            justify-self: left;
        }

        .welcome-sign-up__teaser-headline {
            font-size: mat-font-size($typographyConfig, display-3);
            letter-spacing: mat-letter-spacing($typographyConfig, display-3);
            line-height: mat-line-height($typographyConfig, display-3);
        }

        .welcome-sign-up__cards {
            justify-content: space-between;
        }
    }
}
