.job-shadowing {
    nav,
    main {
        max-width: 1024px;
    }
    .tab-content-sub-title {
        margin-bottom: 1.25rem;
    }
    .grid-layout_controls_footer {
        margin-top: 1.25rem;
    }
}
