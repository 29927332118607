.entity-owners--list {
    max-height: 330px;
    overflow-y: auto;
    display: block;

    &__item:not(:last-child) {
        border-bottom: 1px solid #e1e1e1;
    }

    &__item {
        padding: 16px 32px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &--clickable {
            cursor: pointer;
            padding: 0;

            a {
                padding: 16px 32px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            &:hover {
                background-color: #e1e1e1;
            }
        }

        &--avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            object-fit: cover;
            border: 1px solid white;
        }
        &--name {
            margin-left: 10px;
            font-weight: bold;
        }
        &--action {
            margin-left: auto;
        }
    }

    &__search-row {
        padding: 16px 32px;
        border-bottom: 1px solid #e1e1e1;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &__action {
            margin-left: 20px;
        }
        .global-search__autocomplete-attachment {
            height: 40px;
            bottom: 0;
        }
    }
}
