.mat-dialog-container .info-toggle-dialog {
    &__content {
        &.no-preline {
            white-space: pre-line;
        }
        display: grid;
        grid-template-columns: 6rem auto;
        grid-gap: 2rem;
        justify-items: center;
    }

    &__icon {
        width: 6rem;
        height: 6rem;

        svg {
            height: inherit;
            width: inherit;
        }
    }

    @media only screen and (max-width: 499px) {
        .info-toggle-dialog__content {
            grid-template-columns: auto;
        }
    }
}
