.dashboard-information-tile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    background: #c88250;
    color: #fff;
    text-align: center;
    cursor: pointer;

    .tile-layout_content-icon {
        &.mat-icon {
            height: 7rem;
            width: 7.5rem;

            svg {
                height: inherit;
                width: inherit;
            }
        }
    }

    .tile-layout_content-headline {
        margin-bottom: 20px;
        color: #fff;
    }
}
