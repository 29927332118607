.tp-campaigns-popover {
    .campaigns-list {
        &.mat-list-base {
            padding: 0.8rem 0;
            .mat-list-item {
                height: 1.4rem;
            }
        }

        &__list-item {
            .mat-list-item-content {
                font-size: 0.8rem;
                font-weight: 400;
            }

            .matched {
                padding: 0 0.125rem;
                background-color: $highlightColor;
                font-weight: bold;
                line-height: 1.2rem;
            }
        }
    }
}
