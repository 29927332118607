// This requires variables to have been set at the tenant level
$font-size: 16px;

body {
    height: 100vh;
    width: 100vw;
    margin: 0;

    user-select: text;
}

a,
a:visited {
    text-decoration: none;
    color: inherit;
}

a[href^='http']:not([class^='mat-'], [class^='fr-']),
a[href^='mailto']:not([class^='mat-']) {
    color: inherit;
    text-decoration: none;
    font-weight: bolder;
    font-style: italic;
    &:before {
        content: '»';
        font-size: 140%;
        font-style: normal;
        margin-right: 0.2em;
    }
    &:hover {
        color: #4aaa00;
    }
}

tp-link {
    a[href^='http']:not([class^='mat-']),
    a[href^='mailto']:not([class^='mat-']) {
        font-style: normal;
        &:hover,
        &:visited {
            color: inherit;
        }
        &:before {
            content: none;
        }
    }
}

* {
    box-sizing: border-box;
}

html,
body {
    -webkit-font-smoothing: antialiased;

    &.disable-scrolling {
        touch-action: none;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: auto;
        overflow: hidden;
    }
}

.rich-text a[href] {
    text-decoration: underline;
    cursor: pointer;
}

.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    &>li{
        list-style: none;
        cursor: pointer;
        border-radius: 4px;
        transition: all 0.2s ease-in-out;
        padding: 2px 4px;
        border-radius: 4px;

        &:hover{
            background-color: #f5f5f5;
        }
        &.disabled{
            opacity: 0.5;
            cursor: not-allowed;
            pointer-events: none
        }
        &.active{
            background-color: #f5f5f5;
        }
    }
}

@import './breakpoints';
@import './material-theme';
@import './tenant-ui.fonts';
@import './elements';

@include sidenav-theme($navTopColor, $navBottomColor, $navContrastColor);
//@include formFieldTheme($app-theme);
@include simpleTaggerTheme($app-theme);
