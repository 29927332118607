.modal-layout {
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100vw;
    height: 100vh;

    grid-template-columns: auto 100% auto;
    grid-template-rows: 10% 80% 10%;

    color: $foregroundColor;
    background-color: rgba(0, 0, 0, 0.65);
}

.modal-layout_main {
    display: grid;
    position: relative;
    padding: 6px 12px;
    grid-column: 1 / span 3;
    grid-row: 1 / span 3;
    border-radius: 10px;

    background-color: white;

    grid-template-columns: 20px auto 20px;
    grid-template-rows: 20px auto 40px;

    overflow-y: scroll;
    overflow-x: hidden;
}

.modal-layout_close {
    grid-column: 3 / 3;
    grid-row: 1 / 1;
}

.modal-layout_content {
    position: relative;
    display: block;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    grid-column: 2 / 2;
    grid-row: 2 / 2;

    & > * {
        display: block;
        position: relative;

        width: 100%;
        height: auto;
    }
}

.modal-layout_footer {
    position: relative;
    display: block;

    grid-row: 3 / 3;
    grid-column: 1 / span 3;
}

@media only screen and (min-width: $layout-breakpoint-sm) {
    .modal-layout {
        grid-template-columns: auto $overlayMaxWidth auto;
        grid-template-rows: 10% 80% 10%;

        background-color: rgba(0, 0, 0, 0.65);
    }

    .modal-layout_main {
        grid-template-columns: 40px auto 40px;
        grid-template-rows: 20px auto 40px;

        grid-column: 2 / 2;
        grid-row: 2 / 2;
        word-wrap: break-word;
    }
}
