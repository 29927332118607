.generation-exchange-search {
    .img-placeholder {
        width: 50%;
        margin-left: 25%;
    }

    .campaigns-placeholder-icon {
        vertical-align: middle;
        margin-top: -4px;
    }

    @media only screen and (max-width: #{$layout-breakpoint-sm - 1}) {
        .img-placeholder {
            width: 20rem;
            margin-left: 0;
        }
    }
}
