.loader {
    .spinner {
        width: 65px;
        height: 65px;
        margin-top: 120px !important;

        & > svg {
            fill: #ff9300;
        }
    }
}

.app-shell {
    .spinner {
        & > svg {
            fill: #fff;
        }
    }
}

.default-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 65px;
    height: 65px;
    .spinner {
        & > svg {
            fill: #8e93a4;
        }
    }
}
