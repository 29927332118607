$separatorColor: #f0f0f0;
$iconButtonBackground: #f5f5f5;
$subsectionBorderColor: #ccc;
$grayText: rgba(0, 0, 0, 0.54);

@mixin subsectionElevation() {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover,
    &:focus {
        transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        box-shadow: 0 10px 15px -5px $subsectionBorderColor;
    }
}

.sidenav-opened {
    .profile-view-header {
        width: calc(100% - 270px);
    }
    .profile-view-footer {
        width: calc(100% - 270px);
    }
}

.profile-view {
    display: block;
    padding-bottom: 50px;

    &__avatar-image-picker {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        align-self: flex-start;

        @media only screen and (max-width: $layout-breakpoint-700) {
            align-self: center;
        }
    }

    &__container {
        width: 100%;
        padding: 1rem;
        .profile-view__position {
            font-weight: bold;
            margin-bottom: 5px;
        }
    }

    &__gender-icon {
        display: inline-flex !important; // MUI mat-icon overwrites display to be inline-block
        align-items: center;
        width: 2rem !important; // MUI mat-icon [inline] overwrites the width to inherit, not what we want
        height: 2rem !important; // MUI mat-icon [inline] overwrites the height to inherit, not what we want
        margin-left: 1rem;
    }

    &__section {
        max-width: 1200px;
        margin-right: auto;
        display: block;
        margin-top: 60px;

        &:first-child {
            margin-top: 28px; // container padding 2rem = 32px, 60px - 32px = 28px
        }

        h1:first-of-type {
            margin-bottom: 0;
            align-items: start;
            max-height: 80px;
            overflow: hidden;
            line-break: anywhere;
        }
        @include lt-xs-screen {
            h1:first-of-type {
                max-height: 70px;
            }
        }

        @media only screen and (max-width: $layout-breakpoint-700) {
            &:first-of-type {
                flex-direction: column !important; // Host encapsulation breaks inherited styles
                align-items: center !important; // Host encapsulation breaks inherited styles
                justify-content: center !important; // Host encapsulation breaks inherited styles
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &__message {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $foregroundColor;
    }

    &__general-information-list {
        word-break: break-all;

        .profile-view__general-information-label {
            white-space: nowrap;
            margin-right: 8px;
        }

        .profile-view__general-information-value {
            word-break: normal;
            font-weight: 500;
        }

        .profile-view__general-information-comma {
            font-weight: 500;
            margin-right: 8px;
        }

        .profile-view__general-information-separator {
            position: relative;
            margin-left: 8px;
            margin-right: 8px;
            @media only screen and (min-width: $layout-breakpoint-700) {
                &:after {
                    margin-left: 8px;
                    margin-right: 8px;
                    font-size: 1rem;
                    font-weight: bold;
                    vertical-align: middle;
                    content: '·';
                }
            }
        }
    }

    &__text-button {
        background-color: transparent;
        font-size: 1rem;
        font-weight: 300;
        vertical-align: middle;
        padding-left: 0.3rem;
        padding-right: 0.3rem;

        .mat-icon {
            height: 18px;
            line-height: 18px;
            width: 18px;
            font-size: 18px;
            margin-right: 0.3rem;
            vertical-align: baseline;
        }

        &--uppercase {
            text-transform: uppercase;
        }
    }

    &__subsection {
        display: block;
        &:not(:first-of-type) {
            margin-top: 20px;
        }

        &:first-of-type {
            margin-top: 30px;
        }

        .collapsable {
            &:not(.disabled) {
                @include tp-box-shadow-all;
            }

            &.disabled {
                @include tp-box-shadow;
            }

            border-radius: 5px;

            &--opened {
                .profile-view__subsection-header {
                    padding-bottom: 0;
                }

                .profile-view__subsection-title {
                    border-bottom: 1px solid black;
                    padding-bottom: 30px;
                }
                @include lt-xs-screen {
                    .profile-view__subsection-title {
                        padding-bottom: 15px;
                    }
                }
            }
        }

        .collapsable__content {
            .well {
                padding-bottom: 16px;
            }
        }
    }

    &__subsection-header {
        padding: 20px 30px;
        position: relative;
    }

    @include lt-xs-screen {
        &__subsection-header {
            padding: 15px 0;
        }
        &__subsection-title {
            padding: 0 15px;
        }
    }

    &__subsection-content {
        display: block;
        position: relative;
        padding: 0;

        .well {
            padding: 0 30px;
        }
    }

    // Added as a handle to add new topics or to provide experience
    &__subsection-placeholder {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed $subsectionBorderColor;
        padding: 1rem 0.5rem 1rem 2rem;
        margin-top: 1rem;

        cursor: pointer;

        @include subsectionElevation();
    }

    &__section-title,
    &__subsection-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        width: 100%;
    }
    // increase specificity to override mat-typography stylings
    & &__subsection-title {
        margin-bottom: 0;
        border-bottom: 1px solid transparent;
        &.no-border {
            border-bottom: 0px;
            padding-bottom: 0px;
        }
    }

    &__interaction {
        flex-grow: 0;

        &--align-end:first-of-type {
            margin-left: auto;
        }

        &:not(:first-of-type) {
            margin-left: 0.5rem;
        }
    }

    &__contact-button {
        @media (max-width: $layout-breakpoint-700) {
            display: none;
        }
    }

    &__text-button {
        text-transform: uppercase;
    }

    &__section-title {
        margin-top: 2rem;
        font-size: 2rem;
    }

    &__text-field,
    &__list-field {
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;

        &:not(:first-child) {
            border-top: 1px solid #d5d7da;
        }

        font-weight: normal;
        font-size: 14px;
        line-height: 16px;

        div:first-of-type {
            font-weight: bold;
            font-size: 14px;
        }

        .separated {
            &:not(:first-of-type):before {
                content: ',';
            }
        }

        span {
            display: inline-block;

            &:not(:first-of-type, [class^='separated']):before {
                margin-left: 6px;
                margin-right: 6px;
                font-size: 1rem;
                font-weight: bold;
                vertical-align: middle;
                content: '·';
            }
        }
    }
}
mat-icon.profile-view__subsection-icon {
    height: 32px;
    width: 32px;
    margin-right: 15px;
    svg {
        height: inherit;
        max-width: 32px;
    }
}

.profile-view-header {
    z-index: 2;
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
    padding: 15px 2.5rem;

    > div:first-of-type {
        max-width: 1200px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: auto;
    }

    &__back-button {
        font-weight: bold;

        .mat-icon {
            margin-right: 15px;
        }

        .mat-button-wrapper {
            display: inline-flex;
            vertical-align: middle;
            align-items: center;
        }
    }

    &__action-bar {
        button {
            &:not(:last-of-type) {
                margin-right: 10px;
            }
        }
    }
}
@include lt-xs-screen {
    .profile-view {
        &__subsection-title {
            .mobile-hidden {
                display: none;
            }
        }
    }
}

.profile-view-footer {
    z-index: 2;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: block;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
    padding: 15px 2rem;
    background: #484e5f;

    .profile-view__section {
        margin-top: 0;
    }
}

.profile-view {
    &__subsection-footer {
        display: none;

        nav {
            row-gap: 1rem;
        }
    }
    @include lt-xs-screen {
        &__subsection-footer {
            display: block;
        }
    }
}
@include lt-xs-screen {
    tp-profile-button-navigation .caption-and-name .name {
        max-width: 100px !important;
    }
}
