@import '~froala-editor/css/froala_editor.pkgd.min.css';
.fr-box {
    .fr-toolbar.fr-top {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    &.fr-basic .fr-wrapper,
    .fr-toolbar.fr-top,
    .fr-toolbar.fr-bottom {
        border-color: $mediumGreyColor;
    }
    .fr-wrapper,
    .fr-view {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .fr-second-toolbar {
        visibility: hidden;
    }
}
