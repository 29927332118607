.sta-entity-applications-notify-dialog {
    &__title {
        padding: 0;
    }

    &__sub-title {
        padding-bottom: 1.5rem;
    }

    &__message-label {
        margin-bottom: 10px;
        font-weight: 500;
    }

    &__message {
        padding: 20px 20px 30px;
        background: $lightGrey;
        border: 1px solid $mediumGreyColor;
        border-radius: 5px;
        word-break: break-word;
        font-style: italic;
    }
}
