.card__indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    padding-top: 1rem;
    padding-bottom: 1rem;

    .card__indicator-segment {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        padding-top: 1rem;
        padding-bottom: 1rem;

        @media screen and (max-width: 499px) {
            @include paddingMobile();
        }

        @media screen and (min-width: 500px) {
            @include paddingDesktop();
        }

        mat-icon {
            width: 45px;
            height: 45px;
            margin-bottom: 5px;
            svg {
                height: 45px;
                max-width: 45px;
            }
        }

        .indicator-segment__label {
            font-size: 0.625rem;
            font-weight: bold;
            text-transform: uppercase;
        }

        .indicator-segment__value {
            font-size: 1.5rem;
        }
    }

    @media screen and (max-width: 499px) {
        @include paddingMobile();
    }

    @media screen and (min-width: 500px) {
        @include paddingDesktop();
    }
}
