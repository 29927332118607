.dashboard-message-tile {
    &__unread-messages-badge {
        margin-left: auto;
        margin-right: 1.875rem;
    }

    &__content--no-unread-messages {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        background: #b581c1;
        color: #fff;
        text-align: center;

        .tile-layout_content-icon {
            &.mat-icon {
                height: 7.5rem;
                width: 7.5rem;

                svg {
                    height: inherit;
                    width: inherit;
                }
            }
        }
    }

    &__content--has-messages {
        background: #fff;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        border-top: 1px solid $mediumGreyColor;
        padding: 0;
        cursor: pointer;

        .mat-list-avatar {
            width: 3.75rem !important;
            height: 3.75rem !important;
        }
    }

    &__content--single {
        align-items: center;

        .mat-list .mat-list-item {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    &__go-to-conversation-button {
        cursor: pointer;

        .mat-icon {
            width: auto;
            height: auto;
        }
    }

    .tile-layout_content-headline {
        margin-bottom: 20px;
    }

    .mat-list {
        width: 100%;
        padding-top: 0;

        .mat-list-item {
            height: auto !important;
            padding-top: 0.9375rem;
            padding-bottom: 0.9375rem;
            border-bottom: 0.0625rem solid $lightGrey;

            &:last-of-type {
                padding-bottom: 0;
                border-bottom: none;
            }
        }

        .mat-list-item-content {
            padding: 0 1.25rem !important;
            height: auto !important;
        }

        .mat-list-text {
            .mat-line {
                color: $foregroundColor;

                &:nth-of-type(1) {
                    font-size: 0.875rem;
                    font-weight: bold;
                    line-height: 1.125rem;
                }

                &:nth-of-type(2) {
                    font-size: 0.875rem;
                    line-height: 1rem;
                }

                &:nth-of-type(3) {
                    font-size: 0.75rem;
                    color: $mediumGreyColor;
                }
            }
        }
    }
}
