.details {
    &__organizer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-image {
            border-radius: 50%;
            background-position: center;
            width: 80px;
            height: 80px;
            object-fit: cover;
            background-size: cover;
            position: relative;

            .btn-more {
                position: absolute;
                right: 0;
                top: 55px;
                width: 40px;
                height: 40px;
                line-height: 0;
                padding: 0 5px;
                background-color: transparent;
                box-shadow: none;

                .mat-button-wrapper {
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    padding: 0 5px;
                    background-color: #fff;
                    color: #484e5f;
                    border-radius: 50%;
                }
            }
        }

        &-wrapper {
            display: block;
            position: relative;
            font-size: 0.75rem;
            line-height: 1rem;
            text-align: center;
            width: 100%;
        }

        &-label {
            letter-spacing: 0.12px;
            text-transform: uppercase;
            font-size: 0.625rem;
            margin: 0;
        }

        &-name {
            font-weight: bold;
            font-size: 0.875rem;
            letter-spacing: 0.17px;
            max-height: 38px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 135px;
        }

        @media screen and (max-width: 747px) {
            @include paddingMobile();

            &-wrapper {
                line-height: 1rem;
            }
        }

        @media screen and (min-width: 748px) {
            @include paddingDesktop();

            &-image {
                margin-bottom: 0.5rem;
            }

            &-wrapper {
                display: block;
                flex-direction: column;
                align-items: center;
                position: relative;
                line-height: 1.2rem;
            }
        }
    }
}
