mat-card.account-tile-gen-x {
    &.account-tile-gen-x {
        padding-top: 0.7rem;

        .profile-title-locations {
            margin-bottom: 0;
            text-align: left;
            color: $mediumGreyColor;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 0.7em;
        }

        .profile-message {
            height: 45px;
            color: $mediumGreyColor;
            font-size: 0.7em;
            line-height: 1.3em;
            font-weight: 500;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }
    }
}
