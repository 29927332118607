@import './dashboard.layouts';
$fullscreenLimit: 270px + 765px * 2 + 6 * 15px;
$twoColumnLimit: 270px + 650px * 2 + 6 * 8px;

.dashboard .main-layout_main-container {
    padding: 0;
}

.dashboard .main-layout_main {
    h1 {
        padding: 1em 1em 0 1em;
    }

    & > div {
        position: relative;
        display: grid;
    }
}

.dashboard_tile {
    display: flex;
    position: relative;
    overflow: hidden;

    @include tp-box-shadow;
    border-radius: 10px;
}

@media only screen and (max-width: 799px) {
    @include oneColumnsDashboardLayout();
}

@media only screen and (min-width: 800px) and (max-width: $layout-breakpoint-sm - 1) {
    @include twoColumnsDashboardLayoutWithoutSidenav();
}

@media only screen and (min-width: $layout-breakpoint-sm) and (max-width: $twoColumnLimit - 1px) {
    @include twoColumnsDashboardLayoutWithSidenav();
}

@media only screen and (min-width: $twoColumnLimit) {
    @include fullscreenDashboardLayout();
}
