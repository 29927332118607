.mat-tooltip {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background-color: #8e93a4;
    font-size: 0.7em;
    line-height: 1.4em;
}

.mat-tooltip.tooltip {
    white-space: pre-line;
    font-size: 14px;
    line-height: 20px;
    background-color: rgba(255, 255, 255, 0.97);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 15px 18px;
    color: #333;

    &:after {
        content: ' ';
        position: absolute;
        right: 30px;
        top: -15px;
        border-top: none;
        border-right: 15px solid transparent;
        border-left: 15px solid transparent;
        border-bottom: 15px solid black;
    }
}
