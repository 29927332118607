.details {
    &__image-header {
        &.position-absolute {
            position: absolute;
            top: 0;
            left: 0;
        }
        width: 100%;
        height: 13.75rem;
        background-color: #484e5f;
        display: flex;
        justify-content: flex-start;

        .image-body {
            width: 80%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .orginizer-info {
            position: relative;
            width: 20%;

            .details__organizer {
                color: white;
                width: fit-content;
                position: absolute;
                top: 2em;
                right: 0;
                left: 0;
                margin: auto;

                .btn-more {
                    position: absolute;
                    right: 25%;
                    top: 55px;
                    width: 40px;
                    height: 40px;
                    line-height: 0;
                    padding: 0 5px;
                    background-color: transparent;
                    box-shadow: none;

                    .mat-button-wrapper {
                        width: 24px;
                        height: 24px;
                        line-height: 24px;
                        padding: 0 5px;
                        background-color: #fff;
                        color: #484e5f;
                        border-radius: 50%;
                    }
                }
            }

            .details__actions {
                position: absolute;
                bottom: 1rem;
                right: 0;
                left: 0;
                margin: auto;
                display: flex;
                justify-content: center;

                .mat-icon-button {
                    background-color: #fff;
                }
            }
        }
    }

    @media only screen and (max-width: $layout-breakpoint-sm - 1px) {
        &__image-header {
            .orginizer-info {
                .details__organizer {
                    top: 0.938rem;
                    &-wrapper {
                        margin-top: 0.625rem;
                    }
                }
                .details__actions {
                    bottom: 0.938rem;
                }
            }
        }
        .orginizer-info {
            width: 40%;
        }
        .image-body {
            width: 60%;
        }
    }
}
