.sta-entity-view-conditions,
.sta-publish-approval-dialog {
    hr {
        margin-top: 3.25rem;
        margin-bottom: 3.25rem;
    }

    &__label {
        display: block;
    }

    &__options {
        .mat-radio-button + .mat-radio-button {
            margin-left: 3.75rem;
        }
    }
}
