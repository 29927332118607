tp-tags-viewer {
    font-size: inherit;
    line-height: 1.5;
    max-width: 100%;

    .tags-viewer-item {
        display: inline-flex;

        &:not(:last-of-type)::after {
            content: '\00B7';
            padding-left: 0.5em;
            padding-right: 0.5em;
            font-weight: 700;
        }
    }

    .tags-viewer-item__text--expert {
        background-color: $highlightColor;
        font-weight: bold;
    }
}
