.mat-tab-nav-bar {
    display: block;
    margin-bottom: 3.125rem;

    .mat-tab-link {
        font-size: 14px;
        opacity: 1;

        &.mat-tab-label-active {
            font-weight: bold;
        }

        &:hover {
            background: #f7f7f7;
        }

        span + mat-icon {
            margin-left: 5px;
        }
    }
}
