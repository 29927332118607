.welcome-accept-terms {
    .mat-dialog-actions {
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 0;

        .checkbox-wrapper {
            height: 40px;
            line-height: 40px;
        }
    }
}
