.sta-entity-applications-search {
    margin-top: 10px;

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 0 20px 0;
        background-color: #eee;
        position: relative;
    }

    &__title {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        font-weight: bold;

        .mat-icon {
            margin-right: 0.75rem;
        }

        .mat-icon,
        svg {
            height: 1.5rem;
            width: 1.5rem;
        }
    }

    @media screen and (max-width: #{$layout-breakpoint-xs - 1}) {
        .sta-entity-applications-search__header {
            padding: 0 10px 0;
        }
    }
}

.sta-entity-applications-search-input {
    display: grid;
    grid-template-columns: auto max-content;
    grid-gap: 20px;

    padding: 40px 20px calc(40px - 16px); // exclude the height of error label

    background-color: #eee;
    border-top: 2px solid whitesmoke;
    border-bottom: 2px solid whitesmoke;

    .mat-form-field {
        width: 100%;
        .skills-container-wrapper {
            margin-top: 0;
        }
    }

    .mat-form-field-outline {
        background-color: white;
        border-radius: 10px;
    }

    &__search-button {
        justify-self: center;
        margin-top: 4px;
    }

    @media screen and (max-width: #{$layout-breakpoint-sm - 1}) {
        grid-template-columns: auto;

        .sta-entity-applications-search-input__search-button {
            top: 0 !important; // styling of the vertical alignment directive must be overridden on mobile
        }
    }

    @media only screen and (max-width: #{$layout-breakpoint-xs - 1}) {
        padding: 20px 10px;

        .sta-entity-applications-search-input__search-button {
            justify-self: stretch;
            button {
                width: 100%;
            }
        }

        // required for small screen sizes to avoid the error message overlapping by suggestion tags
        .mat-form-field-invalid {
            .mat-form-field-wrapper {
                padding-bottom: 0;
            }

            .mat-form-field-subscript-wrapper {
                position: relative;
                margin: 0;
            }
        }
    }
}

.campaigns-placeholder-icon {
    vertical-align: middle;
    margin-top: -4px;
}
