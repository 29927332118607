.mentoring-context-select {
    margin-bottom: 30px;
    padding-left: 8px;
}

@media screen and (max-width: $layout-breakpoint-700 - 1px) {
    .mentoring-context-select {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 1.25rem;
        grid-row-gap: 1.25rem;
        padding-left: 1.25rem;
    }

    .radio-option {
        margin: 0;
    }
}

@media screen and (min-width: $layout-breakpoint-700) {
    .mentoring-context-select {
        justify-content: left;
        display: flex;
    }

    .radio-option ~ .radio-option {
        margin-left: 20px;
    }
}
